import { useNavigateWithLegalEntityID, useNavigationRoutes, useQueryFindAllTransactions } from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { Button } from '@chakra-ui/react'
import { Transaction, UuidSchema } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { NEXT_UNRECONCILED_TRANSACTION_QUERY_OPTIONS } from './NextUnreconciledTransactionButton const'
import { NextUnreconciledTransactionButtonProps } from './NextUnreconciledTransactionButton types'

export const NextUnreconciledTransactionButton: React.FC<NextUnreconciledTransactionButtonProps> = ({
    currentTransactionID,
    propsButton
}) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()

    const isCurrentTransactionIDValid = useMemo(
        () => UuidSchema.safeParse(currentTransactionID).success,
        [currentTransactionID]
    )

    const queryNextUnreconciledTransactions = useQueryFindAllTransactions(
        {
            starting_after: currentTransactionID,
            ...NEXT_UNRECONCILED_TRANSACTION_QUERY_OPTIONS
        },
        { enabled: isCurrentTransactionIDValid }
    )

    const nextUnreconciledTransaction = useMemo<Transaction | undefined>(() => {
        const aggregatedQueryData = queryDataAggregation(queryNextUnreconciledTransactions.data)
        return chain(aggregatedQueryData).head().value()
    }, [queryNextUnreconciledTransactions])

    const onClickNextUnreconciledTransaction = useCallback(() => {
        if (!nextUnreconciledTransaction) {
            return
        }
        const path = [
            paths.ACCOUNTS.TRANSACTIONS,
            nextUnreconciledTransaction?.id,
            relativePaths.ACCOUNTS.RECONCILE
        ].join('/')

        navigateWithLegalEntityID(path)
    }, [nextUnreconciledTransaction, paths, relativePaths])

    return (
        <Button
            {...propsButton}
            isDisabled={propsButton?.isDisabled || !nextUnreconciledTransaction}
            onClick={onClickNextUnreconciledTransaction}>
            <FormattedMessage id="app.accounts.transactions.reconcile.action.next_unreconciled_transaction.label" />
        </Button>
    )
}
