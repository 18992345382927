import { chain } from 'lodash'
import { amountFormatter } from '@/utils/@formatters'
import { isLastIndex } from '@/utils'
import { BULLET_SEPARATOR, EMPTY_CHAR_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { List } from '@webapps/numeral-ui-core'
import { ProcessedPaymentsTotalAmount } from '@/services'
import { IntlShape } from 'react-intl'

export function getFormatTotalValuePaymentsProcessedWithIntl(intl: IntlShape) {
    return function formatTotalValuePaymentsProcessed(
        data?: List<ProcessedPaymentsTotalAmount>,
        mainSeparator: string = `${BULLET_SEPARATOR}${SPACE_SYMBOL}`
    ) {
        return chain(data)
            .get('records', [])
            .reduce((acc, item, index, array) => {
                const formattedAmount = amountFormatter(intl, item.total_amount, item.currency)
                const separator = !isLastIndex(index, array) ? mainSeparator : EMPTY_CHAR_SYMBOL

                acc += [formattedAmount, separator].filter(globalThis.Boolean).join(SPACE_SYMBOL)

                return acc
            }, EMPTY_CHAR_SYMBOL)
            .value()
    }
}
