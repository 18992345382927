import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationUpdateByIdRole,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdRole
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import { RoleForm, RoleHeader } from '../@components'

export const RoleEditPage: React.FC = () => {
    const { uuid } = useParams()
    const location = useLocation()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdRole(uuid)
    const mutation = useMutationUpdateByIdRole(uuid, {
        onSuccess() {
            const { SETTINGS } = relativePaths
            const path = updateUUIDRelativeActionInPathname(location.pathname, SETTINGS.DETAILS)

            navigateWithLegalEntityID(path)
        }
    })
    const isDataEmpty = useMemo(() => isEmpty(query?.data), [isEmpty(query?.data)])

    return (
        <PageLayout className="RoleEdit">
            <RoleHeader />
            <RestrictedSection feature={ENTITY_FEATURE.CUSTOM_ROLE} permission={PERMISSION.SETTINGS_CONFIGURE_ROLES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.role}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <RoleForm
                        onSubmit={mutation.mutate}
                        data={query.data}
                        readonly={query.data?.is_default}
                        loading={query.isLoading || mutation.isPending}
                        disabled={query.data?.is_default || mutation.isPending}
                        submitLabelMessageId="app.common.form.actions.submit.label"
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
