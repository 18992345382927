import { PaymentOrder, Uuid } from '@webapps/numeral-ui-core'

export enum UploaderCustomHeaders {
    NumeralPaymentType = 'Numeral-Payment-Type',
    NumeralPaymentDirection = 'Numeral-Payment-Direction',
    NumeralPaymentRetryRuleId = 'Numeral-Payment-Retry-Rule-Id',
    NumeralFileName = 'Numeral-File-Name',
    NumeralPaymentTreasuryOption = 'Numeral-Payment-Treasury-Option'
}

export type UploadFileData<T = void> = {
    file?: File
    connected_account_id: Uuid
} & (T extends PaymentOrder ? Pick<T, 'direction' | 'type' | 'treasury_option' | 'retry_details'> : any)

export type UploadFilesData<T> = Omit<UploadFileData<T>, 'file'> & {
    files: File[]
}

export type UploadFileResponse = {
    file_id: Uuid
    filename: string
}
