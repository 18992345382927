import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdCustomField } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { CustomField } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import {
    CUSTOM_FIELD_ENTITY_DETAIL_ROW_CUSTOMIZERS,
    CUSTOM_FIELD_ENTITY_DETAIL_ROWS
} from './CustomFieldDetails.page.const'

export const CustomFieldDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdCustomField(uuid)

    return (
        <PageLayout className="CustomFieldDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_CUSTOM_FIELDS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.custom_field}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<CustomField>
                        data={query.data}
                        rows={CUSTOM_FIELD_ENTITY_DETAIL_ROWS}
                        rowCustomizers={CUSTOM_FIELD_ENTITY_DETAIL_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
