import { useQueryFindByIdExpectedPayment } from '@/hooks'
import { ReconciliationsPage } from '@/pages'
import { ExpectedPayment, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { useParams } from 'react-router'

export const ExpectedPaymentsReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdExpectedPayment(uuid)

    return (
        <ReconciliationsPage<ExpectedPayment> paymentType={PaymentTypeSchema.enum.expected_payment} queryItem={query} />
    )
}
