import { EntityAction } from '@/components'
import { FormattedAmount, If, Separator, SeparatorVariant } from '@/components/@misc'
import { usePermissions, useQueryFindByIdPaymentCapture } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { isEntityUnreconciled, PERMISSION } from '@/services'
import { prettyPrintCasedWords } from '@/utils'
import { Text } from '@chakra-ui/react'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { PaymentCaptureCancelConfirmationModal } from './@components'
import { BaseReconcileEntityHeaderProps } from '@/pages'
import { SPACE_SYMBOL } from '@/constants'

export type PaymentCaptureHeaderProps = BaseReconcileEntityHeaderProps

export const PaymentCaptureHeader: React.FC<PaymentCaptureHeaderProps> = ({ id, isReconciling, ...props }) => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)

    const query = useQueryFindByIdPaymentCapture(uuid || id)
    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.PAYMENTS_VIEW_PAYMENT_CAPTURES)) {
            return null
        }

        const type = query.data.type
        const createdAt = query.data.created_at

        return (
            <>
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <Text as="span">
                    <FormattedAmount amount={query.data.amount} currency={query.data.currency} />
                </Text>
                <If condition={!!type}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <Text as="span">{prettyPrintCasedWords(type)}</Text>
                </If>
                <If condition={!!createdAt}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={createdAt} />
                </If>
            </>
        )
    }, [isReconciling, query, hasPermission])
    const actions = useMemo<EntityAction[]>(() => {
        const buttons: EntityAction[] = []
        const hasEditPaymentCapturePermission = hasPermission(PERMISSION.PAYMENTS_EDIT_PAYMENT_CAPTURES)
        const isUnreconciled = isEntityUnreconciled(query.data)
        const isCanceled = globalThis.Boolean(query.data?.canceled_at)

        if (hasEditPaymentCapturePermission && isUnreconciled && !isCanceled) {
            buttons.push({
                onClick() {
                    setIsCancelModalOpen(true)
                },
                color: 'red.500',
                isDisabled: query.isLoading || !isUnreconciled,
                children: intl.formatMessage({ id: 'app.payments.payment_captures.tabs.details.actions.cancel.label' })
            })
        }

        return buttons
    }, [query, intl, hasPermission])

    const onCloseCancelModal = useCallback(() => {
        setIsCancelModalOpen(false)
    }, [])

    return (
        <>
            <PageHeaderLayout {...props} title={title} isLoading={query.isLoading} actions={actions} />
            <PaymentCaptureCancelConfirmationModal
                uuid={uuid}
                isOpen={isCancelModalOpen}
                onClose={onCloseCancelModal}
            />
        </>
    )
}
