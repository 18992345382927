import { defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
    baseStyle: {
        borderRadius: 'var(--numeral-ui-primary-border-radius)'
    },
    variants: {
        variants: {
            link: {
                color: 'numeralAccent.500',
                fontSize: 'inherit',
                fontWeight: 'inherit'
            }
        },
        solid: {
            color: 'white',
            background: 'numeralAccent.500',
            _hover: {
                background: 'numeralBlue.500'
            },
            _active: {
                background: 'numeralBlue.500'
            },
            _disabled: {
                background: 'gray.400'
            }
        },
        solidAlternative: {
            color: 'numeralAccent.500',
            background: 'blue.50',
            _disabled: {
                background: 'transparent',
                opacity: '0.5'
            }
        },
        secondary: {
            color: 'gray.700',
            background: 'gray.100',
            _hover: {
                background: 'gray.200'
            },
            _active: {
                background: 'gray.300'
            },
            _disabled: {
                opacity: '.5'
            }
        }
    }
})
