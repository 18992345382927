import {
    QueryFallback,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    useAPIQueryParams,
    useMutationExportReturnRequests,
    useNavigationRoutes,
    useQueryFindAllReturnRequests,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, ReturnRequest, ReturnRequestsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    RETURN_REQUEST_DOCUMENTATION_LINK,
    RETURN_REQUEST_FILTERS,
    RETURN_REQUEST_QUICK_FILTERS,
    RETURN_REQUEST_TABLE_COLUMN_CUSTOMIZERS,
    RETURN_REQUEST_TABLE_STATIC_STATE
} from './ReturnRequests.page.const'
import { ReturnRequestsCustomColumns } from './ReturnRequests.page.types'

export const ReturnRequestsPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.investigations.return_requests.title' }), [intl])
    const { search, filterBy } = useAPIQueryParams<ReturnRequestsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                filters: RETURN_REQUEST_FILTERS,
                quickFilters: RETURN_REQUEST_QUICK_FILTERS
            }
        }
    })

    const query = useQueryFindAllReturnRequests({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportReturnRequests({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const actions = useMemo<TableHeaderActionProps[]>(() => {
        return [
            {
                onClick() {
                    mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
                },
                children: intl.formatMessage({ id: 'app.common.actions.export' }),
                isDisabled: isDataEmpty || mutationExport.isPending
            }
        ]
    }, [intl, isDataEmpty, mutationExport, search.parsedState, filterBy.parsedState])

    const onTableRowClick = useTableRowNavigation<ReturnRequest>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection
                feature={ENTITY_FEATURE.RETURN_REQUEST}
                permission={PERMISSION.INVESTIGATIONS_VIEW_RETURN_REQUESTS}>
                <QuickFilterProvider<ReturnRequestsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<ReturnRequestsServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.return_request}
                        isLoading={query.isLoading}
                        actions={actions}
                        filterBy={filterBy}
                        search={search}
                    />
                    <QueryFallback
                        objectType={ApiObjectTypeSchema.enum.return_request}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError}
                        isDataEmpty={isDataEmpty}
                        isFilteringOrSearching={search?.isActive || filterBy?.isActive}
                        learnMoreLinkUrl={RETURN_REQUEST_DOCUMENTATION_LINK}>
                        <Table<ReturnRequest, ReturnRequestsCustomColumns>
                            data={data}
                            onRowClick={onTableRowClick}
                            onScrollToBottom={query.fetchNextPage}
                            isLoading={query.hasNextPage}
                            state={RETURN_REQUEST_TABLE_STATIC_STATE}
                            columnCustomizers={RETURN_REQUEST_TABLE_COLUMN_CUSTOMIZERS}
                        />
                    </QueryFallback>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
