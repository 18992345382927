import {
    FilterInputs,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AccountHolderInput,
    ConnectedAccountsInput,
    DateInput,
    InternalAccountStatusInput,
    InternalAccountTypeInput,
    TextInput
} from '@/components/@inputs'
import { InternalAccountType, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { accountNumberFormatter } from '@/utils/@formatters'
import { InternalAccount, InternalAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export const INTERNAL_ACCOUNT_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/internal-account-object`

export const INTERNAL_ACCOUNT_FILTERS: FilterInputs<InternalAccountsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    name: (
        <TextInput
            name="name"
            customLabelKey="app.common.form.input.internal_account_name.label"
            customPlaceholderKey="app.common.form.input.internal_account_name.placeholder"
        />
    ),
    account_holder_id: <AccountHolderInput name="account_holder_id" isPlaceholderDisplayed={true} />,
    account_number: <TextInput name="account_number" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    status: <InternalAccountStatusInput name="status" />,
    type: <InternalAccountTypeInput name="type" isPlaceholderDisplayed={true} />,
    bank_code: <TextInput name="bank_code" />
}

export const INTERNAL_ACCOUNT_TABLE_STATIC_STATE: TableStateWithEntity<InternalAccount> = {
    columnOrder: Object.freeze(['created_at', 'type', 'name', 'account_number', 'holder_name', 'status'])
}

export const INTERNAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<InternalAccount> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        cell(info) {
            return <InternalAccountType type={info.getValue()} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())

            return <TableCellText value={formattedValue} />
        }
    },
    holder_name: {
        minSize: MIN_COLUMN_WIDTH.FULL_NAME,
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    status: {
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    }
}
