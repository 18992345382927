import { FormikInput } from '@/types'
import { FieldArray } from 'formik'
import { Button, Flex, Stack } from '@chakra-ui/react'
import { chain } from 'lodash'
import { TextInput } from '@/components/@inputs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCustomFormikField } from '@/hooks'

const MAX_ALTERNATIVE_HOLDER_NAMES = 100

export const AlternativeAccountHolderNamesInput: React.FC<FormikInput> = ({
    name,
    value,
    validator,
    isDisabled,
    isRequired,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<string[]>({
        name,
        value,
        validate: validator?.(intl, { name, isRequired, ...inputProps })
    })

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const onAddAlternativeHolderName = () => arrayHelpers.push(undefined)

                const fieldValueWrapper = chain(field).get('value', [])
                const hasAtLeastOneValue = fieldValueWrapper.size().gt(0).value()
                const hasReachedMaxLimit = fieldValueWrapper.size().eq(MAX_ALTERNATIVE_HOLDER_NAMES).value()
                const hasPreviousValue = fieldValueWrapper.last().thru(globalThis.Boolean).value()
                const isAddDisabled = isDisabled || hasReachedMaxLimit || (!hasPreviousValue && hasAtLeastOneValue)

                return (
                    <Stack gap="calc(var(--numeral-ui-primary-spacing)/2)">
                        {chain(field.value)
                            .map((item, index) => {
                                const onRemove = () => arrayHelpers.remove(index)
                                const itemName = `${name}[${index}]`

                                return (
                                    <Flex justifyContent="space-between" alignContent="center" key={index}>
                                        <TextInput
                                            {...inputProps}
                                            name={itemName}
                                            customLabelKey={'app.common.form.input.alternative_holder_names.label'}
                                            customLabelValues={{ index: index + 1 }}
                                            customPlaceholderKey="app.common.form.input.alternative_holder_names.placeholder"
                                            value={item}
                                            isRequired={isRequired}
                                            isDisabled={isDisabled}
                                            maxLength={100}
                                            inputRightElement={
                                                <Button
                                                    variant="ghost"
                                                    color="red.500"
                                                    fontWeight="normal"
                                                    marginRight="-82px"
                                                    onClick={onRemove}
                                                    size="sm">
                                                    <FormattedMessage id="app.common.form.input.alternative_holder_names.actions.remove_value.label" />
                                                </Button>
                                            }
                                            data-testid={itemName}
                                        />
                                    </Flex>
                                )
                            })
                            .value()}
                        <Button
                            size="sm"
                            maxWidth="200px"
                            leftIcon={<>+</>}
                            width="fit-content"
                            isDisabled={isAddDisabled}
                            onClick={onAddAlternativeHolderName}>
                            <FormattedMessage id="app.common.form.input.alternative_holder_names.actions.add.label" />
                        </Button>
                    </Stack>
                )
            }}
        />
    )
}
