import { chain } from 'lodash'
import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router'
import {
    ExtendedRouteObject,
    getPrivateTopLevelRoutes,
    PATHNAME_LEGAL_ENTITY_ID_WITH_PRECEDING_SYMBOL__REGEX
} from '../providers'
import { useNavigationRoutes } from './context/useNavigationRoutes'

interface UsePrivateTopLevelRoutesHook {
    routes: ExtendedRouteObject[]
    currentRoute?: ExtendedRouteObject
}

const getCurrentRootRoute = (currentPathname: string) => {
    if (!currentPathname) {
        return
    }
    const pathnameWithoutLegalEntity = currentPathname.replace(
        PATHNAME_LEGAL_ENTITY_ID_WITH_PRECEDING_SYMBOL__REGEX,
        ''
    )
    return chain(pathnameWithoutLegalEntity).split('/', 2).last().defaultTo(pathnameWithoutLegalEntity).value()
}
const flattenRoutes = (route: ExtendedRouteObject[]): ExtendedRouteObject[] => {
    return route.flatMap((item) => (item.children ? [item, ...flattenRoutes(item.children)] : item))
}

export const usePrivateTopLevelRoutes = (): UsePrivateTopLevelRoutesHook => {
    const location = useLocation()
    const { routes } = useNavigationRoutes()
    const privateTopLevelRoutes = useRef<ExtendedRouteObject[]>(getPrivateTopLevelRoutes(routes)).current
    const currentPrivateTopLevel = useMemo<ExtendedRouteObject | undefined>(() => {
        const currentRoot = getCurrentRootRoute(location.pathname)
        if (!currentRoot) {
            return
        }
        return privateTopLevelRoutes.find((route) => route?.path?.includes(currentRoot))
    }, [location, privateTopLevelRoutes])

    return {
        routes: privateTopLevelRoutes,
        currentRoute: currentPrivateTopLevel
    }
}
