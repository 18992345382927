import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdInquiry } from '@/hooks'
import { PageLayout } from '@/layouts'
import { InquiryDenial } from '@/pages'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Inquiry } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import { INQUIRY_DETAILS_ROWS, INQUIRY_DETAILS_ROW_CUSTOMIZERS } from './InquiryDetails.page.const'
import { InquiryDetailsCustomRows } from './InquiryDetails.types'
import { useMemo } from 'react'

export const InquiryDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const hasApprovalPermission = hasPermission(PERMISSION.INVESTIGATIONS_APPROVE_INQUIRIES)
    const query = useQueryFindByIdInquiry(uuid)
    const isDataEmpty = useMemo(() => isEmpty(query?.data), [query])

    return (
        <PageLayout>
            <RestrictedSection feature={ENTITY_FEATURE.INQUIRY} permission={PERMISSION.INVESTIGATIONS_VIEW_INQUIRIES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.inquiry}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<Inquiry, InquiryDetailsCustomRows>
                        data={query.data}
                        rows={INQUIRY_DETAILS_ROWS}
                        rowCustomizers={INQUIRY_DETAILS_ROW_CUSTOMIZERS}
                    />
                    <If condition={hasApprovalPermission}>
                        <InquiryDenial data={query.data} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
