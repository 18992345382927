import { MIME_TYPE_TO_FILE_EXTENSION } from '@/constants'

export function isAcceptedFileType(acceptedFileExtensions: string[], fileMimeType: string): boolean {
    if (!acceptedFileExtensions.length || !fileMimeType) {
        return false
    }

    const expectedFileExtension = MIME_TYPE_TO_FILE_EXTENSION[fileMimeType]

    return acceptedFileExtensions.includes(expectedFileExtension)
}

export function isEmptyFileSize(fileSize?: number): boolean {
    return fileSize === 0
}

export function isAcceptedFileSize(acceptedFileSize?: number, fileSize?: number): boolean {
    if (!fileSize || !acceptedFileSize) {
        return false
    }
    return fileSize <= acceptedFileSize
}
