import { Box, Fade } from '@chakra-ui/react'
import { chain } from 'lodash'
import { memo } from 'react'
import { NumeralLogo } from '../@icons'
import './SplashScreen.scss'

interface SplashScreenProps {
    duration?: number
}

export const SplashScreen: React.FC<SplashScreenProps> = memo(({ duration }) => {
    const durationInSeconds = chain(duration).divide(1000).value()
    const transition = {
        enter: { duration: durationInSeconds }
    }

    return (
        <Box className="SplashScreen">
            <Fade in={true} transition={transition}>
                <NumeralLogo animation="fade-in" />
            </Fade>
        </Box>
    )
})
