import { addOrReplaceLegalEntityIDInPathname } from '@/providers'
import { useCallback, useMemo } from 'react'
import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router'
import { useAuth } from '../context'
import { chain } from 'lodash'

export function useNavigateWithLegalEntityID() {
    const navigate = useNavigate()
    const { selectedUserAccess } = useAuth()

    const navigateWithLegalEntityID = useCallback<NavigateFunction>(
        (to: To | number, options?: NavigateOptions) => {
            if (typeof to === 'number') {
                return navigate(to)
            }

            if (typeof to !== 'string') {
                return navigate(to, options)
            }

            const isAbsolutePath = to.toString().startsWith('/')
            const pathname = isAbsolutePath
                ? addOrReplaceLegalEntityIDInPathname(to.toString(), selectedUserAccess?.legal_entity_id)
                : to.toString()
            return navigate(pathname, options)
        },
        [navigate, selectedUserAccess]
    )
    const cannotGoBack = useMemo<boolean>(() => {
        return chain(globalThis.history).get('state.idx').eq(0).value()
    }, [globalThis.history])

    return { navigateWithLegalEntityID, cannotGoBack }
}
