import { EntityAction } from '@/components'
import { APIValidationErrorNotificationBody, Separator, SeparatorVariant } from '@/components/@misc'
import {
    useMutationCreateInquiry,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdReturnRequest,
    useToasts
} from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import {
    ApiObjectTypeSchema,
    ClaimStatusSchema,
    InquiryCreateData,
    InquiryRelatedRequestType,
    Uuid
} from '@webapps/numeral-ui-core'
import { ReactNode, useMemo } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'

export const ReturnRequestDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()

    const query = useQueryFindByIdReturnRequest(uuid)
    const mutationCreateInquiry = useMutationCreateInquiry(uuid, {
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { INVESTIGATIONS } = paths
            const path = [INVESTIGATIONS.INQUIRIES, response.id, relativePaths.INVESTIGATIONS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.investigations.inquiries.create.form.validation.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.investigations.inquiries.create.form.validation.success.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.investigations.inquiries.create.form.validation.error.toast.title" />,
                description: (
                    <APIValidationErrorNotificationBody<InquiryCreateData>
                        error={error}
                        messageId="app.investigations.inquiries.create.form.validation.error.toast.description"
                    />
                )
            })
        }
    })

    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.INVESTIGATIONS_VIEW_RETURN_REQUESTS)) {
            return null
        }

        const formattedDirection = intl.formatMessage({
            id: `app.payments.direction.${query.data.related_payment_type}.label`
        })
        const formattedTitle = intl.formatMessage(
            {
                id: 'app.investigations.return_requests.details.title'
            },
            {
                direction: formattedDirection
            }
        )

        return (
            <Text as="span">
                {query.data?.related_payment_type && (
                    <>
                        {formattedTitle}
                        <Separator variant={SeparatorVariant.Bullet} />
                    </>
                )}
                <FormattedDate value={query.data.created_at} />
            </Text>
        )
    }, [query, intl, hasPermission])
    const actions = useMemo<EntityAction[]>(() => {
        const buttons: EntityAction[] = []
        const canCreateInquiries =
            query.data?.related_payment_type === ApiObjectTypeSchema.enum.payment_order &&
            query.data?.status === ClaimStatusSchema.enum.sent

        if (hasPermission(PERMISSION.INVESTIGATIONS_CREATE_INQUIRIES) && canCreateInquiries) {
            buttons.push({
                async onClick() {
                    mutationCreateInquiry.mutate({
                        related_request_id: query.data?.id as Uuid,
                        related_request_type: query.data?.object as InquiryRelatedRequestType,
                        connected_account_id: query.data?.connected_account_id
                    })
                },
                loadingText: intl.formatMessage({
                    id: 'app.common.loading'
                }),
                isLoading: mutationCreateInquiry.isPending,
                children: intl.formatMessage({ id: 'app.investigations.actions.send_inquiry.label' })
            })
        }

        return buttons
    }, [query, intl, mutationCreateInquiry, hasPermission])

    return <PageHeaderLayout title={title} isLoading={query.isLoading} actions={actions} />
}
