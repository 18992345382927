import { StickyFooter } from '@/components'
import { FormattedAmount } from '@/components/@misc'
import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { getReconcilableAmount, isEntityUnreconciled } from '@/services'
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { Transaction } from '@webapps/numeral-ui-core'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'

export const TransactionReconcileFooter: React.FC<{ data?: Transaction }> = ({ data }) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const isVisible = useMemo<boolean>(() => isEntityUnreconciled(data), [data])
    const { relativePaths } = useNavigationRoutes()
    const onNavigate = useCallback(() => {
        const path = updateUUIDRelativeActionInPathname(location.pathname, relativePaths.ACCOUNTS.RECONCILE)
        navigateWithLegalEntityID(path)
    }, [navigateWithLegalEntityID, relativePaths])

    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    const formattedReconcilableAmount = (
        <FormattedAmount amount={getReconcilableAmount(data)} currency={data?.currency} />
    )

    return (
        <Box className="TransactionReconcileFooter" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Flex gap="16px" flex="1" paddingY="16px" justifyContent="space-between">
                    <Flex gap="8px" direction="column">
                        <Heading as="h3">
                            <FormattedMessage
                                id="app.reconcile.footer.title"
                                values={{ reconcilableAmount: formattedReconcilableAmount }}
                            />
                        </Heading>
                        <Text color="gray.400">
                            <FormattedMessage id="Reconcile this transaction with a payment" />
                        </Text>
                    </Flex>
                    <Box margin="auto 0">
                        <Button colorScheme="gray" onClick={onNavigate} variant="solid">
                            <FormattedMessage id="Reconcile" />
                        </Button>
                    </Box>
                </Flex>
            </StickyFooter>
        </Box>
    )
}
