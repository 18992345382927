import { useEffect, useRef } from 'react'
import { BrowserHistoryInstance } from '@/app/App.const'
import { Transition } from 'history'

interface NavBlockerControl {
    redirectTo: Partial<Location>
    retry(): void
    cancel(): void
}

interface NavBlocker {
    onBlock(control: NavBlockerControl): void
    enabled?: boolean
}

/**
 * @todo
 * Once upgraded to latest react-router and data router, refactor the use case with `useBlocker`
 * @description
 * For react-router 6.3x.0 and above replace with "useBlocker" and a data router (needs app refactor).
 */
export function useRouterNavigationBlocker({ onBlock, enabled }: NavBlocker) {
    const { block } = BrowserHistoryInstance
    const onBlockRef = useRef(onBlock)

    useEffect(() => {
        onBlockRef.current = onBlock

        if (!enabled) {
            return
        }

        let isActive = false
        const unblock = block(({ retry, location }: Transition) => {
            try {
                if (isActive) {
                    unblock()
                    return retry()
                }

                onBlockRef.current({
                    redirectTo: location,
                    retry,
                    cancel() {
                        isActive = false
                    }
                })
            } catch {
                globalThis.console.error('Unknown block retry', location)
            }
        })

        return unblock
    }, [block, enabled])
}
