import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { FormikInput } from '@/types'
import { If, Select } from '@/components/@misc'
import {
    PaymentApprovalRuleAttributeSchema,
    PaymentApprovalRuleCriteria,
    PaymentApprovalRuleOperator,
    PaymentApprovalRuleOperatorSchema
} from '@webapps/numeral-ui-core'
import { useCustomFormikField } from '@/hooks'
import { anythingValidator } from '@/utils/@validators'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCallback, useMemo } from 'react'
import { prettyPrintCasedWords } from '@/utils'

interface PaymentApprovalRuleCriterionListItemOperatorInputProps extends FormikInput {
    criterion: PaymentApprovalRuleCriteria
}

export const PaymentApprovalRuleCriterionListItemOperatorInput: React.FC<
    PaymentApprovalRuleCriterionListItemOperatorInputProps
> = ({
    name,
    value,
    criterion,
    isRequired,
    isLabelDisplayed,
    isErrorHidden,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<Partial<PaymentApprovalRuleOperator>>({
        name,
        value,
        validate: validator?.(intl, { name, isRequired })
    })
    const onChange = useCallback(
        (value: Partial<PaymentApprovalRuleOperator>) => {
            inputProps?.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps]
    )
    const isInvalid = useMemo(() => {
        return !isErrorHidden && meta?.touched && !!meta?.error
    }, [isErrorHidden, meta])
    const filteredOptions = useMemo(() => {
        const { amount_from, amount_to } = PaymentApprovalRuleAttributeSchema.enum
        switch (criterion?.attribute) {
            case amount_to:
            case amount_from: {
                return []
            }

            default: {
                return [PaymentApprovalRuleOperatorSchema.enum.in, PaymentApprovalRuleOperatorSchema.enum.not_in]
            }
        }
    }, [criterion?.attribute])

    return (
        <FormControl isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.operator.label" />
                </FormLabel>
            </If>
            <Select<PaymentApprovalRuleOperator>
                id={name}
                {...field}
                {...inputProps}
                value={value}
                onChange={onChange}
                options={filteredOptions}
                isRequired={isRequired}
                isInvalid={isInvalid}
                isClearable={false}
                isSearchable={false}
                data-testid="payment-approval-rule-criterion-operator"
                getOptionLabel={prettyPrintCasedWords}
            />
            <If condition={!isErrorHidden}>
                <FormErrorMessage>{meta?.error}</FormErrorMessage>
            </If>
        </FormControl>
    )
}
