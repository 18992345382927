import { SPACE_SYMBOL } from '@/constants'

export function fileSizeFormatter(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + SPACE_SYMBOL + sizes[i]
}
