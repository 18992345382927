import { isFileBlob } from '@/utils'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType, z } from 'zod'
import { asOptionalField } from '../validators.utils'
import { isAcceptedFileSize, isAcceptedFileType, isEmptyFileSize } from './files.validator.utils'
import { FormikInputConfiguration } from '@/types'

export type FileFieldValidatorError = {
    fileErrors: string[]
    formError?: string
}

export type FileFieldValidator = (value: File | File[]) => string | undefined | FileFieldValidatorError

/**
 * @description
 * - Validate multiple files and return list of errors for each files and form level errors
 * @returns An object with errors array for individual files and a formError string for form-level errors
 */
export function filesValidator(
    intl: IntlShape,
    configuration?: FormikInputConfiguration,
    options?: Record<string, any>
): FileFieldValidator {
    const validateSingleFile = (value: File, ctx: z.RefinementCtx) => {
        const acceptedExtensions = options?.formattedAcceptedExtensions
        const acceptedSizeInMB = options?.formattedAcceptedSize

        switch (true) {
            case !isFileBlob(value): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({ id: 'app.common.form.validation.required' }),
                    params: {
                        code: z.ZodIssueCode.invalid_type,
                        acceptedSizeInMB,
                        acceptedExtensions
                    }
                })
                break
            }

            case isEmptyFileSize(value?.size): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({ id: 'app.common.form.validation.file.too_small' }),
                    params: {
                        code: z.ZodIssueCode.too_small,
                        acceptedSizeInMB,
                        acceptedExtensions
                    }
                })
                break
            }

            case !isAcceptedFileType(options?.acceptedExtensions, value?.type): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(
                        { id: 'app.common.form.validation.file.invalid_type' },
                        { filename: value?.name }
                    ),
                    params: {
                        code: z.ZodIssueCode.invalid_type,
                        acceptedSizeInMB,
                        acceptedExtensions,
                        filename: value?.name
                    }
                })
                break
            }

            case !isAcceptedFileSize(options?.acceptedSize, value?.size): {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(
                        { id: 'app.common.form.validation.file.too_big' },
                        { filename: value?.name }
                    ),
                    params: {
                        code: z.ZodIssueCode.too_big,
                        acceptedSizeInMB,
                        acceptedExtensions,
                        filename: value?.name
                    }
                })
                break
            }
        }
    }

    const FilesArraySchema = z.array(z.any()).superRefine((values, ctx) => {
        if (configuration?.isRequired && (!values || values.length === 0)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intl.formatMessage({ id: 'app.common.form.validation.required' }),
                path: []
            })
            return
        }

        if ((configuration?.max as number) < values.length) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: intl.formatMessage(
                    { id: 'app.common.form.validation.file.too_many' },
                    {
                        max: configuration?.max
                    }
                ),
                path: []
            })
        }

        values.forEach((file, index) => {
            const contextWrapper = {
                addIssue: (issue: any) => {
                    ctx.addIssue({
                        ...issue,
                        path: [...(issue.path || []), index]
                    })
                },
                path: ctx.path || []
            }

            validateSingleFile(file, contextWrapper as z.RefinementCtx)
        })
    })

    const SingleFileSchema = z.any().superRefine((value, ctx) => {
        validateSingleFile(value, ctx)
    })

    return (value: File | File[]) => {
        let validationResult: SafeParseReturnType<any, any>

        const isArray = Array.isArray(value)
        const schema = isArray ? FilesArraySchema : SingleFileSchema

        if (configuration?.isRequired) {
            validationResult = schema.safeParse(value)
        } else {
            validationResult = asOptionalField(schema).safeParse(value)
        }

        if (!validationResult.success) {
            if (isArray) {
                const fileErrors: string[] = []
                let formError: string | undefined

                validationResult.error.issues.forEach((issue) => {
                    if (issue.path.length > 0) {
                        const computedIndex = issue.path[issue.path.length - 1]

                        if (typeof computedIndex === 'number') {
                            fileErrors[computedIndex] = issue.message
                        }
                    } else {
                        formError = issue.message
                    }
                })

                return {
                    fileErrors,
                    formError
                }
            }

            return validationResult.error.message
        }
    }
}
