import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdUser } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import { UserDetailsContent } from './@components'

export const UserDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdUser(uuid)

    return (
        <PageLayout className="UserDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_USERS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.user}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <UserDetailsContent data={query.data} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
