import { CURRENCIES, DEFAULT_CURRENCY_CODE, DEFAULT_DATE_TEMPLATE_FORMAT, EMPTY_CHAR_SYMBOL } from '@/constants'
import {
    corporateServicesConnectedAccountsFilter,
    enabledConnectedAccountsFilter,
    hasConnectedAccountsActivatedServices,
    isFinancialInstitutionConnectedAccount
} from '@/services/ConnectedAccounts'
import { combineFilters } from '@/utils'
import {
    ConnectedAccount,
    CurrencyCode,
    DirectDebitMandate,
    DirectDebitMandateSequenceSchema,
    DirectDebitMandateTypeSchema,
    Direction,
    DirectionSchema,
    PaymentOrder,
    PaymentOrderStatusSchema,
    PaymentOrderType,
    PaymentOrderTypeSchema,
    ReturnRequestReturnReasonSchema,
    SepaReturnReasonSchema
} from '@webapps/numeral-ui-core'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'

export function isSDDPayment(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa && direction === DirectionSchema.enum.debit
}

export function isSCTPayment(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa && direction === DirectionSchema.enum.credit
}

export function isSCTInstPayment(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa_instant && direction === DirectionSchema.enum.credit
}

export function isTreasuryPayment(type?: PaymentOrderType): boolean {
    return type === PaymentOrderTypeSchema.enum.treasury
}

export function isSwiftPayment(type?: PaymentOrderType): boolean {
    return type === PaymentOrderTypeSchema.enum.swift
}

export function hasUltimateOriginator(item?: unknown) {
    return (
        !isEmpty((item as PaymentOrder)?.ultimate_originator?.holder_name) ||
        !isEmpty((item as PaymentOrder)?.ultimate_originator?.organization_identification?.bank_code) ||
        !isEmpty((item as PaymentOrder)?.ultimate_originator?.organization_identification?.other)
    )
}

export function isPaymentOrderReturnRequestAvailable(
    item?: PaymentOrder,
    connectedAccount?: ConnectedAccount
): boolean {
    const hasStatusExecuted = item?.status === PaymentOrderStatusSchema.enum.executed
    const hasFinancialInstitutionAccount = isFinancialInstitutionConnectedAccount(connectedAccount)
    const hasSDDPayment = isSDDPayment(item?.type, item?.direction)

    return hasStatusExecuted && hasFinancialInstitutionAccount && !hasSDDPayment
}

export function isPaymentOrderReturnAvailable(item?: PaymentOrder, connectedAccount?: ConnectedAccount): boolean {
    const hasStatusExecuted = item?.status === PaymentOrderStatusSchema.enum.executed
    const hasFinancialInstitutionAccount = isFinancialInstitutionConnectedAccount(connectedAccount)
    const hasDateOnOrAfterTodayUTC = item?.value_date ? isDateOnOrAfterTodayUTC(new Date(item?.value_date)) : false
    const hasSDDPayment = isSDDPayment(item?.type, item?.direction)

    return hasStatusExecuted && hasFinancialInstitutionAccount && hasSDDPayment && hasDateOnOrAfterTodayUTC

    function isDateOnOrAfterTodayUTC(date: Date): boolean {
        const today = new Date()
        const startOfToday = new Date(
            Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 0, 0, 0)
        )

        return date >= startOfToday
    }
}

export function isPaymentOrderPendingApprovalStatus(item?: PaymentOrder): boolean {
    return item?.status === PaymentOrderStatusSchema.enum.pending_approval
}

export function isPaymentOrderApprovedStatus(item?: PaymentOrder): boolean {
    return item?.status === PaymentOrderStatusSchema.enum.approved
}

export function getPaymentOrderReturnRequestReasonOptions(item?: PaymentOrder, connectedAccount?: ConnectedAccount) {
    switch (true) {
        case isPaymentOrderReturnAvailable(item, connectedAccount): {
            return [
                SepaReturnReasonSchema.enum.AM05,
                SepaReturnReasonSchema.enum.MS02,
                SepaReturnReasonSchema.enum.MS03
            ]
        }

        case isPaymentOrderReturnRequestAvailable(item, connectedAccount):
        default: {
            return ReturnRequestReturnReasonSchema.options
        }
    }
}

export function computePaymentOrderCurrency(paymentOrderType?: PaymentOrderType): CurrencyCode {
    switch (paymentOrderType) {
        case PaymentOrderTypeSchema.enum.fps:
        case PaymentOrderTypeSchema.enum.bacs: {
            return CURRENCIES.GBP.code
        }
        case PaymentOrderTypeSchema.enum.chaps: {
            return CURRENCIES.GBP.code
        }

        case PaymentOrderTypeSchema.enum.sepa:
        case PaymentOrderTypeSchema.enum.sepa_instant: {
            return CURRENCIES.EUR.code
        }

        case PaymentOrderTypeSchema.enum.icelandic_exp: {
            return CURRENCIES.ISK.code
        }

        case PaymentOrderTypeSchema.enum.osko:
        case PaymentOrderTypeSchema.enum.rits:
        case PaymentOrderTypeSchema.enum.becs: {
            return CURRENCIES.AUD.code
        }

        default: {
            return DEFAULT_CURRENCY_CODE
        }
    }
}

export function isPaymentOrderMandateAvailable(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa && direction === DirectionSchema.enum.debit
}

/**
 * Generate a partial direct debit mandate with default properties, used for prefilling payment order forms
 */
export function generatePaymentOrderDefaultDirectDebitMandate(
    type?: PaymentOrderType,
    direction?: Direction
): Pick<DirectDebitMandate, 'reference' | 'signature_date' | 'type' | 'sequence'> | undefined {
    if (!isPaymentOrderMandateAvailable(type, direction)) {
        return
    }

    return {
        reference: EMPTY_CHAR_SYMBOL,
        signature_date: format(new Date(), DEFAULT_DATE_TEMPLATE_FORMAT),
        type: DirectDebitMandateTypeSchema.enum.sepa_core,
        sequence: DirectDebitMandateSequenceSchema.enum.one_off
    }
}

export function createPaymentOrderConnectedAccountServicesFilter(account: ConnectedAccount): boolean {
    if (!hasConnectedAccountsActivatedServices(account)) {
        return false
    }

    return account?.services_activated.filter(globalThis.Boolean).some(corporateServicesConnectedAccountsFilter)
}

export const createPaymentOrderConnectedAccountsCombinedFilter = combineFilters<ConnectedAccount>(
    enabledConnectedAccountsFilter,
    createPaymentOrderConnectedAccountServicesFilter
)
