import { If, Separator, SeparatorVariant } from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { usePermissions, useQueryFindByIdReturn } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import { BaseReconcileEntityHeaderProps } from '@/pages'

export type ReturnHeaderProps = BaseReconcileEntityHeaderProps

export const ReturnHeader: React.FC<ReturnHeaderProps> = ({ id, isReconciling }) => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdReturn(uuid || id)
    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.PAYMENTS_VIEW_RETURNS)) {
            return null
        }

        const hasRelatedPaymentType = !!query.data.related_payment_type

        return (
            <Text as="span">
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <If condition={hasRelatedPaymentType}>
                    <FormattedMessage
                        id="app.payments.returns.details.title"
                        values={{
                            direction: (
                                <FormattedMessage
                                    id={`app.payments.returns.direction.${query.data.related_payment_type}.label`}
                                />
                            )
                        }}
                    />
                    {SPACE_SYMBOL}
                    <Separator variant={SeparatorVariant.Bullet} />
                </If>
                <If condition={!!query.data.created_at}>
                    <FormattedDate value={query.data.created_at} />
                </If>
            </Text>
        )
    }, [isReconciling, query, hasPermission])

    return <PageHeaderLayout title={title} isLoading={query.isLoading} />
}
