import { getFormButtonLoadingLeftIcon } from '@/components'
import { If } from '@/components/@misc'
import {
    useBulkActions,
    useMutationCreateConcurrentReconciliations,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePage,
    usePermissions,
    usePrevious,
    useToasts
} from '@/hooks'
import {
    enrichReconciliationTargetObjectsSelection,
    getReconcilableTargetObjectAbsolutePath,
    getReconcilableTargetObjectRelativeReconciliationsPath,
    getShouldRedirectAfterReconciliation,
    RECONCILE_ENTITY_PAGE_CONTEXT,
    TransactionExcludeConfirmationModal,
    useReconciliation
} from '@/pages'
import { TOAST_VARIANTS, useBulkActionsPluralization } from '@/providers'
import {
    computeReconcilableAmount,
    isEntityUnreconciled,
    isReconcilablePaymentObjectType,
    isReconcilableTransactionObjectType,
    PERMISSION,
    RECONCILIATIONS_REJECTIONS
} from '@/services'
import { Payment } from '@/types'
import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import {
    ApiObjectType,
    ApiObjectTypeSchema,
    ReconciliationCreateOrUpdateData,
    Transaction
} from '@webapps/numeral-ui-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'
import { isReconciliationDisabled } from './ReconcileEntityFooter.utils'

export const ReconcileEntityFooterAction: React.FC<{ targetObject: ApiObjectType; isDisabled?: boolean }> = (props) => {
    const location = useLocation()
    const { hasPermission } = usePermissions()
    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const previousReconcileEntityNonPersistedState = usePrevious(reconcileEntityNonPersistedState)
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const { onStartReconciliation, onCancelReconciliation } = useReconciliation()
    const { selection, data, isLoading, onResetSelection } = useBulkActions<typeof reconcileEntityNonPersistedState>()
    const sourceObjectPluralization = useBulkActionsPluralization(reconcileEntityNonPersistedState?.object, 1, false)
    const targetObjectPluralization = useBulkActionsPluralization(props.targetObject, selection?.size, false)

    const mutationCreateConcurrentReconciliations = useMutationCreateConcurrentReconciliations({
        onSuccess(response) {
            onResetSelection()
            onCancelReconciliation()

            switch (response) {
                case RECONCILIATIONS_REJECTIONS.PARTIAL_REJECTION: {
                    const { formattedObjectType } = targetObjectPluralization

                    onAdd({
                        status: 'warning',
                        variant: TOAST_VARIANTS.WARNING,
                        title: <FormattedMessage id="app.reconcile.create.warning.toast.title" />,
                        description: (
                            <FormattedMessage
                                id="app.reconcile.create.warning.toast.description"
                                values={{
                                    objectType: formattedObjectType
                                }}
                            />
                        )
                    })
                    break
                }

                default: {
                    const { formattedObjectType } = sourceObjectPluralization

                    onAdd({
                        status: 'success',
                        variant: TOAST_VARIANTS.SUCCESS,
                        title: <FormattedMessage id="app.reconcile.create.success.toast.title" />,
                        description: (
                            <FormattedMessage
                                id="app.reconcile.create.success.toast.description"
                                values={{
                                    objectType: formattedObjectType
                                }}
                            />
                        )
                    })
                }
            }
        },
        onError() {
            const { formattedObjectType } = targetObjectPluralization

            onAdd({
                status: 'error',
                variant: TOAST_VARIANTS.ERROR,
                title: <FormattedMessage id="app.reconcile.create.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.reconcile.create.error.toast.description"
                        values={{
                            objectType: formattedObjectType
                        }}
                    />
                )
            })
        }
    })
    const enrichedSelection = useMemo(() => {
        return enrichReconciliationTargetObjectsSelection<typeof reconcileEntityNonPersistedState>(selection, data)
    }, [selection, data])
    const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false)

    const canExcludeTransaction = useMemo(() => {
        const hasNoReconcileContext = !reconcileEntityNonPersistedState
        const hasConfigureReconciliationsPermission = hasPermission(
            PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS
        )
        const transaction: Transaction | undefined = enrichedSelection?.at(0) as Transaction

        return (
            hasNoReconcileContext &&
            hasConfigureReconciliationsPermission &&
            isReconcilableTransactionObjectType(transaction) &&
            isEntityUnreconciled(transaction)
        )
    }, [reconcileEntityNonPersistedState, enrichedSelection])

    const onReconcile = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            if (!reconcileEntityNonPersistedState) {
                return
            }

            const { id } = reconcileEntityNonPersistedState
            const metadata = Object.create(null)
            const data: ReconciliationCreateOrUpdateData[] = enrichedSelection.map((item) => {
                const amount = computeReconcilableAmount(reconcileEntityNonPersistedState, item)

                if (isReconcilablePaymentObjectType(reconcileEntityNonPersistedState)) {
                    return {
                        transaction_id: item?.id,
                        payment_id: id,
                        amount,
                        metadata
                    }
                }

                return {
                    transaction_id: id,
                    payment_id: item?.id,
                    amount,
                    metadata
                }
            })

            mutationCreateConcurrentReconciliations.mutate(data)
        },
        [enrichedSelection, reconcileEntityNonPersistedState, mutationCreateConcurrentReconciliations]
    )
    const onNavigate = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const selectedItem = enrichedSelection.at(0)
            event.stopPropagation()

            /**
             * @description
             * 1 If the current object is a transaction, only the current path changes (for picking the payment type);
             * 2. If the current object is a not a transaction (but reconcilable payment type),
             * the reconciliation flow will start with it (`item`) against the transactions;
             */
            switch (props.targetObject) {
                case ApiObjectTypeSchema.enum.transaction: {
                    const { ACCOUNTS } = relativePaths
                    const path = [location.pathname, selectedItem?.id, ACCOUNTS.RECONCILE].join('/')

                    navigateWithLegalEntityID(path)
                    break
                }

                default: {
                    onStartReconciliation(ApiObjectTypeSchema.enum.transaction, selectedItem)
                }
            }
        },
        [
            location,
            props.targetObject,
            navigateWithLegalEntityID,
            relativePaths,
            onStartReconciliation,
            enrichedSelection
        ]
    )
    const onCloseTransactionExcludeConfirmationModal = useCallback(() => {
        setIsExcludeModalOpen(false)
    }, [])

    const propsExcludeButton: ButtonProps = useMemo(() => {
        return {
            onClick() {
                setIsExcludeModalOpen(true)
            },
            variant: 'secondary',
            isDisabled: isLoading || props?.isDisabled,
            leftIcon: getFormButtonLoadingLeftIcon(isLoading)
        }
    }, [isLoading, props?.isDisabled])

    const propsReconcileButton: ButtonProps = useMemo(() => {
        const onClick = reconcileEntityNonPersistedState ? onReconcile : onNavigate
        const hasReconcileEntityLoading = mutationCreateConcurrentReconciliations.isPending || isLoading
        const hasReconciliationDisabled = isReconciliationDisabled<(typeof enrichedSelection)[0]>(
            reconcileEntityNonPersistedState,
            enrichedSelection
        )
        const isDisabled =
            props?.isDisabled || reconcileEntityNonPersistedState
                ? hasReconciliationDisabled
                : hasReconcileEntityLoading

        return {
            onClick,
            isDisabled: isDisabled || hasReconcileEntityLoading,
            leftIcon: getFormButtonLoadingLeftIcon(hasReconcileEntityLoading)
        }
    }, [
        reconcileEntityNonPersistedState,
        enrichedSelection,
        isLoading,
        mutationCreateConcurrentReconciliations,
        onReconcile,
        onNavigate,
        props?.isDisabled
    ])

    useEffect(() => {
        if (mutationCreateConcurrentReconciliations.isSuccess && previousReconcileEntityNonPersistedState) {
            const { id, object } = previousReconcileEntityNonPersistedState
            const shouldRedirectAfterReconciliation = getShouldRedirectAfterReconciliation(object)

            if (!shouldRedirectAfterReconciliation) {
                return
            }

            const contextObjectAbsolutePath = getReconcilableTargetObjectAbsolutePath(object)
            const contextObjectRelativePath = getReconcilableTargetObjectRelativeReconciliationsPath(object)
            const path = [contextObjectAbsolutePath, id, contextObjectRelativePath].join('/')

            navigateWithLegalEntityID(path)
        }
    }, [mutationCreateConcurrentReconciliations, previousReconcileEntityNonPersistedState])

    return (
        <>
            <Flex gap="var(--numeral-ui-primary-spacing)">
                <If condition={canExcludeTransaction}>
                    <Button {...propsExcludeButton}>
                        <FormattedMessage id="app.accounts.transactions.actions.exclude.label" />
                    </Button>
                </If>
                <Button {...propsReconcileButton}>
                    <FormattedMessage id="app.reconcile.footer.actions.reconcile.label" />
                </Button>
            </Flex>

            <If condition={canExcludeTransaction}>
                <TransactionExcludeConfirmationModal
                    uuid={enrichedSelection.at(0)?.id}
                    isOpen={isExcludeModalOpen}
                    onClose={onCloseTransactionExcludeConfirmationModal}
                />
            </If>
        </>
    )
}
