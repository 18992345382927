import { QueryFallback, RestrictedSection } from '@/components'
import { APIValidationErrorNotificationBody } from '@/components/@misc'
import {
    useMutationUpdateByIdCustomField,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdCustomField,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { CustomField } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { CustomFieldForm } from '../@components'

export const CustomFieldEditPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdCustomField(uuid)
    const mutation = useMutationUpdateByIdCustomField(uuid, {
        onSuccess() {
            const path = [paths.SETTINGS.CUSTOM_FIELDS, uuid, relativePaths.SETTINGS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.custom_fields.edit.form.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.custom_fields.edit.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.custom_fields.edit.form.validation.error.toast.title" />,
                description: (
                    <APIValidationErrorNotificationBody<CustomField>
                        error={error}
                        messageId="app.settings.custom_fields.edit.form.validation.error.toast.description"
                    />
                )
            })
        }
    })

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const title = intl.formatMessage({
            id: 'app.settings.custom_fields.edit.title'
        })

        return {
            title,
            isLoading: query.isLoading
        }
    }, [intl, query])

    return (
        <PageLayout className="CustomFieldEdit">
            <PageHeaderLayout {...propsPageHeaderLayout} />
            <RestrictedSection permission={PERMISSION.SETTINGS_EDIT_CUSTOM_FIELDS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.custom_field}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <CustomFieldForm
                        data={query?.data}
                        loading={query.isLoading}
                        disabled={query.isLoading}
                        onSubmit={mutation.mutate}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
