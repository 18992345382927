import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdReconciliationRule } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ReconciliationRule } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import {
    RECONCILIATION_RULE_DETAILS_ROW_CUSTOMIZERS,
    RECONCILIATION_RULE_DETAILS_ROWS
} from './ReconciliationRuleDetails.page.const'
import { ReconciliationRuleDetailsCustomRows } from './ReconciliationRuleDetails.page.type'

export const ReconciliationRuleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdReconciliationRule(uuid)

    return (
        <PageLayout className="PaymentRetryRuleDetailsDetails">
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.SETTINGS_VIEW_RECONCILIATION_RULES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.reconciliation_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<ReconciliationRule, ReconciliationRuleDetailsCustomRows>
                        data={query.data}
                        rows={RECONCILIATION_RULE_DETAILS_ROWS}
                        rowCustomizers={RECONCILIATION_RULE_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
