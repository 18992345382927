import { Flex, FlexProps } from '@chakra-ui/react'
import { CheckmarkIcon, SkipToNext, TimelineIcon } from '@/components/@icons'

const propsFlex: FlexProps = {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '21px',
    height: '21px',
    flex: '0 0 21px'
}

export interface PaymentOrderApprovalStatusIconProps {
    hasBeenApproved?: boolean
    hasBeenApprovedByAPIKey?: boolean
}

export const PaymentOrderApprovalStatusIcon: React.FC<PaymentOrderApprovalStatusIconProps> = ({
    hasBeenApproved,
    hasBeenApprovedByAPIKey
}) => {
    switch (true) {
        case hasBeenApproved: {
            return (
                <Flex {...propsFlex} backgroundColor="green.500">
                    <CheckmarkIcon color="white" />
                </Flex>
            )
        }

        case !hasBeenApproved && hasBeenApprovedByAPIKey: {
            return (
                <Flex {...propsFlex} backgroundColor="gray.500">
                    <SkipToNext color="white" />
                </Flex>
            )
        }

        case !hasBeenApproved: {
            return (
                <Flex {...propsFlex} backgroundColor="numeralAccent.500">
                    <TimelineIcon color="white" />
                </Flex>
            )
        }

        default: {
            return null
        }
    }
}
