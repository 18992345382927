import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { usePermissions, useQueryFindByIdAccountHolder } from '@/hooks'
import { PERMISSION } from '@/services'
import { Skeleton, Text } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { RestrictedData } from '../RestrictedData'
import { DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'

interface AccountHolderNameProps {
    accountHolderId?: Uuid
    asPlainText?: boolean
}

export const AccountHolderName: React.FC<AccountHolderNameProps> = React.memo(({ accountHolderId, asPlainText }) => {
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdAccountHolder(accountHolderId, {
        enabled: globalThis.Boolean(accountHolderId)
    })
    const formattedName = useMemo(() => {
        return query.data?.name
    }, [query.data])

    switch (true) {
        case !hasPermission(PERMISSION.COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS): {
            return <RestrictedData />
        }
        case query.isLoading: {
            return <Skeleton height="14px" width="50%" />
        }

        case !accountHolderId:
        case query.isError:
        case isEmpty(query.data?.name): {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case asPlainText: {
            return (
                <Text as="span" title={formattedName}>
                    {formattedName}
                </Text>
            )
        }

        default: {
            return (
                <DetailPageLink objectId={query.data?.id} objectType={ApiObjectTypeExtendedSchema.enum.account_holder}>
                    {formattedName}
                </DetailPageLink>
            )
        }
    }
})
