import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { COLUMN_WIDTH_RATIO } from '@/constants'
import { Role } from '@/services'
import { UserRoleWithCustomRoleTag } from '@/components/@misc'

export const ROLES_TABLE_STATIC_STATE: TableStateWithEntity<Role> = {
    columnOrder: Object.freeze(['name', 'description'])
}

export const ROLES_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Role> = {
    name: {
        size: COLUMN_WIDTH_RATIO.ROLE_NAME,
        cell(info) {
            return <UserRoleWithCustomRoleTag role={info.row.original} />
        }
    }
}
