import React, { memo, useMemo } from 'react'
import { ApiObjectType, ReconciliationStatusSchema } from '@webapps/numeral-ui-core'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'
import { LinkWithFilterBy } from '@/components/@misc'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'
import { DEFAULT_DATE_TEMPLATE_FORMAT } from '@/constants'
import { computeDateRangeFilterByLookBehindOffset, computeIntervalQueryKeysByObjectType } from '@/services'
import { getNavigationRouteByObjectType } from '../ReconciliationsWidget.utils'
import { formatNumber } from 'chart.js/helpers'
import { Box, BoxProps } from '@chakra-ui/react'

interface TableCellUnreconciledStatusLinkProps extends BoxProps {
    objectType: ApiObjectType
    value: number
    lookBehindOffset: number
}

export const TableCellUnreconciledStatusLink: React.FC<TableCellUnreconciledStatusLinkProps> = memo(
    ({ objectType, value, lookBehindOffset, ...boxProps }) => {
        const intl = useIntl()
        const filterByState = useMemo(() => {
            const [startDate, endDate] = computeDateRangeFilterByLookBehindOffset(lookBehindOffset)
            const [start_interval_key, end_interval_key] = computeIntervalQueryKeysByObjectType(objectType)

            return {
                reconciliation_status: ReconciliationStatusSchema.enum.unreconciled,
                [start_interval_key]: format(startDate, DEFAULT_DATE_TEMPLATE_FORMAT),
                [end_interval_key]: format(endDate, DEFAULT_DATE_TEMPLATE_FORMAT)
            }
        }, [lookBehindOffset])
        const path = useMemo(() => {
            return getNavigationRouteByObjectType(objectType)
        }, [objectType])
        const formattedValue = useMemo(() => {
            const valueWithDefault = value ?? 0
            return formatNumber(valueWithDefault, intl.locale)
        }, [value, intl])

        return (
            <Box {...boxProps} textAlign="right">
                <LinkWithFilterBy<typeof filterByState>
                    filterBy={filterByState}
                    path={path}
                    title={formattedValue}
                    aria-label={formattedValue}
                    fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}>
                    {formattedValue}
                </LinkWithFilterBy>
            </Box>
        )
    }
)
