import { Form, FormPageFooter, FormSection } from '@/components'
import { ApiObjectTypeSchema, InternalAccount, InternalAccountTypeSchema } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { InternalAccountFormProps } from './InternalAccount.form.types'
import { getInternalAccountFormInitialValues } from './InternalAccount.form.utils'
import {
    ConnectedAccountsInput,
    InternalAccountTypeInput,
    InternalAccountTypeInputSelectOption,
    TextInput
} from '@/components/@inputs'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { CustomFieldsSection } from '@/pages'
import { isCorporateConnectedAccount, isFinancialInstitutionConnectedAccount } from '@/services'

export const InternalAccountForm: React.FC<InternalAccountFormProps> = ({ disabled, loading, onSubmit = noop }) => {
    const initialValues = useMemo(getInternalAccountFormInitialValues, [])
    return (
        <Form<Partial<InternalAccount>> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const isBankCodeInputInputDisplayed = values.type === InternalAccountTypeSchema.enum.own
                const connectedAccountsFilter = (() => {
                    switch (values.type) {
                        case InternalAccountTypeSchema.enum.virtual: {
                            return isCorporateConnectedAccount
                        }
                        case InternalAccountTypeSchema.enum.own: {
                            return isFinancialInstitutionConnectedAccount
                        }
                    }
                })()

                return (
                    <>
                        <FormSection titleId="Account settings">
                            <TextInput
                                name="name"
                                customPlaceholderKey="app.counterparties.internal_accounts.create.form.inputs.name.placeholder"
                                isDisabled={disabled}
                                isRequired={true}
                                data-testid="name"
                            />
                            <InternalAccountTypeInput
                                name="type"
                                customPlaceholderKey="app.counterparties.internal_accounts.create.form.inputs.type.placeholder"
                                isDisabled={disabled}
                                isClearable={false}
                                isRequired={true}
                                data-testid="type"
                                components={{
                                    Option: InternalAccountTypeInputSelectOption
                                }}
                            />
                            <ConnectedAccountsInput
                                name="connected_account_ids[0]"
                                customPlaceholderKey="app.counterparties.internal_accounts.create.form.inputs.connected_account_ids.placeholder"
                                isDisabled={disabled}
                                queryDataFilter={connectedAccountsFilter}
                                isRequired={true}
                                data-testid="connected_account_ids[0]"
                            />
                        </FormSection>
                        <FormSection titleId="Account details">
                            <AccountDetailsForm
                                isLookupAvailable={false}
                                isDisabled={disabled}
                                isBankCodeInputInputDisplayed={isBankCodeInputInputDisplayed}
                                isAlternativeHolderNamesInputDisplayed={true}
                                isRequired={true}
                            />
                        </FormSection>
                        <OptionalAccountHolderAddressForm isDisabled={disabled} />
                        <TextInput name="creditor_identifier" data-testid="creditor_identifier" isDisabled={disabled} />
                        <CustomFieldsSection<InternalAccount>
                            objectType={ApiObjectTypeSchema.enum.internal_account}
                            isDisabled={disabled}
                            isExpanded={false}
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.counterparties.internal_accounts.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
