import {
    DirectDebitMandateStatusSchema,
    EventStatusSchema,
    FileStatusSchema,
    IncomingPaymentStatusSchema,
    InquiryStatusSchema,
    InternalAccountStatusSchema,
    PaymentApprovalRuleStatusSchema,
    PaymentOrderStatusSchema,
    ReconciliationStatusSchema,
    ReturnRequestStatusSchema
} from '@webapps/numeral-ui-core'
import { StatusLabelValue } from './StatusLabel.types'
import { ExtendedStatusSchema } from '@/schemas'

export const computeStatusLabelProps = (status?: StatusLabelValue) => {
    const partialLabelId = 'api.status.'

    switch (status) {
        case PaymentApprovalRuleStatusSchema.enum.paused:
        case InternalAccountStatusSchema.enum.disabled:
        case ReconciliationStatusSchema.enum.excluded:
        case IncomingPaymentStatusSchema.enum.pending_confirmation:
        case PaymentOrderStatusSchema.enum.sent:
        case PaymentOrderStatusSchema.enum.pending:
        case PaymentOrderStatusSchema.enum.pending_approval:
        case PaymentOrderStatusSchema.enum.processing:
        case InquiryStatusSchema.enum.processing:
        case InquiryStatusSchema.enum.sent:
        case InquiryStatusSchema.enum.created:
        case InquiryStatusSchema.enum.archived:
        case EventStatusSchema.enum.created:
        case EventStatusSchema.enum.archived:
        case PaymentOrderStatusSchema.enum.retried: {
            return {
                color: 'gray.600',
                backgroundColor: 'gray.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        case IncomingPaymentStatusSchema.enum.pending_return:
        case PaymentOrderStatusSchema.enum.returned:
        case EventStatusSchema.enum.pending_retry: {
            return {
                color: 'orange.600',
                backgroundColor: 'orange.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        case ExtendedStatusSchema.enum.verified:
        case ExtendedStatusSchema.enum.enabled:
        case ExtendedStatusSchema.enum.activate:
        case ExtendedStatusSchema.enum.uploaded:
        case PaymentApprovalRuleStatusSchema.enum.active:
        case InternalAccountStatusSchema.enum.active:
        case IncomingPaymentStatusSchema.enum.confirmed:
        case ReturnRequestStatusSchema.enum.accepted:
        case PaymentOrderStatusSchema.enum.approved:
        case PaymentOrderStatusSchema.enum.executed:
        case IncomingPaymentStatusSchema.enum.received:
        case InquiryStatusSchema.enum.received:
        case FileStatusSchema.enum.processed:
        case EventStatusSchema.enum.delivered: {
            return {
                color: 'green.600',
                backgroundColor: 'green.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        case ExtendedStatusSchema.enum.deactivate:
        case InternalAccountStatusSchema.enum.blocked:
        case DirectDebitMandateStatusSchema.enum.blocked:
        case PaymentOrderStatusSchema.enum.canceled:
        case EventStatusSchema.enum.failed:
        case ExtendedStatusSchema.enum.failed:
        case FileStatusSchema.enum.rejected:
        case InquiryStatusSchema.enum.denied:
        case ReturnRequestStatusSchema.enum.denied: {
            return {
                color: 'red.600',
                backgroundColor: 'red.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        default: {
            return {
                color: 'gray.600',
                backgroundColor: 'gray.100'
            }
        }
    }
}
