import { Form, FormPageFooter, FormSection } from '@/components'
import { ReturnRequestReturnReasonInput } from '@/components/@inputs'
import { PaymentOrder, ReturnCreateData } from '@webapps/numeral-ui-core'
import { FormikValues } from 'formik'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'

interface PaymentOrderReturnFormProps {
    data?: PaymentOrder
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: ReturnCreateData): void
}

export const PaymentOrderReturnForm: React.FC<PaymentOrderReturnFormProps> = ({
    data,
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useRef<Partial<ReturnCreateData>>({
        return_reason: undefined
    }).current
    const onFormSubmit = useCallback(
        (values: FormikValues) => {
            if (data?.id) {
                onSubmit({
                    ...values,
                    related_payment_id: data.id
                })
            }
        },
        [data, onSubmit]
    )

    return (
        <Form<Partial<ReturnCreateData>> initialValues={initialValues} onSubmit={onFormSubmit}>
            {({ handleSubmit, dirty }) => {
                return (
                    <FormSection titleId="app.common.form.sections.general_information.title">
                        <ReturnRequestReturnReasonInput
                            name="return_reason"
                            isPlaceholderDisplayed={true}
                            data={data}
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.payments.payment_orders.return.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </FormSection>
                )
            }}
        </Form>
    )
}
