import { If, Select, useSearchQuerySelect } from '@/components/@misc'
import { useCustomFormikField, usePermissions, useQueryFindAllRoles } from '@/hooks'
import { FormikInput } from '@/types'
import { queryDataAggregation } from '@/utils'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { uuidValidator } from '@/utils/@validators'
import { PERMISSION, Role } from '@/services'
import { TextInput } from '@/components/@inputs'
import { UserRoleNameSelectOption } from '@/components/@inputs/UserRolesInput/@components'

interface UserRolesInputProps extends FormikInput {
    customPlaceholderKey?: string
}

export const UserRolesInput: React.FC<UserRolesInputProps> = ({
    name,
    value,
    customPlaceholderKey,
    searchOnMount,
    isLabelDisplayed,
    isPlaceholderDisplayed = false,
    isRequired = false,
    isDisabled = false,
    validator = uuidValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const [field, meta, helpers] = useCustomFormikField<Uuid>({
        name,
        value,
        validate: validator(intl, { name, isRequired })
    })
    const { enabled, limit, inputText, onInputChange, onFocus } = useSearchQuerySelect(searchOnMount)
    const query = useQueryFindAllRoles(
        { limit },
        {
            enabled: !isDisabled && enabled
        }
    )
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, intl, query, isPlaceholderDisplayed])
    const onChange = useCallback(
        (value: Uuid) => {
            inputProps.onChange?.(value)
            helpers.setValue(value, true)
        },
        [helpers, inputProps, data]
    )
    const isInvalid = useMemo(() => {
        return meta?.touched && !!meta?.error
    }, [meta])

    if (hasPermission(PERMISSION.SETTINGS_VIEW_ROLES)) {
        return (
            <FormControl key={name} isInvalid={isInvalid}>
                <If condition={isLabelDisplayed}>
                    <FormLabel htmlFor={name}>
                        <FormattedMessage id={`app.common.form.input.${name}.label`} />
                    </FormLabel>
                </If>
                <Select<Role>
                    {...inputProps}
                    {...field}
                    id={name}
                    options={data}
                    placeholder={placeholder}
                    isInvalid={isInvalid}
                    isRequired={isRequired}
                    isDisabled={isDisabled}
                    isLoading={query.isLoading}
                    isClearable={true}
                    inputValue={inputText}
                    onChange={onChange}
                    onFocus={onFocus}
                    onInputChange={onInputChange}
                    components={{
                        Option: UserRoleNameSelectOption
                    }}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                />
                <FormErrorMessage>{meta?.error}</FormErrorMessage>
            </FormControl>
        )
    }

    return <TextInput {...field} {...inputProps} id={name} placeholder={placeholder} validator={validator} />
}
