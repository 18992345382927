import { FilesIcon } from '@/components/@icons'
import { setTimeout } from '@/utils'
import { FormatterFunction } from '@/utils/@formatters'
import { Button, ButtonProps, Text, Tooltip } from '@chakra-ui/react'
import { noop } from '@tanstack/react-table'
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

interface CopyToClipboardButtonProps {
    value: string | number | readonly string[]
    formatter?: FormatterFunction
}

type WrapperComponent = React.FC<PropsWithChildren>

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ value, formatter }) => {
    const intl = useIntl()
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)
    const title = useRef(
        intl.formatMessage({
            id: 'app.common.actions.copy.title'
        })
    ).current

    const label = useMemo<string>(() => intl.formatMessage({ id: 'app.common.actions.copy.label' }), [intl])
    const confirmation = useRef<string>(
        intl.formatMessage({
            id: 'app.common.actions.copy.confirmation'
        })
    ).current
    const ariaLabelText = useRef<string>(
        intl.formatMessage({
            id: 'app.common.actions.copy.aria_label'
        })
    ).current

    const CopyToClipboardWrapper = useMemo<WrapperComponent>(() => {
        if (!isTooltipVisible) {
            return ({ children }) => <>{children}</>
        }

        return ({ children }) => (
            <Tooltip hasArrow={true} label={confirmation} aria-label={ariaLabelText} placement="top">
                {children}
            </Tooltip>
        )
    }, [ariaLabelText, isTooltipVisible, confirmation])

    const onClick = useCallback(() => {
        const text = formatter?.(value) || value?.toString()

        if (!text?.length) {
            return
        }

        globalThis.navigator.clipboard
            .writeText(text)
            .then(() => setIsTooltipVisible(true))
            .catch(noop)
    }, [value, formatter])

    const propsButton = useRef<ButtonProps>({
        width: '100px',
        height: 'auto',
        padding: 0,
        _hover: {
            color: 'gray.800'
        },
        leftIcon: <FilesIcon />
    }).current

    useEffect(() => {
        let cancelTimeout: CallableFunction

        if (isTooltipVisible) {
            cancelTimeout = setTimeout(setIsTooltipVisible, 500)
        }

        return () => cancelTimeout?.()
    }, [isTooltipVisible])

    return (
        <CopyToClipboardWrapper>
            <Button {...propsButton} title={title} color="gray.500" onClick={onClick} variant="ghost">
                <Text>{label}</Text>
            </Button>
        </CopyToClipboardWrapper>
    )
}
