import { Link, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { FILE_UPLOAD_SAMPLE_FILE_URL } from '../FileUploadInput.const'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'

export const FileUploadInputStatusActive: React.FC<FileUploadInputStatusProps> = ({
    color,
    browseLink,
    formattedAcceptedSize,
    fontWeight,
    ...props
}) => {
    return (
        <Stack alignItems="center" gap="calc(var(--numeral-ui-primary-spacing)/2)">
            <Text fontWeight={fontWeight} {...props}>
                <FormattedMessage id="app.common.form.input.file.placeholder" values={{ browseLink }} />
            </Text>
            <Text color={color} width="80%" textAlign="center" fontSize="smaller">
                <FormattedMessage
                    id="app.common.form.input.file.placeholder.alternative"
                    values={{
                        downloadTemplateLink: (
                            <Link href={FILE_UPLOAD_SAMPLE_FILE_URL}>
                                <FormattedMessage id="app.common.form.input.file.actions.download_template" />
                            </Link>
                        ),
                        formattedAcceptedSize
                    }}
                />
            </Text>
        </Stack>
    )
}
