import { FormikInput } from '@/types'
import { HTMLChakraProps } from '@chakra-ui/system'
import { InputHTMLAttributes, ReactNode } from 'react'

export type FileUploadInputProps = Omit<FormikInput, 'value'> & {
    value?: File | File[]
    acceptedSize?: number
    acceptedExtensions: string[]
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>

export enum FileUploadInputDataTransferKind {
    FILE = 'file'
}

export type FileUploadInputStatusProps = HTMLChakraProps<any> & {
    color?: string
    error?: any
    browseLink?: ReactNode
    formattedAcceptedSize?: string
    fileCount?: number
}

export enum FileReaderReadyState {
    EMPTY = 0, // No data loaded.
    LOADING = 1, // Data is currently being loaded.
    DONE = 2 // Data has been loaded successfully.
}
