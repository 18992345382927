import { EntityAction } from '@/components'
import {
    useMutationEnableByIdUser,
    useMutationResendUserInviteById,
    useMutationResetUserMFAById,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindCurrentUserInfo,
    useToasts
} from '@/hooks'
import { TOAST_VARIANTS, updateUUIDRelativeActionInPathname } from '@/providers'
import {
    isCurrentUser,
    isUserStatusDisabled,
    isUserStatusPending,
    isUserStatusVerified,
    PERMISSION,
    User
} from '@/services'
import { UseDisclosureProps } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router'

export function useUserActions(user?: User, modalDisclosure?: UseDisclosureProps) {
    const { uuid } = useParams()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const queryUserInfo = useQueryFindCurrentUserInfo()

    const mutationEnable = useMutationEnableByIdUser(uuid || user?.id)

    const mutationResendInvite = useMutationResendUserInviteById(uuid || user?.id, {
        onSuccess() {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.users.edit.actions.resend_invite.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.users.edit.actions.resend_invite.success.toast.description" />
                )
            })
        },

        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.users.edit.actions.resend_invite.error.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.users.edit.actions.resend_invite.error.toast.description" />
                )
            })
        }
    })

    const mutationResetMFA = useMutationResetUserMFAById(uuid || user?.id, {
        onSuccess() {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.users.edit.actions.reset_mfa.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.users.edit.actions.reset_mfa.success.toast.description" />
                )
            })
        },

        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.users.edit.actions.reset_mfa.error.toast.title" />,
                description: <FormattedMessage id="app.settings.users.edit.actions.reset_mfa.error.toast.description" />
            })
        }
    })

    const actions: EntityAction[] = []

    if (hasPermission(PERMISSION.SETTINGS_EDIT_USERS)) {
        actions.push({
            onClick() {
                const { SETTINGS, EDIT, UUID } = relativePaths
                const path = uuid
                    ? updateUUIDRelativeActionInPathname(location.pathname, SETTINGS.EDIT)
                    : EDIT.replace(UUID, user?.id as string)

                navigateWithLegalEntityID(path)
            },
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.edit.label'
            })
        })
    }

    if (hasPermission(PERMISSION.SETTINGS_INVITE_USERS) && isUserStatusPending(user)) {
        actions.push({
            onClick() {
                mutationResendInvite.mutate()
            },
            isLoading: mutationResendInvite.isPending,
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.resend_invite.label'
            })
        })
    }

    if (hasPermission(PERMISSION.SETTINGS_EDIT_USERS) && isUserStatusVerified(user)) {
        actions.push({
            onClick() {
                mutationResetMFA.mutate()
            },
            isLoading: mutationResetMFA.isPending,
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.reset_mfa.label'
            })
        })
    }

    if (
        hasPermission(PERMISSION.SETTINGS_DISABLE_USERS) &&
        !isUserStatusDisabled(user) &&
        !isCurrentUser(queryUserInfo.data, user)
    ) {
        actions.push({
            onClick: modalDisclosure?.onOpen,
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.disable.label'
            }),
            color: 'red.500'
        })
    }

    if (hasPermission(PERMISSION.SETTINGS_INVITE_USERS) && isUserStatusDisabled(user)) {
        actions.push({
            onClick() {
                mutationEnable.mutate()
            },
            isLoading: mutationEnable.isPending,
            children: intl.formatMessage({
                id: 'app.settings.users.edit.actions.enable.label'
            })
        })
    }

    return actions
}
