import { useQueryFindByIdPaymentCapture } from '@/hooks'
import { ReconciliationsPage } from '@/pages'
import { PaymentCapture, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { useParams } from 'react-router'

export const PaymentCaptureReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentCapture(uuid)

    return (
        <ReconciliationsPage<PaymentCapture> paymentType={PaymentTypeSchema.enum.payment_capture} queryItem={query} />
    )
}
