import { Separator, SeparatorVariant } from '@/components/@misc'
import { usePermissions, useQueryFindByIdConnectedAccount } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import { ConnectedAccount } from '@webapps/numeral-ui-core'
import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router'

export const ConnectedAccountDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdConnectedAccount(uuid)

    const title = useMemo<ReactNode>(() => {
        const connectedAccount: ConnectedAccount | undefined = query.data

        if (!connectedAccount || !hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS)) {
            return null
        }

        return (
            <>
                <Text as="span">{connectedAccount.bank_name}</Text>
                <Separator variant={SeparatorVariant.Bullet} />
                <Text as="span">{connectedAccount.name}</Text>
            </>
        )
    }, [query, hasPermission])

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        return {
            title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [query, title])

    return <PageHeaderLayout {...propsPageHeaderLayout} />
}
