import { ConnectedAccountName, FormattedAmount, FormattedDate, Separator, SeparatorVariant } from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { usePermissions, useQueryFindByIdBalance } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { prettyPrintCasedWords } from '@/utils'
import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router'

export const BalanceDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()

    const query = useQueryFindByIdBalance(uuid)
    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.ACCOUNTS_VIEW_BALANCES)) {
            return null
        }

        const amount = query.data.amount
        const displayDate = query.data.created_at
        const balanceType = query.data.type
        const connectedAccountId = query.data.connected_account_id
        const showConnectedAccount = hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS) && !!connectedAccountId

        return (
            <>
                {amount && (
                    <>
                        <FormattedAmount amount={amount} currency={query.data.currency} />
                        {SPACE_SYMBOL}
                        {query.data.direction}
                    </>
                )}
                {balanceType && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        {prettyPrintCasedWords(balanceType)}
                    </>
                )}
                {showConnectedAccount && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        <ConnectedAccountName connectedAccountId={connectedAccountId} asPlainText={true} />
                    </>
                )}
                {displayDate && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        <FormattedDate value={displayDate} />
                    </>
                )}
            </>
        )
    }, [query, hasPermission])

    return <PageHeaderLayout title={title} isLoading={query.isLoading} />
}
