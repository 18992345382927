import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { z } from 'zod'

const API_OBJECT_TYPE_VALUES = [
    'bank',
    'api_key',
    'webhook',
    'user',
    'audit_trail',
    'role',
    ...ApiObjectTypeSchema.options
] as const
export const ApiObjectTypeExtendedSchema = z.enum(API_OBJECT_TYPE_VALUES)

const STATUS_VALUES = ['deactivate', 'verified', 'enabled', 'activate', 'uploaded', 'failed'] as const
export const ExtendedStatusSchema = z.enum(STATUS_VALUES)
