import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { ReconciliationItem, WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES } from '@/services'
import { Box, Text } from '@chakra-ui/react'
import { prettyPrintCasedWords } from '@/utils'
import { TableCellUnreconciledStatusLink } from './@components'
import { HOME_PAGE_STYLE_CONFIGURATION } from '../../Home.page.const'
import { FormattedMessage } from 'react-intl'

export const RECONCILIATIONS_WIDGET_TABLE_STATIC_STATE: TableStateWithEntity<ReconciliationItem> = {
    columnOrder: ['object', 'unreconciled_yesterday', 'unreconciled_last_seven_days', 'total_reconciled']
}

export const RECONCILIATIONS_WIDGET_COLUMN_CUSTOMIZERS: TableColumnCustomizers<ReconciliationItem> = {
    object: {
        header() {
            const { WIDGET_LITE_TABLE_MIN_HEADER_WIDTH } = HOME_PAGE_STYLE_CONFIGURATION
            return <Box minWidth={WIDGET_LITE_TABLE_MIN_HEADER_WIDTH} />
        },
        cell(info) {
            return (
                <Text color="gray.500" fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}>
                    <FormattedMessage
                        id="app.common.pluralization.label"
                        values={{
                            count: 2,
                            singular: prettyPrintCasedWords(info.getValue(), true)
                        }}
                    />
                </Text>
            )
        }
    },
    unreconciled_last_seven_days: {
        header(info) {
            return (
                <Box whiteSpace="pre" textAlign="right">
                    <FormattedMessage id="app.table.header.unreconciled_last_seven_days" />
                </Box>
            )
        },
        cell(info) {
            const { object } = info.row.original
            return (
                <TableCellUnreconciledStatusLink
                    objectType={object}
                    value={info.getValue()}
                    lookBehindOffset={WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES.SEVEN_DAYS}
                />
            )
        }
    },
    unreconciled_yesterday: {
        header(info) {
            return (
                <Box whiteSpace="pre" textAlign="right">
                    <FormattedMessage id="app.table.header.unreconciled_yesterday" />
                </Box>
            )
        },
        cell(info) {
            const { object } = info.row.original

            return (
                <TableCellUnreconciledStatusLink
                    objectType={object}
                    value={info.getValue()}
                    lookBehindOffset={WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES.ONE_DAY}
                />
            )
        }
    }
}
