import { Icon, IconProps } from '@chakra-ui/react'

export const RightArrowIcon: React.FC<IconProps> = (props) => (
    <Icon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
        <path
            d="M6 2L10 6L6 10"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)
