import { TableCellAccount } from '@/components'
import { BULLET_SEPARATOR, EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import {
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdConnectedAccount,
    useQueryFindByIdInternalAccount
} from '@/hooks'
import { isCorporateConnectedAccount } from '@/services'
import { truncateMiddle } from '@/utils'
import { Link, Skeleton } from '@chakra-ui/react'
import { Account, Uuid } from '@webapps/numeral-ui-core'
import { useCallback } from 'react'

type PaymentOrderDetailsFromProps = {
    connectedAccountID?: Uuid
    originatingAccount?: Account
    originatingAccountID?: Uuid
}

export const PaymentOrderDetailsFrom: React.FC<PaymentOrderDetailsFromProps> = ({
    connectedAccountID,
    originatingAccount,
    originatingAccountID
}) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()

    const queryConnectedAccount = useQueryFindByIdConnectedAccount(connectedAccountID, {
        enabled: globalThis.Boolean(connectedAccountID)
    })

    // originating_account contains connected account data for corporate payments,
    // and internal account data for interbank payments
    const isCorporatePayment = isCorporateConnectedAccount(queryConnectedAccount.data)
    const queryInternalAccount = useQueryFindByIdInternalAccount(originatingAccountID, {
        enabled: globalThis.Boolean(queryConnectedAccount.isSuccess && !isCorporatePayment && originatingAccountID)
    })

    const connectedAccountLinkLabel = `${originatingAccount?.holder_name} ${BULLET_SEPARATOR} ${queryConnectedAccount.data?.name} ${BULLET_SEPARATOR} ${truncateMiddle(queryConnectedAccount.data?.account_number)}`
    const internalAccountLinkLabel = `${queryInternalAccount.data?.holder_name} ${BULLET_SEPARATOR} ${queryInternalAccount.data?.name} ${BULLET_SEPARATOR} ${truncateMiddle(queryInternalAccount.data?.account_number)}`

    const handleConnectedAccountLinkClick = useCallback(() => {
        const path = `${paths.SETTINGS.CONNECTED_ACCOUNTS}/${connectedAccountID}/${relativePaths.SETTINGS.DETAILS}`
        navigateWithLegalEntityID(path)
    }, [paths, relativePaths, navigateWithLegalEntityID, connectedAccountID])

    const handleInternalAccountLinkClick = useCallback(() => {
        const path = `${paths.COUNTERPARTIES.INTERNAL_ACCOUNTS}/${originatingAccountID}/${relativePaths.COUNTERPARTIES.DETAILS}`
        navigateWithLegalEntityID(path)
    }, [paths, relativePaths, navigateWithLegalEntityID, originatingAccountID])

    switch (true) {
        case queryConnectedAccount.isLoading || queryInternalAccount.isLoading:
            return <Skeleton height="14px" width="50%" />
        case queryConnectedAccount.isSuccess && isCorporatePayment:
            return (
                <Link title={connectedAccountLinkLabel} onClick={handleConnectedAccountLinkClick}>
                    {connectedAccountLinkLabel}
                </Link>
            )

        case queryInternalAccount.isSuccess:
            return (
                <Link title={internalAccountLinkLabel} onClick={handleInternalAccountLinkClick}>
                    {' '}
                    {internalAccountLinkLabel}{' '}
                </Link>
            )
        case globalThis.Boolean(originatingAccount):
            return <TableCellAccount value={originatingAccount} />
        default:
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }
}
