import { z } from 'zod'
import { PaymentApprovalRuleAttributeSchema, PaymentApprovalRuleOperatorSchema } from '@webapps/numeral-ui-core'
import { PaymentApprovalRuleAttributePairConfiguration } from './PaymentApprovalRule.form.types'

/**
 * @description
 * - Used for pairs of fields
 */
export const PaymentApprovalRuleVirtualOperatorSchema = z.enum(['between', 'less_than', 'more_than'])

export const PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS: Record<string, PaymentApprovalRuleAttributePairConfiguration> = {
    AMOUNT: {
        fields: [
            PaymentApprovalRuleAttributeSchema.enum.amount_from,
            PaymentApprovalRuleAttributeSchema.enum.amount_to
        ],
        defaultOperator: PaymentApprovalRuleOperatorSchema.enum.equals,
        operators: PaymentApprovalRuleVirtualOperatorSchema.options,
        relationKey: 'extra'
    }
}
