import { SearchIcon } from '@/components/@icons'
import { TextInput } from '@/components/@inputs'
import { minLengthValidator } from '@/utils/@validators'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { TABLE_HEADER_SEARCH_MIN_THRESHOLD } from './TableHeaderSearch.const'
import { TableHeaderSearchFormProps } from './TableHeaderSearch.types'

export const TableHeaderSearch: React.FC<TableHeaderSearchFormProps> = ({ objectType }) => {
    const intl = useIntl()
    const customPlaceholderValues = useMemo(() => {
        if (!objectType) {
            return
        }

        const defaultSearchableField = intl.formatMessage({ id: 'app.table.header.search.searchable_fields.default' })

        return {
            objectType: intl
                .formatMessage({ id: `api.object.${objectType}`, defaultMessage: objectType })
                .toLowerCase(),
            searchableFields: intl
                .formatMessage({
                    id: `app.table.header.search.${objectType}.searchable_fields`,
                    defaultMessage: defaultSearchableField
                })
                .toLowerCase()
        }
    }, [objectType, intl])

    return (
        <TextInput
            name="search"
            customPlaceholderKey="app.table.header.search.placeholder"
            customPlaceholderValues={customPlaceholderValues}
            inputLeftElement={<SearchIcon color="gray.400" />}
            marginBottom={0}
            validator={minLengthValidator}
            min={TABLE_HEADER_SEARCH_MIN_THRESHOLD}
            isClearable={true}
            isLabelDisplayed={false}
            isErrorHidden={true}
        />
    )
}
