import { useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { Button, Flex, Heading } from '@chakra-ui/react'
import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { NextUnreconciledTransactionButton } from '../NextUnreconciledTransactionButton'
import { TransactionReconcileFullyReconciledContentProps } from './TransactionReconcileFullyReconciledContent types'

export const TransactionReconcileFullyReconciledContent: React.FC<TransactionReconcileFullyReconciledContentProps> = ({
    transactionID
}) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()

    const onViewReconciliationsClick = useCallback(() => {
        if (!transactionID) {
            return
        }
        const path = [paths.ACCOUNTS.TRANSACTIONS, transactionID, relativePaths.ACCOUNTS.RECONCILIATIONS].join('/')

        navigateWithLegalEntityID(path)
    }, [transactionID, paths, relativePaths, navigateWithLegalEntityID])

    return (
        <Flex flexDirection="column" textAlign="center" alignItems="center" gap="32px" marginTop="30px">
            <Heading>
                <FormattedMessage id="app.accounts.transactions.reconcile.fully_reconciled.title" />
            </Heading>
            <Button onClick={onViewReconciliationsClick}>
                <FormattedMessage id="app.accounts.transactions.reconcile.fully_reconciled.action.view_reonciliations.label" />
            </Button>
            <NextUnreconciledTransactionButton
                currentTransactionID={transactionID}
                propsButton={{ variant: 'secondary' }}
            />
        </Flex>
    )
}
