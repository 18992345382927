import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdPaymentRetryRule } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE } from '@/services'
import { PaymentRetryRule } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import {
    PAYMENT_RETRY_RULE_DETAILS_ROWS,
    PAYMENT_RETRY_RULE_DETAILS_ROW_CUSTOMIZERS
} from './PaymentRetryRuleDetails.page.const'
import { PaymentOrderDetailsCustomRows } from './PaymentRetryRuleDetails.page.type'
import { useMemo } from 'react'

export const PaymentRetryRuleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentRetryRule(uuid)
    const isDataEmpty = useMemo(() => isEmpty(query.data), [query.data])

    return (
        <PageLayout className="PaymentRetryRuleDetailsDetails">
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_RETRY_RULE}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_retry_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<PaymentRetryRule, PaymentOrderDetailsCustomRows>
                        data={query.data}
                        rows={PAYMENT_RETRY_RULE_DETAILS_ROWS}
                        rowCustomizers={PAYMENT_RETRY_RULE_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
