import React from 'react'
import { PaymentApprovalRuleAttribute, PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { ArrayHelpers } from 'formik'
import {
    factoryEmptyPaymentApprovalRuleCriterion,
    getSafeFormValuesFromFormikArrayHelper,
    isPaymentApprovalRuleAttributePair
} from '../PaymentApprovalRule.form.utils'
import { Flex, Stack } from '@chakra-ui/react'
import { If } from '@/components/@misc'
import { PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS } from '../PaymentApprovalRule.form.const'
import { PaymentApprovalRuleCriterionDefault } from './PaymentApprovalRuleCriterionDefault'
import { PaymentApprovalRuleCriterionAmount, PaymentApprovalRuleCriterionAttribute } from './'
import { FormattedMessage } from 'react-intl'

interface PaymentApprovalRuleCriterionListItemProps {
    index: number
    criterion: PaymentApprovalRuleCriteria
    availableAttributes: PaymentApprovalRuleAttribute[]
    arrayHelpers: ArrayHelpers
    isDisabled?: boolean
}

export const PaymentApprovalRuleCriterionListItem: React.FC<PaymentApprovalRuleCriterionListItemProps> = ({
    index,
    criterion,
    isDisabled,
    availableAttributes,
    arrayHelpers
}) => {
    const hasAttributeAsPartOfAPair = isPaymentApprovalRuleAttributePair(criterion?.attribute)
    const { criteria } = getSafeFormValuesFromFormikArrayHelper<PaymentApprovalRuleCriteria>(arrayHelpers)
    const isFirstElement = index === 0

    return (
        <Stack>
            <If condition={!isFirstElement}>
                <Flex marginBottom="calc(var(--numeral-ui-primary-spacing)/2)">
                    <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.functions.and.label" />
                </Flex>
            </If>
            <Flex gap="calc(var(--numeral-ui-primary-spacing)/2)" flex="1" alignItems="baseline">
                <PaymentApprovalRuleCriterionAttribute
                    index={index}
                    value={criterion.attribute}
                    options={availableAttributes}
                    isDisabled={isDisabled}
                    onChange={(value) =>
                        handlePaymentApprovalRuleAttributeChange(index, value, criterion, criteria, arrayHelpers)
                    }
                />
                {/*@description Exception*/}
                <If condition={hasAttributeAsPartOfAPair}>
                    <PaymentApprovalRuleCriterionAmount
                        criterion={criterion}
                        arrayHelpers={arrayHelpers}
                        isDisabled={isDisabled}
                        config={PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS.AMOUNT}
                        onRemove={() =>
                            handlePaymentApprovalRuleAttributeRemoval(index, criterion, criteria, arrayHelpers)
                        }
                    />
                </If>
                <If condition={!hasAttributeAsPartOfAPair}>
                    <PaymentApprovalRuleCriterionDefault
                        index={index}
                        criterion={criterion}
                        isDisabled={isDisabled}
                        onRemove={() =>
                            handlePaymentApprovalRuleAttributeRemoval(index, criterion, criteria, arrayHelpers)
                        }
                    />
                </If>
            </Flex>
        </Stack>
    )
}

export function handlePaymentApprovalRuleAttributeChange(
    index: number,
    value: PaymentApprovalRuleAttribute,
    criterion: PaymentApprovalRuleCriteria,
    criteria: PaymentApprovalRuleCriteria[],
    arrayHelpers: ArrayHelpers
) {
    const isCurrentAttributePartOfAPair = isPaymentApprovalRuleAttributePair(criterion.attribute)
    const isNewAttributeValuePartOfAPair = isPaymentApprovalRuleAttributePair(value)

    if (isCurrentAttributePartOfAPair && !isNewAttributeValuePartOfAPair) {
        handlePaymentApprovalRuleAttributePairRemoval(index, criteria, arrayHelpers)
    }

    arrayHelpers.replace(
        index,
        factoryEmptyPaymentApprovalRuleCriterion({
            attribute: value,
            values: [null]
        })
    )
}

export function handlePaymentApprovalRuleAttributeRemoval(
    index: number,
    criterion: PaymentApprovalRuleCriteria,
    criteria: PaymentApprovalRuleCriteria[],
    arrayHelpers: ArrayHelpers
) {
    const hasAttributePair = isPaymentApprovalRuleAttributePair(criterion.attribute)

    if (hasAttributePair) {
        handlePaymentApprovalRuleAttributePairRemoval(index, criteria, arrayHelpers)
    }

    arrayHelpers.remove(index)
}

export function handlePaymentApprovalRuleAttributePairRemoval(
    index: number,
    criteria: PaymentApprovalRuleCriteria[],
    arrayHelpers: ArrayHelpers
) {
    const nextIndex = index + 1
    const nextCriteria = criteria[nextIndex]

    if (nextCriteria?.attribute === PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS.AMOUNT.fields[1]) {
        arrayHelpers.remove(nextIndex)
    }
}
