import React, { useCallback } from 'react'
import { CurrencyCode, PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { ArrayHelpers } from 'formik'
import {
    PaymentApprovalRuleAttributePairConfiguration,
    PaymentApprovalRuleVirtualOperator
} from '../PaymentApprovalRule.form.types'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    getPaymentApprovalRuleConfigurationPairFields,
    getSafeFormValuesFromFormikArrayHelper
} from '../PaymentApprovalRule.form.utils'
import { usePaymentApprovalRuleAmountCriterion } from '../@hooks'
import { chain } from 'lodash'
import { PaymentApprovalRuleVirtualOperatorSchema } from '../PaymentApprovalRule.form.const'
import { Box, Button, Flex } from '@chakra-ui/react'
import { AmountInput, CurrencyInput } from '@/components/@inputs'
import { If, Select } from '@/components/@misc'
import { prettyPrintCasedWords } from '@/utils'

interface PaymentApprovalRuleCriterionAmountProps {
    criterion: PaymentApprovalRuleCriteria
    arrayHelpers: ArrayHelpers
    config: PaymentApprovalRuleAttributePairConfiguration
    onRemove: () => void
    isDisabled?: boolean
}

export const PaymentApprovalRuleCriterionAmount: React.FC<PaymentApprovalRuleCriterionAmountProps> = ({
    criterion,
    isDisabled,
    arrayHelpers,
    config,
    onRemove
}) => {
    const intl = useIntl()
    const { criteria } = getSafeFormValuesFromFormikArrayHelper<PaymentApprovalRuleCriteria>(arrayHelpers)
    const { currentIndex, nextIndex, pairedCriteria, currentVirtualOperator } = usePaymentApprovalRuleAmountCriterion(
        criterion,
        criteria,
        config
    )

    const onChangeVirtualOperator = useCallback(
        (operator: PaymentApprovalRuleVirtualOperator) => {
            const [firstAttribute, secondAttribute] = getPaymentApprovalRuleConfigurationPairFields(config)
            const criterionCurrentValues = chain(criterion).get('values').value()
            const nextIndex = currentIndex + 1

            switch (operator) {
                case PaymentApprovalRuleVirtualOperatorSchema.enum.between: {
                    arrayHelpers.replace(currentIndex, {
                        attribute: firstAttribute,
                        extra: criterion.extra,
                        operator: config.defaultOperator,
                        values: [...criterionCurrentValues]
                    })
                    if (pairedCriteria) {
                        arrayHelpers.remove(nextIndex)
                    }
                    arrayHelpers.insert(nextIndex, {
                        attribute: secondAttribute,
                        extra: criterion.extra,
                        operator: config.defaultOperator,
                        values: [...criterionCurrentValues]
                    })
                    break
                }

                /**
                 * @description
                 * Swap fields cases:
                 */
                case PaymentApprovalRuleVirtualOperatorSchema.enum.less_than:
                case PaymentApprovalRuleVirtualOperatorSchema.enum.more_than: {
                    const hasLessThanOperatorSelected =
                        operator === PaymentApprovalRuleVirtualOperatorSchema.enum.less_than

                    if (pairedCriteria) {
                        arrayHelpers.remove(nextIndex)
                    }

                    arrayHelpers.replace(currentIndex, {
                        ...criterion,
                        operator: config.defaultOperator,
                        attribute: hasLessThanOperatorSelected ? secondAttribute : firstAttribute,
                        values: [...criterionCurrentValues]
                    })
                    break
                }
            }
        },
        [arrayHelpers, currentIndex, pairedCriteria, criterion, config]
    )
    const onChangeCurrency = useCallback(
        (value: CurrencyCode) => {
            arrayHelpers.replace(currentIndex, {
                ...criterion,
                extra: value
            })

            if (pairedCriteria) {
                arrayHelpers.replace(nextIndex, {
                    ...pairedCriteria,
                    extra: value
                })
            }
        },
        [arrayHelpers, currentIndex, pairedCriteria, criterion]
    )

    const propsCommonAmount = {
        placeholder: intl.formatMessage({ id: 'app.common.form.input.amount.placeholder' }),
        currency: criterion.extra,
        isLabelDisplayed: false,
        isRequired: true,
        isDisabled
    }

    return (
        <Flex gap="calc(var(--numeral-ui-primary-spacing)/2)" flex="1" alignItems="baseline">
            <Box>
                <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.functions.is.label" />
            </Box>
            <Box minWidth="120px">
                <Select<PaymentApprovalRuleVirtualOperator>
                    value={currentVirtualOperator}
                    onChange={onChangeVirtualOperator}
                    options={config.operators}
                    isDisabled={isDisabled}
                    isClearable={false}
                    isSearchable={false}
                    getOptionLabel={prettyPrintCasedWords}
                />
            </Box>
            <Box minWidth="110px">
                <CurrencyInput
                    name={`criteria.${currentIndex}.extra`}
                    value={criterion.extra}
                    customPlaceholderKey="app.common.form.input.select.placeholder.alternative"
                    onChange={onChangeCurrency}
                    isLabelDisplayed={false}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
            </Box>
            <Box minWidth="70px" flex="1 1">
                <AmountInput name={`criteria.${currentIndex}.values[0]`} {...propsCommonAmount} />
            </Box>
            <If condition={!!pairedCriteria}>
                <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.functions.and.label" />
                <Box minWidth="70px" flex="1 1">
                    <AmountInput name={`criteria.${nextIndex}.values[0]`} {...propsCommonAmount} />
                </Box>
            </If>
            <Button variant="ghost" color="red.500" fontWeight="normal" onClick={onRemove}>
                <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.remove_criterion.label" />
            </Button>
        </Flex>
    )
}
