import { SplashScreen } from '@/components'
import { If } from '@/components/@misc'
import {
    useConfiguration,
    useNavigationRoutes,
    usePrevious,
    useQueryFindAllUserAccesses,
    useQueryParams,
    useServices
} from '@/hooks'
import { ENTITY_FEATURE, PERMISSION, STORAGE_KEYS, StorageService, UserAccess } from '@/services'
import { timeoutCallbackRunner } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { head, isEmpty, isEqualWith, isNil, noop } from 'lodash'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
    addOrReplaceLegalEntityIDInPathname,
    getLegalEntityIDFromPathname,
    removeObjectUUIDAndRelativeActionInPathname
} from '../NavigationRoutesProvider'
import { INACTIVITY_LOGOUT_TIME, SPLASH_SCREEN_CLOSE_DELAY_IN_MS } from './AuthProvider.const'
import { AuthContext } from './AuthProvider.context'
import { AUTH_ERROR_TYPES } from './AuthProvider.types'
import { getUserAccessByLegalEntityID, isFindAllUserAccessesQueryEnabled } from './AuthProvider.utils'

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { apiEnvironment } = useConfiguration()
    const { authenticationService } = useServices()
    const { getQueryParam } = useQueryParams()
    const { paths } = useNavigationRoutes()
    const queryClient = useQueryClient()
    const location = useLocation()
    const navigate = useNavigate()
    const [selectedUserAccess, setSelectedUserAccess] = useState<UserAccess | undefined>(undefined)
    const [isUserLoggingOut, setIsUserLoggingOut] = useState<boolean>(false)
    const prevUserAccess = usePrevious<UserAccess>(selectedUserAccess)
    const [activatedFeatures, setActivatedFeatures] = useState<Set<ENTITY_FEATURE> | undefined>(undefined)
    const [userPermissions, setUserPermissions] = useState<Set<PERMISSION> | undefined>(undefined)
    const [isSplashScreenDisplayed, setIsSplashScreenDisplayed] = useState<boolean>(true)

    const isUserLoggedIn = useMemo<boolean>(() => {
        return !!selectedUserAccess && !isUserLoggingOut
    }, [selectedUserAccess, isUserLoggingOut])

    const isQueryEnabled = useCallback(
        () => isFindAllUserAccessesQueryEnabled(selectedUserAccess, isUserLoggingOut, location.pathname),
        [selectedUserAccess, location.pathname]
    )

    const queryUserAccesses = useQueryFindAllUserAccesses({
        enabled: isQueryEnabled()
    })

    const getInitialUserAccess = useCallback(
        (userAccesses?: UserAccess[]) => {
            if (!userAccesses) {
                return
            }

            const localStoragePersistedLegalEntityID = StorageService.getItem(
                STORAGE_KEYS.SELECTED_LEGAL_ENTITY_ID
            ) as Uuid
            const defaultUserAccess = head(userAccesses)

            return getUserAccessByLegalEntityID(userAccesses, localStoragePersistedLegalEntityID) ?? defaultUserAccess
        },
        [getQueryParam, StorageService, location]
    )

    const onSetSelectedUserAccess = (userAccess?: UserAccess) => {
        if (isNil(userAccess) || isEqualWith(userAccess, prevUserAccess)) {
            return
        }

        setSelectedUserAccess(userAccess)
        setActivatedFeatures(new Set(userAccess.features))
        setUserPermissions(new Set(userAccess.permissions))
        StorageService.setItem(STORAGE_KEYS.SELECTED_LEGAL_ENTITY_ID, userAccess.legal_entity_id)

        if (userAccess.environment !== prevUserAccess?.environment) {
            apiEnvironment.setActiveEnvironmentByName(userAccess.environment)
        }

        // Invalidating all queries, this will as well rebuild all services with the new base URL
        queryClient.cancelQueries()
        queryClient.invalidateQueries()

        const updatedPathname = addOrReplaceLegalEntityIDInPathname(location.pathname, userAccess.legal_entity_id)

        if (prevUserAccess) {
            const updatedPathnameStrippedFromRelativeObjectID =
                removeObjectUUIDAndRelativeActionInPathname(updatedPathname)
            navigate(updatedPathnameStrippedFromRelativeObjectID)
            globalThis.window.location.reload()
        } else {
            navigate(updatedPathname, { replace: true })
        }
    }

    const onLogin = useCallback(() => {
        authenticationService.login(apiEnvironment.primary?.url)
    }, [authenticationService, apiEnvironment])

    const onLogout = useCallback(() => {
        // Update the state and letting the use effect triggering the logout process
        setIsUserLoggingOut(true)
    }, [setIsUserLoggingOut])

    /**
     * @todo
     * Too many side effects (four `useEffects`) in one provider, we need to isolate each one in dedicated hooks with tests ideally.
     */
    useEffect(() => {
        let timeout: number | undefined

        if (!queryUserAccesses.isLoading) {
            globalThis.setTimeout(() => {
                setIsSplashScreenDisplayed(false)
            }, SPLASH_SCREEN_CLOSE_DELAY_IN_MS)
        }

        return () => {
            globalThis.clearTimeout(timeout)
        }
    }, [queryUserAccesses.isLoading])

    useEffect(() => {
        if (isUserLoggingOut) {
            queryClient.cancelQueries().catch(noop)
            // Logout from the default environment first and let BFF redirect us to <ExtraLogout/> if needed
            authenticationService.logout(apiEnvironment.primary?.url)
        }
    }, [isUserLoggingOut])

    /**
     * Inactive users automatically get logged out after INACTIVITY_LOGOUT_TIME ms
     */
    useEffect(() => {
        const cancelTimeoutCallbackRunner = timeoutCallbackRunner(onLogout, INACTIVITY_LOGOUT_TIME)

        return () => {
            cancelTimeoutCallbackRunner?.()
        }
    }, [onLogout])

    useEffect(() => {
        if (!queryUserAccesses.isFetched || queryUserAccesses.isLoading || isUserLoggingOut) {
            return
        }

        const legalEntityIDFromPathname = getLegalEntityIDFromPathname(location.pathname)
        if (selectedUserAccess && selectedUserAccess.legal_entity_id === legalEntityIDFromPathname) {
            return
        }

        if (queryUserAccesses.isError) {
            navigate(`${paths.ACCOUNT.ERROR}`)
            return
        }

        if (queryUserAccesses.isSuccess && isEmpty(queryUserAccesses.data)) {
            navigate(`${paths.ACCOUNT.ERROR}/?error_type=${AUTH_ERROR_TYPES.NO_ACCESSES}`)
            return
        }

        let updatedUserAccess
        if (legalEntityIDFromPathname) {
            updatedUserAccess = getUserAccessByLegalEntityID(queryUserAccesses.data, legalEntityIDFromPathname)
            if (!updatedUserAccess) {
                // If no user access matches the legal ID in the URL, we redirect to a missing access page
                const missingAccessPath = addOrReplaceLegalEntityIDInPathname(
                    paths.MISSING_ACCESS,
                    legalEntityIDFromPathname
                )
                if (!location.pathname.includes(paths.MISSING_ACCESS)) {
                    navigate(missingAccessPath)
                }
                return
            }
        } else {
            updatedUserAccess = getInitialUserAccess(queryUserAccesses.data)
        }
        onSetSelectedUserAccess(updatedUserAccess)
    }, [location, queryUserAccesses])

    return (
        <AuthContext.Provider
            value={{
                isUserLoggedIn,
                onLogin,
                isUserLoggingOut,
                onLogout,
                userAccesses: queryUserAccesses.data,
                selectedUserAccess,
                selectUserAccess: onSetSelectedUserAccess,
                activatedFeatures,
                userPermissions
            }}>
            <If condition={isSplashScreenDisplayed}>
                <SplashScreen duration={SPLASH_SCREEN_CLOSE_DELAY_IN_MS} />
            </If>
            {children}
        </AuthContext.Provider>
    )
}
