import { FormattedMessage, IntlShape } from 'react-intl'
import {
    PaymentApprovalRule,
    PaymentApprovalRuleAttributeSchema,
    PaymentApprovalRuleCriteria
} from '@webapps/numeral-ui-core'
import React, { ReactNode } from 'react'
import { chain, isEmpty, isString } from 'lodash'
import { isLastIndex } from '@/utils'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { FormattedAmount, Separator, SeparatorVariant } from '@/components/@misc'
import {
    Box,
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Stack,
    Tag
} from '@chakra-ui/react'

export function factoryAmountsPaymentApprovalRuleCriteriaWithIntl(intl?: IntlShape) {
    return function factoryAmountsPaymentApprovalRuleCriteria(
        paymentApprovalRule?: PaymentApprovalRule,
        groupBy: string = 'extra'
    ) {
        const amountFromCriterion = findAllPaymentApprovalRulesByAttribute(paymentApprovalRule, 'amount_from')
        const amountToCriterion = findAllPaymentApprovalRulesByAttribute(paymentApprovalRule, 'amount_to')
        const criterion = [...amountFromCriterion, ...amountToCriterion]
        const parts: ReactNode[] = []

        if (isEmpty(criterion)) {
            parts.push(EMPTY_VALUE_PLACEHOLDER)
        } else {
            const criterionGroupedByCurrencyWrapper = chain(criterion).groupBy(groupBy)

            criterionGroupedByCurrencyWrapper
                .keys()
                .forEach((currency, index, array) => {
                    const hasLastIndex = isLastIndex(index, array)
                    const currencySpecificCriterionWrapper = criterionGroupedByCurrencyWrapper.get(currency)

                    currencySpecificCriterionWrapper
                        .forEach((criterion) => {
                            criterion.values.forEach((value) => {
                                const hasAmountTo =
                                    criterion.attribute === PaymentApprovalRuleAttributeSchema.enum.amount_to
                                const isSingle = currencySpecificCriterionWrapper.size().eq(1).value()

                                if (hasAmountTo) {
                                    parts.push(SPACE_SYMBOL)
                                }

                                const formattedAttribute = intl?.formatMessage(
                                    {
                                        id: isSingle
                                            ? `api.payment_approval_rule.attributes.${criterion.attribute}.alternative`
                                            : `api.payment_approval_rule.attributes.${criterion.attribute}`,
                                        defaultMessage: criterion.attribute
                                    },
                                    {
                                        value: <FormattedAmount currency={currency} amount={value} />
                                    }
                                ) as ReactNode[]

                                const word = hasAmountTo
                                    ? chain(formattedAttribute)
                                          .map((value) => {
                                              const hasToBeLowerCased = !isSingle && isString(value)
                                              return hasToBeLowerCased ? value.toLowerCase() : value
                                          })
                                          .value()
                                    : formattedAttribute

                                parts.push(word)
                            })
                        })
                        .value()

                    if (!hasLastIndex) {
                        parts.push(<Separator variant={SeparatorVariant.Bullet} />)
                    }
                })
                .value()
        }

        return parts
    }
}

export function factoryDefaultPaymentApprovalRuleCriteria(
    paymentApprovalRule?: PaymentApprovalRule,
    attribute?: PaymentApprovalRuleCriteria['attribute'],
    groupBy: string = 'operator',
    factoryComponent?: (value: any) => ReactNode
): ReactNode {
    const criterion = findAllPaymentApprovalRulesByAttribute(paymentApprovalRule, attribute)
    const parts = []

    if (isEmpty(criterion)) {
        parts.push(EMPTY_VALUE_PLACEHOLDER)
    } else {
        const criterionGroupedByWrapper = chain(criterion).groupBy(groupBy)

        criterionGroupedByWrapper
            .keys()
            .forEach((operator, index, array) => {
                const hasLastIndex = isLastIndex(index, array)
                const innerCriterionWrapper = criterionGroupedByWrapper.get(operator)
                const firstCriterionWrapper = innerCriterionWrapper.head()
                const firstCriterionValuesWrapper = firstCriterionWrapper.get('values')

                const firstValueWrapper = firstCriterionValuesWrapper.head()

                const hasMoreCriteriaOfTheSameType = firstCriterionWrapper.get('values').size().gt(1).value()

                const formattedOperator = <FormattedMessage id={`api.payment_approval_rule.operators.${operator}`} />

                parts.push(formattedOperator)
                parts.push(SPACE_SYMBOL)
                parts.push(
                    <Flex noOfLines={1} flexShrink="0">
                        {factoryComponent?.(firstValueWrapper.value())}
                    </Flex>
                )

                if (hasMoreCriteriaOfTheSameType) {
                    const numberOfValuesLeftInCriteria = firstCriterionValuesWrapper.tail().size().value()
                    const tooltipContent = (
                        <Stack padding="calc(--numeral-ui-primary-spacing/2)">
                            {firstCriterionValuesWrapper
                                .tail()
                                .map((value, index) => <Box key={index}>{factoryComponent?.(value)}</Box>)
                                .value()}
                        </Stack>
                    )

                    parts.push(SPACE_SYMBOL)
                    parts.push(
                        <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.functions.and.label" />
                    )
                    parts.push(SPACE_SYMBOL)

                    parts.push(
                        <Popover
                            returnFocusOnClose={true}
                            autoFocus={false}
                            closeOnBlur={true}
                            closeOnEsc={true}
                            isLazy={true}
                            placement="top"
                            trigger="hover">
                            <PopoverTrigger>
                                <Tag
                                    variant="subtle"
                                    flexShrink="0"
                                    backgroundColor="gray.100"
                                    cursor="pointer"
                                    size="sm">
                                    <FormattedMessage
                                        id="app.common.pluralization.label"
                                        values={{
                                            count: numberOfValuesLeftInCriteria,
                                            singular: `${numberOfValuesLeftInCriteria} other`
                                        }}
                                    />
                                </Tag>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent maxWidth="400px">
                                    <PopoverArrow />
                                    <PopoverHeader>
                                        {formattedOperator}
                                        {SPACE_SYMBOL}
                                        <FormattedMessage
                                            id="app.common.pluralization.label"
                                            values={{
                                                count: numberOfValuesLeftInCriteria,
                                                singular: `${numberOfValuesLeftInCriteria} other`
                                            }}
                                        />
                                    </PopoverHeader>
                                    <PopoverBody>{tooltipContent}</PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    )
                }

                if (!hasLastIndex) {
                    parts.push(<Separator variant={SeparatorVariant.Bullet} />)
                }
            })
            .value()
    }

    return (
        <Flex alignItems="center" gap="4px">
            {parts}
        </Flex>
    )
}

export function findAllPaymentApprovalRulesByAttribute(
    paymentApprovalRule?: PaymentApprovalRule,
    attribute?: PaymentApprovalRuleCriteria['attribute']
): PaymentApprovalRuleCriteria[] {
    return chain(paymentApprovalRule?.criteria).filter({ attribute }).value() as PaymentApprovalRuleCriteria[]
}
