import { Button, Flex, Stack } from '@chakra-ui/react'
import { isLastIndex } from '@/utils'
import { FormikInput } from '@/types'
import {
    ConnectedAccountsInput,
    ExternalAccountsInput,
    TextInput,
    UsersInput,
    UserRolesInput
} from '@/components/@inputs'
import { If } from '@/components/@misc'
import { FormattedMessage } from 'react-intl'
import { FieldArray } from 'formik'
import { PaymentApprovalRuleAttributeSchema, PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { isNull } from 'lodash'

interface PaymentApprovalRuleCriterionListItemValuesProps extends FormikInput {
    criterion: PaymentApprovalRuleCriteria
    onRemoveParent(): void
}

export const PaymentApprovalRuleCriterionListItemValues: React.FC<PaymentApprovalRuleCriterionListItemValuesProps> = ({
    name,
    criterion,
    isDisabled,
    onRemoveParent
}) => {
    return (
        <FieldArray
            name={name}
            render={(criteriaValuesArrayHelpers) => {
                return (
                    <Stack gap="0" flex="1 1 auto">
                        {criterion?.values?.map((value, index, array) => {
                            const isFirstElement = index === 0
                            const hasValueLastIndex = isLastIndex(index, array)
                            const fieldName = `${name}.${index}`
                            const propsCommon: FormikInput = {
                                name: fieldName,
                                value,
                                isLabelDisplayed: false,
                                isRequired: true,
                                isDisabled,
                                /* @ts-expect-error merge SelectProps with FormikInput props*/
                                isOptionDisabled(option: typeof value) {
                                    return criterion?.values.includes(option?.value)
                                }
                            }

                            const onRemoveChildElement = () => {
                                criteriaValuesArrayHelpers.remove(index)
                            }

                            const hasNullInLastValue = isNull(array[array.length - 1])

                            return (
                                <Flex
                                    gap="calc(var(--numeral-ui-primary-spacing)/2)"
                                    alignContent="baseline"
                                    key={index}>
                                    <If condition={!isFirstElement}>
                                        <Flex marginY="6px" marginLeft="-22px">
                                            <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.functions.or.label" />
                                        </Flex>
                                    </If>
                                    <Stack gap="0" flex="1">
                                        {(() => {
                                            const {
                                                amount_from,
                                                amount_to,
                                                connected_account_id,
                                                external_account_id,
                                                created_by_user_id,
                                                created_by_role_id
                                            } = PaymentApprovalRuleAttributeSchema.enum

                                            switch (criterion?.attribute) {
                                                case amount_from:
                                                case amount_to: {
                                                    /**
                                                     * @description
                                                     * - Exception to the general rule: `amount_from` and `amount_to` are handled
                                                     * in a different component and should not be rendered here.
                                                     * - Check PaymentApprovalRuleCriterionAmount component for more details.
                                                     */
                                                    return null
                                                }
                                                case connected_account_id: {
                                                    return <ConnectedAccountsInput {...propsCommon} />
                                                }
                                                case created_by_role_id: {
                                                    return <UserRolesInput {...propsCommon} />
                                                }
                                                case created_by_user_id: {
                                                    return <UsersInput {...propsCommon} />
                                                }
                                                case external_account_id: {
                                                    return <ExternalAccountsInput {...propsCommon} />
                                                }

                                                default: {
                                                    return <TextInput {...propsCommon} />
                                                }
                                            }
                                        })()}
                                        <If condition={hasValueLastIndex}>
                                            <Button
                                                size="sm"
                                                maxWidth="200px"
                                                leftIcon={<>+</>}
                                                alignSelf="flex-end"
                                                onClick={() => criteriaValuesArrayHelpers.push(null)}
                                                isDisabled={isDisabled || hasNullInLastValue}>
                                                <FormattedMessage
                                                    id={`app.settings.payment_approval_rules.create.form.inputs.${criterion?.attribute}.label`}
                                                />
                                            </Button>
                                        </If>
                                    </Stack>
                                    <Button
                                        variant="ghost"
                                        color="red.500"
                                        fontWeight="normal"
                                        onClick={() => {
                                            if (isFirstElement) {
                                                onRemoveParent()
                                            } else {
                                                onRemoveChildElement()
                                            }
                                        }}
                                        isDisabled={isDisabled}>
                                        <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.remove_criterion.label" />
                                    </Button>
                                </Flex>
                            )
                        })}
                    </Stack>
                )
            }}
        />
    )
}
