import { Icon, IconProps } from '@chakra-ui/react'

export const SkipToNext: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 -5 12 20" fill="none" {...props}>
        <path
            d="M9.74992 10V0H11.4166V10H9.74992ZM0.583252 10V0L8.08325 5L0.583252 10ZM2.24992 6.875L5.08325 5L2.24992 3.125V6.875Z"
            fill="white"
        />
    </Icon>
)
