import { PaymentApprovalRuleApproverType, PaymentApprovalRuleApproverTypeSchema, Uuid } from '@webapps/numeral-ui-core'
import { RoleName, RoleNameProps, UserName, UserNameProps } from '@/components/@misc'
import { Nullable } from '@/types'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

interface PaymentApprovalRuleApproverProps extends UserNameProps, RoleNameProps {
    approverId?: Nullable<Uuid>
    approverType?: PaymentApprovalRuleApproverType
}

export const PaymentApprovalRuleApprover: React.FC<PaymentApprovalRuleApproverProps> = ({
    approverId,
    approverType,
    ...props
}) => {
    const { user, role } = PaymentApprovalRuleApproverTypeSchema.enum

    switch (approverType) {
        case user: {
            return <UserName {...props} id={approverId} />
        }
        case role: {
            return <RoleName {...props} id={approverId} />
        }

        default: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }
    }
}
