import { Icon, IconProps } from '@chakra-ui/react'

export const CrossIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
        <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 6L14 14M14 6L6 14"
        />
    </Icon>
)
