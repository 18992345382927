import {
    PaymentApprovalRule,
    PaymentApprovalRuleApproverTypeSchema,
    PaymentApprovalRuleAttribute,
    PaymentApprovalRuleAttributeSchema,
    PaymentApprovalRuleCriteria
} from '@webapps/numeral-ui-core'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PaymentApprovalRuleAttributePairConfiguration } from './PaymentApprovalRule.form.types'
import { ArrayHelpers, FieldValidator, FormikValues } from 'formik'
import { chain } from 'lodash'
import { PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS } from './PaymentApprovalRule.form.const'
import { IntlShape } from 'react-intl'
import { FormikInputConfiguration } from '@/types'
import { anythingValidator } from '@/utils/@validators'

export function getSafeFormValuesFromFormikArrayHelper<T>(arrayHelpers: ArrayHelpers<T[]>) {
    return chain(arrayHelpers).get('form.values', Object.create(null)).value()
}

export function getPaymentApprovalRuleFormInitialValues(data?: Partial<PaymentApprovalRule>) {
    const step = {
        approvers: {
            number: 1,
            type: null,
            values: []
        },
        self_approval_allowed: true
    }

    return {
        name: EMPTY_CHAR_SYMBOL,
        scope: ApiObjectTypeExtendedSchema.enum.payment_order,
        criteria: [],
        steps: [[step]],
        ...data
    } as PaymentApprovalRule
}

export function getPaymentApprovalRuleConfigurationPairFields(config?: PaymentApprovalRuleAttributePairConfiguration) {
    return [config?.fields?.[0], config?.fields?.[1]]
}

export function isPaymentApprovalRuleAttributePair(paymentApprovalRuleAttribute: PaymentApprovalRuleAttribute) {
    return Object.keys(PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS).some((key) => {
        const fieldValue = PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS[key]
        return fieldValue.fields.includes(paymentApprovalRuleAttribute)
    })
}

export function isPaymentApprovalRuleAttributeAmountToFilter(value: PaymentApprovalRuleAttribute) {
    return value === PaymentApprovalRuleAttributeSchema.enum.amount_to
}

export function isPaymentApprovalRuleAttributeUsedFilter(
    attribute: string,
    criteria?: PaymentApprovalRuleCriteria[]
): boolean {
    return criteria?.some((c) => c.attribute === attribute) ?? false
}

/**
 * Check if a pair of payment approval rule attributes are used in the criteria
 * 1. Check if the attribute is part of this pair configuration
 * 2. Check if either attribute from the pair exists in criteria
 */
export function isPaymentApprovalRulePairUsedFilter(
    attribute: PaymentApprovalRuleAttribute,
    criteria?: PaymentApprovalRuleCriteria[],
    config?: PaymentApprovalRuleAttributePairConfiguration
): boolean {
    if (!criteria?.length) {
        return false
    }

    if (!config?.fields.includes(attribute)) {
        return false
    }

    const [firstAttribute, secondAttribute] = getPaymentApprovalRuleConfigurationPairFields(config)

    if (firstAttribute && secondAttribute) {
        const attributesInCriteria = criteria.map((criterion: PaymentApprovalRuleCriteria) => criterion.attribute)
        return attributesInCriteria.includes(firstAttribute) || attributesInCriteria.includes(secondAttribute)
    }

    return false
}

export function factoryEmptyPaymentApprovalRuleCriterion(
    criterion?: Partial<PaymentApprovalRuleCriteria>
): Partial<PaymentApprovalRuleCriteria> {
    return {
        attribute: undefined,
        extra: null,
        operator: undefined,
        values: [],
        ...criterion
    }
}

export function paymentApprovalRuleStepsUserApproversValidator(
    intl: IntlShape,
    configuration?: FormikInputConfiguration,
    values?: FormikValues
): FieldValidator {
    switch (values?.approverType) {
        case PaymentApprovalRuleApproverTypeSchema.enum.user: {
            return (value: string): string | void => {
                if (value?.length < values?.numberOfApprovals) {
                    return intl.formatMessage({
                        id: `app.settings.payment_approval_rules.create.form.inputs.steps.approvers.type.user.validation.invalid`
                    })
                }
            }
        }
        default: {
            return anythingValidator(intl, configuration, values)
        }
    }
}
