import { DOT_SYMBOL } from '@/constants'
import { DEFAULT_NUMBER_OF_CHARS } from './truncateMiddle.utils.const'

/**
 * Truncate the middle of the value to only keep the begining and the end.
 * Truncated content is replace by ..
 */
export function truncateMiddle(
    value?: string,
    numberOfLeadingChars: number = DEFAULT_NUMBER_OF_CHARS,
    numberOfTrailingChars: number = DEFAULT_NUMBER_OF_CHARS
): string | undefined {
    if (!value) {
        return
    }

    const sanitizedNumberOfLeadingChars = globalThis.Math.floor(globalThis.Math.max(0, numberOfLeadingChars))
    const sanitizedNumberOfTrailingChars = globalThis.Math.floor(globalThis.Math.max(0, numberOfTrailingChars))

    if (value.length <= sanitizedNumberOfLeadingChars + sanitizedNumberOfTrailingChars) {
        return value
    }

    const firstChars = value.slice(0, sanitizedNumberOfLeadingChars)
    const lastChars = sanitizedNumberOfTrailingChars === 0 ? '' : value.slice(-numberOfTrailingChars)

    return `${firstChars}${DOT_SYMBOL}${DOT_SYMBOL}${lastChars}`
}
