import { Checkbox, FormControl, FormErrorMessage, FormLabel, Skeleton, Stack } from '@chakra-ui/react'
import { PaymentApprovalRuleApproverTypeSchema, PaymentApprovalRuleStep, Uuid } from '@webapps/numeral-ui-core'
import { useQueryFindAllRoles, useQueryFindAllUsers } from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { FieldArray, useField } from 'formik'
import { Role, User } from '@/services'
import { FormikInput } from '@/types'
import { If, PaymentApprovalRuleApprover } from '@/components/@misc'
import { ChangeEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { chain } from 'lodash'
import { paymentApprovalRuleStepsUserApproversValidator } from '../../PaymentApprovalRule.form.utils'

interface PaymentApprovalRuleStepsApproversValuesProps extends Omit<FormikInput, 'value'> {
    value?: Uuid[]
    paymentApprovalRuleStep: PaymentApprovalRuleStep
}

export const PaymentApprovalRuleStepsApproversValuesInput: React.FC<PaymentApprovalRuleStepsApproversValuesProps> = ({
    name,
    value,
    paymentApprovalRuleStep,
    isLabelDisplayed,
    isErrorHidden,
    isRequired,
    isDisabled,
    validator = paymentApprovalRuleStepsUserApproversValidator
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator(
            intl,
            { name, isRequired },
            {
                approverType: paymentApprovalRuleStep?.approvers?.type,
                numberOfApprovals: paymentApprovalRuleStep?.approvers?.number
            }
        )
    })

    const queryRoles = useQueryFindAllRoles(undefined, {
        enabled: paymentApprovalRuleStep?.approvers?.type === PaymentApprovalRuleApproverTypeSchema.enum.role
    })
    const queryUsers = useQueryFindAllUsers(undefined, {
        enabled: paymentApprovalRuleStep?.approvers?.type === PaymentApprovalRuleApproverTypeSchema.enum.user
    })

    const data = useMemo<(User | Role)[]>(() => {
        switch (paymentApprovalRuleStep?.approvers?.type) {
            case PaymentApprovalRuleApproverTypeSchema.enum.role: {
                return queryDataAggregation(queryRoles.data)
            }

            case PaymentApprovalRuleApproverTypeSchema.enum.user: {
                return queryDataAggregation(queryUsers.data)
            }

            default: {
                return []
            }
        }
    }, [paymentApprovalRuleStep?.approvers?.type, queryRoles, queryUsers])

    const isLoading = useMemo(() => {
        return queryUsers.isLoading || queryRoles.isLoading
    }, [queryUsers.isLoading, queryRoles.isLoading])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.steps.approvers.label" />
                </FormLabel>
            </If>
            <Skeleton isLoaded={!isLoading} minHeight="var(--numeral-ui-primary-spacing)">
                <FieldArray
                    name={name}
                    render={({ push, remove }) => {
                        return (
                            <Stack
                                gap="var(--numeral-ui-primary-spacing)"
                                marginBottom="var(--numeral-ui-primary-spacing)">
                                {chain(data)
                                    .map((item, index) => {
                                        const itemValue = item?.id as string
                                        const itemFieldName = `${name}.${index}`
                                        const valueIndex = chain(value).indexOf(itemValue).defaultTo(-1).value()
                                        const isChecked = valueIndex !== -1
                                        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                                            const newIsChecked = event.target.checked
                                            event.stopPropagation()

                                            if (newIsChecked && !isChecked) {
                                                push(item.id)
                                            } else if (!newIsChecked && isChecked) {
                                                remove(valueIndex)
                                            }

                                            helpers.setTouched(true)
                                        }

                                        return (
                                            <Checkbox
                                                {...field}
                                                id={itemFieldName}
                                                name={itemFieldName}
                                                key={index}
                                                value={itemValue}
                                                isChecked={isChecked}
                                                isRequired={isRequired}
                                                isInvalid={isInvalid}
                                                isDisabled={isDisabled}
                                                onChange={onChange}>
                                                <PaymentApprovalRuleApprover
                                                    approverId={item.id}
                                                    approverType={paymentApprovalRuleStep?.approvers?.type}
                                                    asPlainText={true}
                                                />
                                            </Checkbox>
                                        )
                                    })
                                    .value()}
                            </Stack>
                        )
                    }}
                />
            </Skeleton>
            <If condition={!isErrorHidden}>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
            </If>
        </FormControl>
    )
}
