import { getFormButtonLoadingLeftIcon, StickyFooter } from '@/components'
import {
    useAuth,
    useMutationApproveByIdPaymentOrder,
    useMutationCancelByIdPaymentOrder,
    useQueryFindCurrentUserInfo,
    useToasts
} from '@/hooks'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { canApproveOrCancelPaymentOrderByStatus } from './PaymentOrderApproval.utils'
import { APIValidationErrorNotificationBody } from '@/components/@misc'
import { TOAST_VARIANTS } from '@/providers'

interface PaymentOrderApprovalProps {
    data?: PaymentOrder
}

export function PaymentOrderApproval({ data }: PaymentOrderApprovalProps) {
    const { onAdd } = useToasts()
    const { selectedUserAccess } = useAuth()
    const mutationApprove = useMutationApproveByIdPaymentOrder(data?.id, {
        onSuccess() {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.payments.payment_orders.approval.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.payments.payment_orders.approval.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.payments.payment_orders.approval.validation.error.toast.title" />,
                description: (
                    <APIValidationErrorNotificationBody<PaymentOrder>
                        messageId="app.payments.payment_orders.approval.validation.error.toast.description"
                        error={error}
                    />
                )
            })
        }
    })
    const mutationCancel = useMutationCancelByIdPaymentOrder(data?.id)
    const isVisible = useMemo<boolean>(() => canApproveOrCancelPaymentOrderByStatus(data?.status), [data])
    const onApprove = useCallback(() => {
        mutationApprove.mutate()
    }, [mutationApprove])
    const onCancel = useCallback(() => {
        mutationCancel.mutate()
    }, [mutationCancel])
    const isLoading = useMemo<boolean>(() => {
        return mutationApprove.isPending || mutationCancel.isPending
    }, [mutationApprove, mutationCancel])
    const canCurrentUserApprovePaymentOrder = useMemo<boolean>(() => {
        return !data?.approvals?.some((approval) => approval.id === selectedUserAccess?.id)
    }, [data, selectedUserAccess])

    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="PaymentOrderApproval" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                <FormattedMessage id="app.payments.payment_orders.approval.title" />
                            </Text>
                            <Text>
                                <FormattedMessage id="app.payments.payment_orders.approval.description" />
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button
                                colorScheme="gray"
                                onClick={onCancel}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationCancel.isPending)}
                                isDisabled={isLoading}
                                variant="outline"
                                backgroundColor="white">
                                <FormattedMessage id="app.payments.payment_orders.approval.actions.cancel.label" />
                            </Button>
                            <Button
                                onClick={onApprove}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationApprove.isPending)}
                                isDisabled={isLoading || !canCurrentUserApprovePaymentOrder}
                                variant="solid">
                                <FormattedMessage id="app.payments.payment_orders.approval.actions.approve.label" />
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
