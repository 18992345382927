import { isDefaultRole, Role } from '@/services'
import { memo, useMemo } from 'react'
import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { FormattedMessage } from 'react-intl'

interface UserRoleWithCustomTagProps {
    role?: Role
}

export const UserRoleWithCustomRoleTag: React.FC<UserRoleWithCustomTagProps> = ({ role }) => {
    const hasDefaultRole = useMemo(() => isDefaultRole(role), [role])

    switch (true) {
        case !role?.name: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case hasDefaultRole: {
            return <>{role?.name}</>
        }

        default: {
            return (
                <Flex gap="calc(var(--numeral-ui-primary-spacing)/2)" lineHeight="1.5">
                    <Text whiteSpace="pre">{role?.name}</Text>
                    <Tag variant="subtle" backgroundColor="gray.100">
                        <FormattedMessage id="app.settings.roles.form.type.custom.label" defaultMessage="Custom" />
                    </Tag>
                </Flex>
            )
        }
    }
}
