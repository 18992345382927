import {
    useMutationActivatePaymentApprovalRule,
    useMutationDisablePaymentApprovalRule,
    useMutationPausePaymentApprovalRule,
    usePermissions,
    useQueryFindByIdPaymentApprovalRule
} from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { TableHeaderActionProps } from '@/components'
import { PERMISSION } from '@/services'
import { PaymentApprovalRuleStatusSchema } from '@webapps/numeral-ui-core'

export const PaymentApprovalRuleDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdPaymentApprovalRule(uuid)
    const mutationPause = useMutationPausePaymentApprovalRule(uuid)
    const mutationActivate = useMutationActivatePaymentApprovalRule(uuid)
    const mutationDisable = useMutationDisablePaymentApprovalRule(uuid)

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        const title = query.data?.name

        return {
            title: query.isError ? notFoundTitle : title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [intl, query])

    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []
        const hasPausedStatus = query.data?.status === PaymentApprovalRuleStatusSchema.enum.paused
        const hasActiveStatus = query.data?.status === PaymentApprovalRuleStatusSchema.enum.active
        const hasDisabledStatus = query.data?.status === PaymentApprovalRuleStatusSchema.enum.disabled

        if (!hasPermission(PERMISSION.SETTINGS_EDIT_PAYMENT_APPROVAL_RULES)) {
            return
        }

        if (hasActiveStatus) {
            actions.push({
                onClick() {
                    mutationPause.mutate()
                },
                isDisabled: mutationPause.isPending,
                children: intl.formatMessage({
                    id: 'app.settings.payment_approval_rules.tabs.details.actions.pause.label',
                    defaultMessage: 'Pause'
                })
            })
        }

        if (hasPausedStatus) {
            actions.push({
                onClick() {
                    mutationActivate.mutate()
                },
                isDisabled: false,
                children: intl.formatMessage({
                    id: 'app.settings.payment_approval_rules.tabs.details.actions.activate.label',
                    defaultMessage: 'Activate'
                })
            })
        }

        if (hasPausedStatus && !hasDisabledStatus) {
            actions.push({
                onClick() {
                    mutationDisable.mutate()
                },
                isDisabled: mutationDisable.isPending,
                color: 'red.500',
                children: intl.formatMessage({
                    id: 'app.common.actions.disable.label'
                })
            })
        }

        return actions
    }, [query, mutationPause, mutationActivate, mutationDisable, hasPermission, intl])

    return <PageHeaderLayout {...propsPageHeaderLayout} actions={actions} />
}
