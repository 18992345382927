import { GroupByTable, QueryFallback, TableCellShortDate, useTableRowNavigation } from '@/components'
import { useNavigationRoutes } from '@/hooks'
import { BalanceExpanded, BalanceGroup } from '@/services'
import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { BALANCE_DOCUMENTATION_LINK, BALANCE_TABLE_STATIC_STATE } from '../../Balances.page.const'
import { BalancesGroupByHeader } from '../BalancesGroupByHeader'
import { BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY } from './BalancesGroupByTable.const'
import { BalancesGroupByTableProps } from './BalancesGroupByTable.types'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { BalanceDateWithWarning, FormattedTextIntl } from '@/components/@misc'

export const BalancesGroupByTable: React.FC<BalancesGroupByTableProps> = ({
    queryItem,
    filterBy,
    isSearching,
    ...props
}) => {
    const { relativePaths } = useNavigationRoutes()
    const onTableRowClick = useTableRowNavigation<BalanceExpanded>('id', relativePaths.ACCOUNTS.DETAILS)
    const isDataEmpty = useMemo<boolean>(() => isEmpty(queryItem.data?.groups), [queryItem])
    const rowCustomizers = useMemo(() => {
        if (filterBy?.innerState?.latest) {
            return {
                ...BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY,
                date: {
                    minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
                    size: COLUMN_WIDTH_RATIO.SHORT_DATE,
                    header() {
                        return <FormattedTextIntl messageId="app.table.header.value_date" />
                    },
                    cell(info) {
                        const value = info.getValue()

                        return (
                            <BalanceDateWithWarning value={value}>
                                <TableCellShortDate value={value} />
                            </BalanceDateWithWarning>
                        )
                    }
                }
            }
        }

        return BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY
    }, [filterBy])

    return (
        <QueryFallback
            objectType={ApiObjectTypeSchema.enum.balance}
            isLoading={queryItem.isLoading}
            isSuccess={queryItem.isSuccess}
            isError={queryItem.isError}
            isDataEmpty={isDataEmpty}
            isFilteringOrSearching={filterBy.isActive || isSearching}
            learnMoreLinkUrl={BALANCE_DOCUMENTATION_LINK}>
            <GroupByTable<BalanceExpanded, BalanceGroup>
                {...props}
                data={queryItem.data}
                onRowClick={onTableRowClick}
                groupHeader={BalancesGroupByHeader}
                state={BALANCE_TABLE_STATIC_STATE}
                columnCustomizers={rowCustomizers}
            />
        </QueryFallback>
    )
}
