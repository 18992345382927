import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdPaymentApprovalRule } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { PaymentApprovalRule } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import {
    PAYMENT_APPROVAL_RULE_DETAILS_ROW_CUSTOMIZERS,
    PAYMENT_APPROVAL_RULE_DETAILS_ROWS
} from './PaymentApprovalRuleDetails.page.const'
import { PaymentApprovalRuleCustomRows } from './PaymentApprovalRuleDetails.page.types'

export const PaymentApprovalRuleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentApprovalRule(uuid)
    const isDataEmpty = useMemo(() => isEmpty(query.data), [query.data])

    return (
        <PageLayout className="PaymentApprovalRuleDetails">
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_APPROVAL_RULE}
                permission={PERMISSION.SETTINGS_VIEW_PAYMENT_APPROVAL_RULES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_approval_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<PaymentApprovalRule, PaymentApprovalRuleCustomRows>
                        data={query?.data}
                        rows={PAYMENT_APPROVAL_RULE_DETAILS_ROWS}
                        rowCustomizers={PAYMENT_APPROVAL_RULE_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
