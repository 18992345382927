import { CallbackPage, ExtraLogoutPage, NotFoundPage } from '@/pages'
import {
    navigationProviderIndexRoutesFilter,
    navigationProviderWildCardRoutesFilter,
    navigationProviderDisabledRoutesFilter,
    navigationProviderHiddenRoutesFilter,
    navigationProviderGroupEmptyRoutesFilter
} from './NavigationRoutesProvider.utils'
import { ExtendedRouteObject } from './NavigationRoutesProvider.types'
import { UUID_PATTERN } from '@/utils/@validators'
import { combineFilters } from '@/utils'

export const defaultMainNavigationRoutesFilter = combineFilters<ExtendedRouteObject>(
    navigationProviderIndexRoutesFilter,
    navigationProviderWildCardRoutesFilter,
    navigationProviderDisabledRoutesFilter,
    navigationProviderHiddenRoutesFilter,
    navigationProviderGroupEmptyRoutesFilter
)

export const PATHNAME_LEGAL_ENTITY_ID_PRECEDING_SYMBOL = 'c'

export const PATHNAME_LEGAL_ENTITY_ID_WITH_PRECEDING_SYMBOL__REGEX = new RegExp(
    `^/${PATHNAME_LEGAL_ENTITY_ID_PRECEDING_SYMBOL}/${UUID_PATTERN.source}`,
    'i'
)
/**
 * Match the last part of the URL {uuid}/{action}$ while making sure to not match c/:legalEntityID
 */
export const PATHNAME_OBJECT_UUID_AND_RELATIVE_ACTION_REGEX = new RegExp(
    `(?<!/${PATHNAME_LEGAL_ENTITY_ID_PRECEDING_SYMBOL})/(${UUID_PATTERN.source})/[^/]+$`,
    'i'
)

export const NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS = Object.freeze({
    ROOT: '/',
    ACCOUNT: {
        LOGIN: '/account/login',
        ERROR: '/account/error'
    },
    HOME: '/home',
    PAYMENTS: {
        PAYMENT_ORDERS: '/payments/payment-orders',
        PAYMENT_FILES: '/payments/payment-files',
        EXPECTED_PAYMENTS: '/payments/expected-payments',
        INCOMING_PAYMENTS: '/payments/incoming-payments',
        RETURNS: '/payments/returns',
        PAYMENT_CAPTURES: '/payments/payment-captures'
    },
    ACCOUNTS: {
        TRANSACTIONS: '/accounts/transactions',
        BALANCES: '/accounts/balances'
    },
    COUNTERPARTIES: {
        ACCOUNT_HOLDERS: '/counterparties/account-holders',
        INTERNAL_ACCOUNTS: '/counterparties/internal-accounts',
        EXTERNAL_ACCOUNTS: '/counterparties/external-accounts',
        DIRECT_DEBIT_MANDATES: '/counterparties/direct-debit-mandates'
    },
    FILES: '/files',
    INVESTIGATIONS: {
        INQUIRIES: '/investigations/inquiries',
        CLAIMS: '/investigations/claims',
        RETURN_REQUESTS: '/investigations/return-requests'
    },
    DEVELOPERS: {
        API_KEYS: '/developers/api-keys',
        WEB_HOOKS: '/developers/webhooks',
        EVENTS: '/developers/events'
    },
    SETTINGS: {
        CONNECTED_ACCOUNTS: '/settings/connected-accounts',
        USERS: '/settings/users',
        ROLES: '/settings/roles',
        AUDIT_TRAIL: '/settings/audit-trail',
        CUSTOM_FIELDS: '/settings/custom-fields',
        RECONCILIATION_RULES: '/settings/reconciliation-rules',
        PAYMENT_APPROVAL_RULES: '/settings/payment-approval-rules',
        PAYMENT_RETRY_RULES: '/settings/payment-retry-rules'
    },
    CALLBACK: '/login-callback',
    INITIAL_CALLBACK: '/initial-login-callback',
    EXTRA_LOGOUT: '/extra-logout',
    DEBUG: '/debug',
    MISSING_ACCESS: '/missing-access',
    NOT_FOUND: '/not-found'
})

export const NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS = (() => {
    const dynamicPathSegments = {
        LEGAL_ENTITY_ID: `${PATHNAME_LEGAL_ENTITY_ID_PRECEDING_SYMBOL}/:legalEntityID`,
        UUID: ':uuid',
        // ---
        EDIT: ':uuid/edit',
        DENY: ':uuid/deny',
        DETAILS: ':uuid/details',
        CANCEL: ':uuid/cancel',
        REJECT: ':uuid/reject',
        RETURN: ':uuid/return',
        RETURN_REQUEST: ':uuid/return-request',
        EVENTS: ':uuid/events',
        RECONCILE: ':uuid/reconcile'
    }
    const staticPathSegmentsBySection = {
        INVITE: 'invite',
        NEW: 'new',
        UPLOAD: 'upload',
        IMPORT: 'import',
        PAYMENTS: {
            EDIT: 'edit',
            DENY: 'deny',
            CANCEL: 'cancel',
            REJECT: 'reject',
            RETURN: 'return',
            RETURN_REQUEST: 'return-request',
            EVENTS: 'events',
            DETAILS: 'details',
            APPROVALS: 'approvals',
            COUNTERPARTIES: 'counterparties',
            RETRIES: 'retries',
            RECONCILIATIONS: 'reconciliations',
            AUDIT_TRAIL: 'audit-trail'
        },
        INVESTIGATIONS: {
            REJECT: 'reject',
            DETAILS: 'details',
            EVENTS: 'events',
            AUDIT_TRAIL: 'audit-trail'
        },
        ACCOUNTS: {
            RECONCILE: 'reconcile',
            EDIT: 'edit',
            DETAILS: 'details',
            COUNTERPARTIES: 'counterparties',
            EVENTS: 'events',
            RECONCILIATIONS: 'reconciliations',
            AUDIT_TRAIL: 'audit-trail'
        },
        COUNTERPARTIES: {
            EDIT: 'edit',
            DETAILS: 'details',
            DENY: 'deny',
            INTERNAL_ACCOUNTS: 'internal-accounts',
            EXTERNAL_ACCOUNTS: 'external-accounts',
            COUNTERPARTIES: 'counterparty',
            VERIFICATIONS: 'verifications',
            EVENTS: 'events',
            AUDIT_TRAIL: 'audit-trail'
        },
        FILES: {
            EVENTS: 'events',
            DETAILS: 'details',
            AUDIT_TRAIL: 'audit-trail'
        },
        DEVELOPERS: {
            EDIT: 'edit',
            DETAILS: 'details',
            EVENTS: 'events',
            AUDIT_TRAIL: 'audit-trail'
        },
        SETTINGS: {
            DETAILS: 'details',
            EDIT: 'edit',
            INTERNAL_ACCOUNTS: 'internal-accounts',
            AUDIT_TRAIL: 'audit-trail',
            HISTORY: 'history'
        }
    }

    return {
        ...dynamicPathSegments,
        ...staticPathSegmentsBySection
    }
})()

export const CallbackRoute: ExtendedRouteObject = {
    title: 'Callback',
    path: '/login-callback',
    element: <CallbackPage isInitialLogin={false} />
}

export const InitialCallbackRoute: ExtendedRouteObject = {
    title: 'Callback',
    path: '/initial-login-callback',
    element: <CallbackPage isInitialLogin={true} />
}

export const ExtraLogoutRoute: ExtendedRouteObject = {
    title: 'app.common.account.logout.title',
    path: 'extra-logout',
    element: <ExtraLogoutPage />
}

export const NotFoundRoute: ExtendedRouteObject = {
    title: 'app.common.not_found.title',
    path: '*',
    element: <NotFoundPage />
}
