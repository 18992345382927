import React, { useMemo } from 'react'
import { PaymentApprovalRuleAttribute } from '@webapps/numeral-ui-core'
import { FormattedMessage, useIntl } from 'react-intl'
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components'
import { components } from 'react-select'
import { Select, SelectControlLabelChakraUIWrapper } from '@/components/@misc'
import { Box } from '@chakra-ui/react'

export const PaymentApprovalRuleCriterionAttribute: React.FC<{
    index: number
    value: PaymentApprovalRuleAttribute
    options: PaymentApprovalRuleAttribute[]
    isDisabled?: boolean
    onChange: (value: PaymentApprovalRuleAttribute) => void
}> = ({ index, value, options, isDisabled, onChange }) => {
    const intl = useIntl()
    const selectComponents = useMemo((): SelectComponentsConfig<PaymentApprovalRuleAttribute, any, any> => {
        if (!value) {
            return Object.create(null)
        }

        return {
            /**
             * @description
             * Because the options get filtered out as they are used, we need to use the value to get the label.
             */
            Control({ children, ...rest }) {
                return (
                    <components.Control {...rest}>
                        <SelectControlLabelChakraUIWrapper>
                            <FormattedMessage
                                id={`app.settings.payment_approval_rules.create.form.inputs.${value}.label`}
                            />
                        </SelectControlLabelChakraUIWrapper>
                    </components.Control>
                )
            }
        }
    }, [value])

    return (
        <Box minWidth="200px">
            <Select<PaymentApprovalRuleAttribute>
                name={`criteria.${index}.attribute`}
                value={value}
                options={options}
                onChange={onChange}
                isDisabled={isDisabled}
                isRequired={true}
                isSearchable={true}
                components={selectComponents}
                getOptionLabel={(option) => {
                    return intl.formatMessage({
                        id: `app.settings.payment_approval_rules.create.form.inputs.${option}.label`
                    })
                }}
            />
        </Box>
    )
}
