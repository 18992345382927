import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdBalance } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, Balance } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { BalancesCustomColumns } from '../Balances.page.types'
import { BALANCE_DETAILS_ROWS, BALANCE_ENTITY_DETAIL_ROW_CUSTOMIZERS } from './BalanceDetails.page.const'

export const BalanceDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdBalance(uuid)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data)
    }, [query.data])

    return (
        <PageLayout className="Balance">
            <RestrictedSection feature={ENTITY_FEATURE.BALANCE} permission={PERMISSION.ACCOUNTS_VIEW_BALANCES}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.balance}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<Balance, BalancesCustomColumns>
                        data={query.data}
                        rows={BALANCE_DETAILS_ROWS}
                        rowCustomizers={BALANCE_ENTITY_DETAIL_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
