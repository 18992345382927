import {
    Button,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Spinner,
    Stack,
    Text,
    UseDisclosureProps
} from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { ConnectedAccountName, If, StatusLabel } from '@/components/@misc'
import { UploadFileData, UploadFileResponse } from '@/services'
import { chain } from 'lodash'
import { UseConcurrentMutationResult, useNavigateWithLegalEntityID, useNavigationRoutes } from '@/hooks'
import { FormattedMessage } from 'react-intl'
import { isLastIndex } from '@/utils'
import { fileSizeFormatter } from '@/utils/@formatters'
import { ExtendedStatusSchema } from '@/schemas'

interface PaymentOrderUploadResultsModalProps extends UseDisclosureProps {
    mutationItem: UseConcurrentMutationResult<UploadFileResponse, string, UploadFileData[]>
    disabled?: boolean
    loading?: boolean
}

export const PaymentOrderUploadResultsModal: React.FC<PaymentOrderUploadResultsModalProps> = ({
    mutationItem,
    ...props
}) => {
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const onClickViewPaymentFiles = useCallback(() => {
        const { PAYMENTS } = paths
        navigateWithLegalEntityID(PAYMENTS.PAYMENT_FILES)
    }, [navigateWithLegalEntityID, paths])
    const firstMutationVariables = useMemo(() => {
        return chain(mutationItem).get('variables').first().value()
    }, [mutationItem])
    const allMutationVariables = useMemo(() => {
        return chain(mutationItem).get('variables').value()
    }, [mutationItem])

    const mutationItemsStatus = useMemo(() => {
        return chain(mutationItem).get('itemsStatus').value()
    }, [mutationItem])

    return (
        <Modal {...(props as ModalProps)} size="6xl" isCentered={true} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex justifyContent="space-between" noOfLines={1}>
                        <FormattedMessage
                            id="app.payments.payment_orders.upload.results.title"
                            values={{
                                connectedAccountName: (
                                    <ConnectedAccountName
                                        connectedAccountId={firstMutationVariables?.connected_account_id}
                                        asPlainText={true}
                                    />
                                )
                            }}
                        />
                    </Flex>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <Stack
                        maxHeight="500px"
                        overflowY="auto"
                        overflowX="hidden"
                        border="1px solid"
                        borderColor="gray.200"
                        padding="var(--numeral-ui-primary-spacing)"
                        gap="calc(var(--numeral-ui-primary-spacing)/2)">
                        {allMutationVariables?.map((variable, index, array) => {
                            const hasLastIndex = isLastIndex(index, array)
                            const hasMutationItemStatus = globalThis.Boolean(mutationItemsStatus?.[variable.id])
                            const file: File = variable.file

                            return (
                                <Stack key={`${file.name}-${index}`}>
                                    <Flex justify="space-between" align="center">
                                        <Flex gap="var(--numeral-ui-primary-spacing)" fontWeight="400">
                                            <Text>{file.name}</Text>
                                            <Text color="gray.500">{fileSizeFormatter(file.size)}</Text>
                                        </Flex>
                                        <If condition={hasMutationItemStatus}>
                                            {(() => {
                                                const statusItem = mutationItemsStatus?.[variable.id]
                                                switch (true) {
                                                    case statusItem?.isLoading:
                                                        return <Spinner />
                                                    case statusItem?.isSuccess:
                                                        return (
                                                            <StatusLabel value={ExtendedStatusSchema.enum.uploaded} />
                                                        )
                                                    case statusItem?.isError:
                                                        return (
                                                            <Flex gap="calc(var(--numeral-ui-primary-spacing)/2)">
                                                                <Text color="red.500">
                                                                    {(() => {
                                                                        switch (statusItem?.error?.status) {
                                                                            case 409: {
                                                                                return (
                                                                                    <FormattedMessage id="app.payments.payment_orders.upload.results.content.error.conflict" />
                                                                                )
                                                                            }

                                                                            default: {
                                                                                return (
                                                                                    <FormattedMessage id="app.payments.payment_orders.upload.results.content.error.general" />
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                </Text>
                                                                <StatusLabel value={ExtendedStatusSchema.enum.failed} />
                                                            </Flex>
                                                        )
                                                }
                                            })()}
                                        </If>
                                    </Flex>
                                    <If condition={!hasLastIndex}>
                                        <Divider marginY="calc(var(--numeral-ui-primary-spacing)/2)" />
                                    </If>
                                </Stack>
                            )
                        })}
                    </Stack>
                </ModalBody>
                <ModalFooter gap="var(--numeral-ui-primary-spacing)">
                    <Button
                        onClick={onClickViewPaymentFiles}
                        type="submit"
                        padding="var(--numeral-ui-primary-spacing)!important"
                        isDisabled={mutationItem.isPending}
                        isLoading={mutationItem.isPending}>
                        <FormattedMessage id="app.page.details.cells.related_objects.payment_files.link" />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
