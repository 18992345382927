import { Box, Flex, Stack, Switch, Text } from '@chakra-ui/react'
import { PaymentApprovalRuleApproverType, PaymentApprovalRuleStep } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'
import { NumberInput } from '@/components/@inputs'
import { useFormikContext } from 'formik'
import { PaymentApprovalRuleStepsApproversTypesInput } from './PaymentApprovalRuleStepsApproversTypesInput'
import { ChangeEvent } from 'react'
import { PaymentApprovalRuleStepsApproversValuesInput } from './PaymentApprovalRuleStepsApproversValuesInput'
import { If } from '@/components/@misc'

const APPROVAL_STEPS_MIN = 1
const APPROVAL_STEPS_MAX = 100

interface PaymentApprovalRuleStepsProps {
    steps?: PaymentApprovalRuleStep[][]
    isDisabled?: boolean
}

export const PaymentApprovalRuleSteps: React.FC<PaymentApprovalRuleStepsProps> = ({ steps, isDisabled }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext()

    return steps?.map((step, stepIndex) => {
        return step?.map((subStep, subStepIndex) => {
            const stepApprovalNumberFieldName = `steps.${stepIndex}.${subStepIndex}.approvers.number`
            const stepApprovalTypeFieldName = `steps.${stepIndex}.${subStepIndex}.approvers.type`
            const stepApprovalValuesFieldName = `steps.${stepIndex}.${subStepIndex}.approvers.values`
            const stepApprovalSelfApprovalFieldName = `steps.${stepIndex}.${subStepIndex}.self_approval_allowed`
            const hasApproverType = globalThis.Boolean(subStep?.approvers?.type)

            const onChangeApproverType = (value: PaymentApprovalRuleApproverType) => {
                Promise.all([
                    setFieldValue(stepApprovalTypeFieldName, value),
                    setFieldValue(stepApprovalValuesFieldName, []),
                    setFieldTouched(stepApprovalValuesFieldName, false)
                ])
            }
            const onChangeSelfApproval = (event: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(stepApprovalSelfApprovalFieldName, event.target.checked)
            }

            return (
                <Stack gap="var(--numeral-ui-primary-spacing)" key={subStepIndex}>
                    <Flex gap="var(--numeral-ui-primary-spacing)" alignItems="baseline" key={subStepIndex}>
                        <NumberInput
                            name={stepApprovalNumberFieldName}
                            placeholder="1"
                            value={subStep?.approvers?.number}
                            min={APPROVAL_STEPS_MIN}
                            max={APPROVAL_STEPS_MAX}
                            width="60px"
                            isLabelDisplayed={false}
                            isErrorHidden={true}
                            isRequired={true}
                            isDisabled={isDisabled}
                        />
                        <Text>
                            <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.steps.approvals_from" />
                        </Text>
                        <Box flex="1">
                            <PaymentApprovalRuleStepsApproversTypesInput
                                name={stepApprovalTypeFieldName}
                                value={subStep?.approvers?.type}
                                onChange={onChangeApproverType}
                                isRequired={true}
                                isDisabled={isDisabled}
                            />
                        </Box>
                    </Flex>
                    <If condition={hasApproverType}>
                        <PaymentApprovalRuleStepsApproversValuesInput
                            name={stepApprovalValuesFieldName}
                            value={subStep.approvers.values}
                            paymentApprovalRuleStep={subStep}
                            isLabelDisplayed={false}
                            isRequired={true}
                            isDisabled={isDisabled}
                        />
                    </If>
                    <Switch
                        name={stepApprovalSelfApprovalFieldName}
                        isChecked={subStep?.self_approval_allowed}
                        onChange={onChangeSelfApproval}
                        isDisabled={isDisabled}
                        size="sm">
                        <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.steps.self_approval_allowed" />
                    </Switch>
                </Stack>
            )
        })
    })
}
