import { Form, FormPageFooter, FormSection } from '@/components'
import { ConnectedAccountsInput, FileUploadInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import {
    fileIngestionServiceConnectedAccountsFilter,
    UPLOADER_PAYMENT_FILE_ACCEPTED_EXTENSIONS,
    UPLOADER_PAYMENT_FILE_ACCEPTED_SIZE_IN_BYTES,
    UPLOADER_PAYMENT_MAX_ACCEPTED_FILES,
    UploadFilesData
} from '@/services'
import { Alert, Flex, Switch } from '@chakra-ui/react'
import { noop } from 'lodash'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaymentOrderRetryRuleSection, PaymentOrderTypeDirectionInput } from '../../../@forms'
import { getPaymentOrderUploadFormInitialValues } from './PaymentOrderUpload.form.utils'
import { PaymentOrderTreasuryOptionInput } from '@/pages'
import { PaymentOrder } from '@webapps/numeral-ui-core'

interface PaymentOrderUploadFormProps {
    data?: UploadFilesData<PaymentOrder>
    disabled?: boolean
    loading?: boolean

    onSubmit?(data: UploadFilesData<PaymentOrder>): void
}

export const PaymentOrderUploadForm: React.FC<PaymentOrderUploadFormProps> = ({
    data,
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useMemo(() => {
        return getPaymentOrderUploadFormInitialValues(data)
    }, [data])
    const [connectedAccountId, setConnectedAccountId] = useState<string>()
    const hasConnectedAccountId = useMemo(() => {
        return globalThis.Boolean(connectedAccountId)
    }, [connectedAccountId])
    const query = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId
    })
    const hasFileIngestionService = useMemo(() => {
        return query.data?.services_activated?.some(fileIngestionServiceConnectedAccountsFilter)
    }, [query])

    const [isOverrideFileSettingsEnabled, setIsOverrideFileSettingsEnabled] = useState(false)

    const onChangeConnectedAccountId = useCallback(
        (id: string) => {
            setConnectedAccountId(id)
        },
        [setConnectedAccountId]
    )

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, dirty, setFieldValue, handleSubmit }) => {
                const isFormDisabled = disabled || !hasFileIngestionService || !dirty
                const onToggleOverrideFileSettings = () => {
                    setIsOverrideFileSettingsEnabled((prevState) => !prevState)

                    setFieldValue('type', undefined, false)
                    setFieldValue('treasury_option', undefined, false)

                    setFieldValue('direction', undefined, false)
                    setFieldValue('retry_details.payment_retry_rule_id', undefined, false)
                }

                return (
                    <>
                        <ConnectedAccountsInput
                            value={connectedAccountId}
                            name="connected_account_id"
                            isPlaceholderDisplayed={true}
                            onChange={onChangeConnectedAccountId}
                            customPlaceholderKey="app.payments.payment_orders.upload.form.inputs.connected_account_id.placeholder"
                            isDisabled={disabled}
                            isRequired={true}
                        />

                        <If condition={hasConnectedAccountId && !hasFileIngestionService}>
                            <Alert status="info" variant="numeralAccent">
                                <FormattedMessage id="app.payments.payment_orders.upload.form.validation.connected_account_id.invalid" />
                            </Alert>
                        </If>

                        <If condition={!hasConnectedAccountId || hasFileIngestionService}>
                            <FileUploadInput
                                name="files"
                                value={values.files}
                                customLabelKey="app.payments.payment_orders.upload.form.inputs.file.label"
                                acceptedExtensions={UPLOADER_PAYMENT_FILE_ACCEPTED_EXTENSIONS}
                                acceptedSize={UPLOADER_PAYMENT_FILE_ACCEPTED_SIZE_IN_BYTES}
                                max={UPLOADER_PAYMENT_MAX_ACCEPTED_FILES}
                                isRequired={hasFileIngestionService}
                            />

                            <FormSection
                                titleId="app.payments.payment_orders.upload.form.sections.payment_settings.title"
                                title={
                                    <Switch
                                        data-testid="toggle-override-file-settings"
                                        size="sm"
                                        isChecked={isOverrideFileSettingsEnabled}
                                        isDisabled={disabled}
                                        onChange={onToggleOverrideFileSettings}>
                                        <FormattedMessage id="app.payments.payment_orders.upload.form.switch.override_file_settings.title" />
                                    </Switch>
                                }>
                                <If condition={isOverrideFileSettingsEnabled}>
                                    <PaymentOrderTypeDirectionInput name="type" isDisabled={disabled} />
                                    <PaymentOrderTreasuryOptionInput name="treasury_option" isDisabled={disabled} />
                                    <PaymentOrderRetryRuleSection isDisabled={disabled} isSectionDisplayed={false} />
                                </If>
                                <If condition={!isOverrideFileSettingsEnabled}>
                                    <Flex justifyContent="center">
                                        <FormattedMessage id="app.payments.payment_orders.upload.form.sections.override_file_settings.disabled.content" />
                                    </Flex>
                                </If>
                            </FormSection>
                        </If>
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={isFormDisabled}
                            isLoading={loading}
                            showUnsavedChangesWarning={true}
                            submitLabelMessageId="app.payments.payment_orders.upload.form.actions.submit.label"
                        />
                    </>
                )
            }}
        </Form>
    )
}
