import {
    defaultMainNavigationRoutesFilter,
    navigationProviderFooterRoutesFilter,
    ExtendedRouteObject
} from '@/providers'
import { ENTITY_FEATURE, USER_ACCESS_CONFIGURATION_ENTITY_TYPE } from '@/services'
import { combineFilters } from '@/utils'
import { chain, partition } from 'lodash'

export function getAccordionGroupActiveIndexByCurrentPathname(
    routes: ExtendedRouteObject[],
    currentPathname: string
): number {
    const indexFinder = ({ path }: ExtendedRouteObject) => (path ? currentPathname?.includes(`/${path}`) : false)
    const groupFilter = ({ configuration }: ExtendedRouteObject) => configuration?.isGroup === true

    return chain(routes).filter(groupFilter).findIndex(indexFinder).value()
}

export function isAccordionGroupActive(
    currentIndex: number,
    realIndex: number,
    routes: ExtendedRouteObject[]
): boolean {
    const groupFilter = ({ configuration }: ExtendedRouteObject) => configuration?.isGroup === true

    return chain(routes)
        .findIndex(groupFilter)
        .thru((foundIndex) => realIndex - foundIndex === currentIndex)
        .value()
}

/**
 * @description
 * Split routes in two separate sections:
 * - Top main entries
 * - Lower main entries
 */
export function getPartitionedNavigationRoutes(
    routes: ExtendedRouteObject[],
    ...filterFunctions: ((item: ExtendedRouteObject, index?: number) => boolean)[]
): [ExtendedRouteObject[], ExtendedRouteObject[]] {
    const defaultRoutesFilter =
        filterFunctions && filterFunctions?.length > 0
            ? combineFilters<ExtendedRouteObject>(defaultMainNavigationRoutesFilter, ...filterFunctions)
            : defaultMainNavigationRoutesFilter

    const filteredRoutes = routes.filter(defaultRoutesFilter)

    return partition(filteredRoutes, navigationProviderFooterRoutesFilter)
}

/**
 * @description
 * Generate a filter that filters out children that don't match current legal entity type
 * @returns true if the route isn't restricted to financial institution or if the current legal entity is a financial institution
 */
export function getLegalEntityTypeRoutesFilter(
    currentLegalEntityType?: USER_ACCESS_CONFIGURATION_ENTITY_TYPE
): (route: ExtendedRouteObject) => boolean {
    return (route) => {
        return (
            !route.configuration?.isForFinancialInstitution ||
            currentLegalEntityType === USER_ACCESS_CONFIGURATION_ENTITY_TYPE.FINANCIAL_INSTITUTION
        )
    }
}

/**
 * Generate a filter that filters out children that require a feature not currently active
 * @param activeFeatures List of active features for the current legal entity
 * @returns true if the route doesn't require a feature or if the feature required is part of the active features
 */
export function getLegalEntityFeatureRoutesFilter(
    activeFeatures?: ENTITY_FEATURE[]
): (route: ExtendedRouteObject) => boolean {
    return ({ configuration }) => {
        return !configuration?.feature || !!activeFeatures?.includes(configuration?.feature)
    }
}
