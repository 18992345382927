import { Nullable } from '@/types'
import { Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { usePermissions, useQueryFindByIdAPIKey } from '@/hooks'
import React, { useMemo } from 'react'
import { getAPIKeyLast4CharsPlaceholderTemplate, PERMISSION } from '@/services'
import { useIntl } from 'react-intl'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { Skeleton, Text } from '@chakra-ui/react'
import { DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'

interface APIKeyNameProps {
    id?: Nullable<Uuid>
    asPlainText?: boolean
}

export const APIKeyName: React.FC<APIKeyNameProps> = ({ id, asPlainText }) => {
    const intl = useIntl()
    const hasValidUUID = useMemo(() => UuidSchema.safeParse(id).success, [id])
    const { hasPermission } = usePermissions()
    const hasViewAPIKeysPermission = useMemo(() => {
        return hasPermission(PERMISSION.DEVELOPERS_VIEW_API_KEYS)
    }, [hasPermission])
    const query = useQueryFindByIdAPIKey(id as string, {
        enabled: hasViewAPIKeysPermission && hasValidUUID
    })
    const formattedName = useMemo(() => {
        const last4Chars = getAPIKeyLast4CharsPlaceholderTemplate(query.data)
        const name = intl.formatMessage({ id: 'api.object.api_key' })

        if (!hasViewAPIKeysPermission) {
            return name
        }

        return [name, last4Chars].join(SPACE_SYMBOL)
    }, [query.data, hasViewAPIKeysPermission, intl])

    switch (true) {
        case !id:
        case query.isError:
        case query.isSuccess && !query.data?.name: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case query.isLoading: {
            return <Skeleton height="14px" width="150px" />
        }

        case asPlainText: {
            return (
                <Text as="span" title={formattedName}>
                    {formattedName}
                </Text>
            )
        }

        default: {
            return (
                <DetailPageLink objectId={query.data?.id} objectType={ApiObjectTypeExtendedSchema.enum.api_key}>
                    {formattedName}
                </DetailPageLink>
            )
        }
    }
}
