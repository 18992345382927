import { TableCellText, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { PaymentApprovalRule } from '@webapps/numeral-ui-core'
import { StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO } from '@/constants'
import { PaymentApprovalRuleCustomRows } from './PaymentApprovalRules.page.types'
import { chain } from 'lodash'
import { prettyPrintCasedWords } from '@/utils'
import { FormattedMessage } from 'react-intl'

export const PAYMENT_APPROVAL_RULES_TABLE_STATIC_STATE: TableStateWithEntity<
    PaymentApprovalRule,
    PaymentApprovalRuleCustomRows
> = {
    columnOrder: Object.freeze(['name', 'scope', 'aggregated_approvals', 'status'])
}

export const PAYMENT_APPROVAL_RULES_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<
    PaymentApprovalRule,
    PaymentApprovalRuleCustomRows
> = {
    name: {
        size: COLUMN_WIDTH_RATIO.NAME,
        header(info) {
            return <FormattedMessage id="app.table.header.rule_name" />
        },
        cell(info) {
            const value = info.getValue()
            return <>{value}</>
        }
    },
    scope: {
        cell(info) {
            const value = info.getValue()
            const formattedValue = prettyPrintCasedWords(value)

            return <TableCellText value={formattedValue} />
        }
    },
    aggregated_approvals: {
        size: COLUMN_WIDTH_RATIO.CAPTURE_TYPE,
        cell(info) {
            const value = chain(info.row.original?.steps).flatten().sumBy('approvers.number').value()?.toString()
            return <TableCellText value={value} />
        }
    },
    status: {
        size: COLUMN_WIDTH_RATIO.CAPTURE_TYPE,
        cell(info) {
            const value = info.getValue()
            return <StatusLabel value={value} />
        }
    }
}
