import { useMutationProcessPayments, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import { chain, noop } from 'lodash'
import React, { useCallback, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SupportEmailMailtoLink } from '@/components/@misc'
import { APIError } from '@/types'
import { AxiosError } from 'axios'
import { ProcessPaymentPayload } from '@webapps/numeral-ui-core'
import { WILDCARD_SYMBOL } from '@/constants'

export const MainNavigationProcessPaymentsConfirmationModal: React.FC<UseDisclosureProps> = ({
    isOpen,
    onClose = noop
}) => {
    const intl = useIntl()
    const { onAdd } = useToasts()
    const onError = useCallback(
        (error?: AxiosError<APIError<ProcessPaymentPayload>>) => {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                isClosable: true,
                title: intl.formatMessage({
                    id: 'app.payments.process_payments.actions.process.error.toast.title'
                }),
                description: intl.formatMessage(
                    { id: 'app.payments.process_payments.actions.process.error.toast.description' },
                    {
                        supportEmailMailtoLink: <SupportEmailMailtoLink />
                    }
                )
            })
        },
        [intl, onAdd]
    )

    const cancelRef = useRef(null)
    const mutation = useMutationProcessPayments({
        onSuccess(response) {
            const hasUnsuccessfullyProcessedPayments = chain(response)
                .get('accounts')
                .values()
                .some(({ unsuccessfully_processed }) => unsuccessfully_processed != null)
                .value()

            if (hasUnsuccessfullyProcessedPayments) {
                onError()
            } else {
                onClose(response)

                onAdd({
                    variant: TOAST_VARIANTS.SUCCESS,
                    status: 'success',
                    isClosable: true,
                    title: intl.formatMessage({
                        id: 'app.payments.process_payments.actions.process.success.toast.title'
                    }),
                    description: intl.formatMessage({
                        id: 'app.payments.process_payments.actions.process.success.toast.description'
                    })
                })
            }
        },
        onError
    })
    const onProcessPayments = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        mutation.mutate({
            connected_account_ids: [WILDCARD_SYMBOL],
            payment_types: [WILDCARD_SYMBOL]
        })
    }, [mutation])
    const onCloseWithLoading = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        onClose()
    }, [mutation, onClose])

    return (
        <AlertDialog isOpen={isOpen as boolean} leastDestructiveRef={cancelRef} onClose={onCloseWithLoading}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <FormattedMessage id="app.payments.process_payments.title" />
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <FormattedMessage id="app.payments.process_payments.description" />
                    </AlertDialogBody>
                    <AlertDialogFooter gap="var(--numeral-ui-primary-spacing)">
                        <Button
                            variant="secondary"
                            ref={cancelRef}
                            onClick={onCloseWithLoading}
                            disabled={mutation.isPending}>
                            <FormattedMessage id="app.common.actions.cancel.label" />
                        </Button>
                        <Button onClick={onProcessPayments} isLoading={mutation.isPending}>
                            <FormattedMessage id="app.payments.process_payments.actions.process.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
