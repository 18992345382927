import { EntityDetailCardRowCustomizers, EntityDetailCardRowKey, TableCellShortDate } from '@/components'
import { ConnectedAccountName, FormattedAmount } from '@/components/@misc'
import { ENTITY_DETAIL_CARD_ROW_ACTION_TYPE } from '@/components/EntityDetailCard'
import { Transaction } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'

export const TRANSACTION_RECONCILE_HEADER_CARD_ROW_KEYS: EntityDetailCardRowKey<Transaction>[] = [
    'created_at',
    'value_date',
    'amount',
    'unreconciled_amount',
    'external_account_holder_name',
    'external_account_number',
    'description',
    'connected_account_id'
]

export const TRANSACTION_RECONCILE_HEADER_CARD_ROW_CUSTOMIZERS: EntityDetailCardRowCustomizers<Transaction> = {
    created_at: {
        cell(item) {
            return <TableCellShortDate value={item.created_at} />
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY
        }
    },
    value_date: {
        cell(item) {
            return <TableCellShortDate value={item.value_date} />
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY
        }
    },
    amount: {
        cell(item) {
            return <FormattedAmount amount={item.amount} currency={item.currency} />
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY
        }
    },
    unreconciled_amount: {
        cell(item) {
            const unreconciledAmount = Math.max(0, item.amount - item.reconciled_amount)
            return <FormattedAmount amount={unreconciledAmount} currency={item.currency} />
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY
        }
    },
    external_account_holder_name: {
        cell(item) {
            return item.external_account?.holder_name
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.SEARCH,
            isHidden(item) {
                return isEmpty(item.external_account?.holder_name)
            }
        }
    },
    external_account_number: {
        cell(item) {
            return item.external_account?.account_number
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.SEARCH,
            isHidden(item) {
                return isEmpty(item.external_account?.account_number)
            }
        }
    },
    description: {
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.SEARCH,
            isHidden(item) {
                return isEmpty(item.description)
            }
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} asPlainText={true} />
        },
        action: {
            type: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY
        }
    }
}
