import { IntlShape } from 'react-intl'
import { SafeParseReturnType, z } from 'zod'
import { get } from 'lodash'
import { FormikInputConfiguration } from '@/types'
import { asOptionalField, sanitiseValidatorValue } from '@/utils/@validators/validators.utils'

const DEFAULT_MIN_VALUE = -Infinity
const DEFAULT_MAX_VALUE = Infinity

export function numberValidator(intl: IntlShape, configuration?: FormikInputConfiguration) {
    const NumberSchema = z.number()

    return (value: unknown): string | void => {
        const minValue = get(configuration, 'min', DEFAULT_MIN_VALUE)
        const maxValue = get(configuration, 'max', DEFAULT_MAX_VALUE)
        const sanitisedValue = sanitiseValidatorValue(value)

        switch (true) {
            case configuration?.isRequired && globalThis.Number.isNaN(sanitisedValue): {
                return intl.formatMessage({ id: 'app.common.form.validation.required' })
            }

            case (sanitisedValue as number) > maxValue: {
                return intl.formatMessage(
                    { id: 'app.common.form.validation.number.max.invalid' },
                    {
                        max: maxValue
                    }
                )
            }

            case (sanitisedValue as number) < minValue: {
                return intl.formatMessage(
                    { id: 'app.common.form.validation.number.min.invalid' },
                    {
                        min: minValue
                    }
                )
            }
        }

        const validationResult: SafeParseReturnType<number, any> = configuration?.isRequired
            ? NumberSchema.safeParse(sanitisedValue)
            : asOptionalField(NumberSchema).safeParse(sanitisedValue)

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.number.invalid' })
        }
    }
}
