import { RestrictedSection } from '@/components'
import { useMutationUploadFilesConcurrent, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ENTITY_FEATURE, PERMISSION, UPLOADER_REJECTIONS, UploadFilesData } from '@/services'
import { Box, useDisclosure } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PaymentOrderUploadForm, PaymentOrderUploadResultsModal, transformUploadFilesRequest } from './@components'
import { PaymentOrder } from '@webapps/numeral-ui-core'

export const PaymentOrderUploadPage: React.FC = () => {
    const intl = useIntl()
    const { onAdd } = useToasts()
    const [isResultsModalOpen, setIsResultsModalOpen] = useState(false)
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.payment_orders.upload.title' }), [intl])
    const mutation = useMutationUploadFilesConcurrent({
        onMutate() {
            onOpenResultsModal()
        },
        onSuccess() {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.payments.payment_orders.upload.form.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.payments.payment_orders.upload.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            switch (error) {
                case UPLOADER_REJECTIONS.PARTIAL_REJECTION: {
                    onAdd({
                        variant: TOAST_VARIANTS.WARNING,
                        status: 'warning',
                        title: (
                            <FormattedMessage id="app.payments.payment_orders.upload.form.validation.error.partial.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.payments.payment_orders.upload.form.validation.error.partial.toast.description" />
                        )
                    })
                    break
                }

                case UPLOADER_REJECTIONS.FULL_REJECTION:
                default: {
                    onAdd({
                        variant: TOAST_VARIANTS.ERROR,
                        status: 'error',
                        title: (
                            <FormattedMessage id="app.payments.payment_orders.upload.form.validation.error.toast.title" />
                        ),
                        description: (
                            <FormattedMessage
                                id="app.payments.payment_orders.upload.form.validation.error.toast.description"
                                values={{ message: <FormattedMessage id="app.common.error.title" /> }}
                            />
                        )
                    })
                }
            }
        }
    })
    const onOpenResultsModal = useCallback(() => {
        setIsResultsModalOpen(true)
    }, [mutation])
    const onCloseResultsModal = useCallback(() => {
        setIsResultsModalOpen(false)
        navigateWithLegalEntityID(0)
    }, [mutation, navigateWithLegalEntityID, paths])
    const onSubmit = useCallback(
        (data: UploadFilesData<PaymentOrder>) => {
            const requestData = transformUploadFilesRequest(data)
            mutation.mutate(requestData)
        },
        [mutation]
    )

    const disclosure = useDisclosure({
        isOpen: isResultsModalOpen,
        onOpen: onOpenResultsModal,
        onClose: onCloseResultsModal
    })

    return (
        <PageLayout className="PaymentOrderUpload">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS}>
                <Box marginTop="calc(var(--numeral-ui-primary-spacing)/2)">
                    <PaymentOrderUploadForm
                        onSubmit={onSubmit}
                        loading={mutation.isPending}
                        disabled={mutation.isPending || isResultsModalOpen}
                    />
                </Box>
                <PaymentOrderUploadResultsModal {...disclosure} mutationItem={mutation} />
            </RestrictedSection>
        </PageLayout>
    )
}
