import { Amount, CurrencyCode } from '@webapps/numeral-ui-core'
import { dinero, toDecimal } from 'dinero.js'
import { isNil } from 'lodash'
import { getDineroCurrency } from '../getDineroCurrency'

/**
 * This util allows to convert a minor currency amount into a major currency (float) amount
 */
export function convertToMajorUnitAmount(minorUnitAmount: Amount, currencyCode: CurrencyCode): Amount | undefined {
    if (isNil(minorUnitAmount) || isNil(currencyCode)) {
        return
    }

    const dineroCurrency = getDineroCurrency(currencyCode)

    if (isNil(dineroCurrency)) {
        return
    }

    const dineroWrapper = dinero({ amount: minorUnitAmount, currency: dineroCurrency })
    const decimalAmount = toDecimal(dineroWrapper)

    return globalThis.Number(decimalAmount)
}
