import React, { useMemo } from 'react'
import { PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { usePaymentApprovalRuleAvailableAttributes, usePaymentApprovalRuleDefaultCriterion } from '../@hooks'
import { PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS } from '../PaymentApprovalRule.form.const'
import { FieldArray } from 'formik'
import { Button, Stack } from '@chakra-ui/react'
import { PaymentApprovalRuleCriterionListItem } from '../@components/PaymentApprovalRuleCriterionListItem'
import { isEmpty } from 'lodash'
import {
    factoryEmptyPaymentApprovalRuleCriterion,
    getSafeFormValuesFromFormikArrayHelper
} from '../PaymentApprovalRule.form.utils'
import { FormattedMessage } from 'react-intl'

interface PaymentApprovalRuleCriterionListProps {
    criteria: PaymentApprovalRuleCriteria[]
    isDisabled?: boolean
}

export const PaymentApprovalRuleCriterionList: React.FC<PaymentApprovalRuleCriterionListProps> = ({
    criteria,
    isDisabled
}) => {
    const { getActualIndex, getVisibleCriteria } = usePaymentApprovalRuleDefaultCriterion(criteria)
    const visibleCriteria = useMemo(() => getVisibleCriteria(), [criteria])
    const hasEmptyCriteria = !visibleCriteria.length
    const hasEmptyCriteriaValues = visibleCriteria.some(({ values }) => isEmpty(values.filter(globalThis.Boolean)))

    return (
        <FieldArray
            name="criteria"
            render={(arrayHelpers) => {
                const values = getSafeFormValuesFromFormikArrayHelper(arrayHelpers)
                const availableAttributes = usePaymentApprovalRuleAvailableAttributes(values.criteria)
                const hasEmptyAvailableAttributes = !availableAttributes.length

                return (
                    <Stack>
                        {visibleCriteria.map((criterion, visualIndex) => {
                            const actualIndex = getActualIndex(visualIndex)
                            const composedKey = `${visualIndex}-${criterion.attribute}` //needed/refreshes the input

                            return (
                                <PaymentApprovalRuleCriterionListItem
                                    key={composedKey}
                                    index={actualIndex}
                                    criterion={criterion}
                                    arrayHelpers={arrayHelpers}
                                    isDisabled={isDisabled}
                                    availableAttributes={availableAttributes}
                                />
                            )
                        })}
                        <Button
                            size="sm"
                            maxWidth="200px"
                            leftIcon={<>+</>}
                            width="fit-content"
                            variant={hasEmptyCriteria ? 'ghost' : undefined}
                            backgroundColor={hasEmptyCriteria ? 'gray.200' : undefined}
                            isDisabled={isDisabled || hasEmptyAvailableAttributes || hasEmptyCriteriaValues}
                            onClick={() => arrayHelpers.push(factoryEmptyPaymentApprovalRuleCriterion())}>
                            <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.add_criterion.label" />
                        </Button>
                    </Stack>
                )
            }}
        />
    )
}
