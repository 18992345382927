import { RestrictedSection } from '@/components'
import { useMutationCreateInternalAccount, useNavigateWithLegalEntityID, useNavigationRoutes, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { InternalAccountCreateErrorNotification, InternalAccountForm } from './@components'

export const InternalAccountCreatePage: React.FC = () => {
    const intl = useIntl()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.internal_accounts.create.title' }),
        [intl]
    )
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { onAdd } = useToasts()
    const { paths, relativePaths } = useNavigationRoutes()
    const mutation = useMutationCreateInternalAccount({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { COUNTERPARTIES } = paths
            const path = [COUNTERPARTIES.INTERNAL_ACCOUNTS, response.id, relativePaths.COUNTERPARTIES.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: intl.formatMessage({
                    id: 'app.counterparties.internal_accounts.create.form.validation.create.success.title'
                }),
                description: intl.formatMessage({
                    id: 'app.counterparties.internal_accounts.create.form.validation.create.success.description'
                })
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: intl.formatMessage({
                    id: 'app.counterparties.internal_accounts.create.form.validation.create.error.title'
                }),
                description: <InternalAccountCreateErrorNotification error={error} />
            })
        }
    })
    return (
        <PageLayout className="InternalAccountCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_CREATE_INTERNAL_ACCOUNTS}>
                <Box marginTop="calc(var(--numeral-ui-primary-spacing)/2)">
                    <InternalAccountForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={mutation.isPending}
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
