import {
    APIKeysService,
    AuditTrailService,
    BalancesService,
    ConnectedAccountsService,
    IdsResolverService,
    PaymentOrdersResolverService,
    RolesService,
    UploaderService,
    UserService,
    UsersService,
    WebhooksService,
    WidgetsService
} from '@/services'
import {
    AccountHoldersService,
    AuthenticationService,
    BankCodeLookupService,
    BulkActionsService,
    ClaimsService,
    ConfigurationService,
    CustomFieldsService,
    DirectDebitMandatesService,
    EventsService,
    ExpectedPaymentsService,
    ExternalAccountsService,
    FilesService,
    IncomingPaymentsService,
    InquiriesService,
    InternalAccountsService,
    PaymentApprovalRulesService,
    PaymentCapturesService,
    PaymentFilesService,
    PaymentOrdersService,
    PaymentRetryRulesService,
    ProcessPaymentsService,
    ReconciliationRulesService,
    ReconciliationsService,
    ReturnRequestsService,
    ReturnsService,
    TransactionsService,
    withInvalidSessionInterceptor
} from '@webapps/numeral-ui-core'
import { AxiosRequestConfig } from 'axios'
import { noop } from 'lodash'
import { BFFServiceInstances, PublicAPIServiceInstances } from './ServicesProvider.types'

/** @todo Write unit test */
export function getInvalidSessionInterceptor(onInvalidSession = noop): [(config: any) => any, (error: any) => void] {
    const onFulfill = (config: AxiosRequestConfig) => config
    const onRejected = withInvalidSessionInterceptor(onInvalidSession)

    return [onFulfill, onRejected]
}

export function getBFFServiceInstances(configuration?: ConfigurationService): BFFServiceInstances {
    return {
        widgetsService: new WidgetsService(configuration),
        apiKeysService: new APIKeysService(configuration),
        webhooksService: new WebhooksService(configuration),
        rolesService: new RolesService(configuration),
        authenticationService: new AuthenticationService(configuration),
        userService: new UserService(configuration),
        usersService: new UsersService(configuration),
        uploaderService: new UploaderService(configuration),
        auditTrailService: new AuditTrailService(configuration),
        connectedAccountsService: new ConnectedAccountsService(configuration),
        idsResolverService: new IdsResolverService(configuration),
        paymentOrdersResolverService: new PaymentOrdersResolverService(configuration)
    }
}

export function getPublicAPIServiceInstances(configuration?: ConfigurationService): PublicAPIServiceInstances {
    return {
        paymentOrdersService: new PaymentOrdersService(configuration),
        paymentFilesService: new PaymentFilesService(configuration),
        expectedPaymentsService: new ExpectedPaymentsService(configuration),
        incomingPaymentsService: new IncomingPaymentsService(configuration),
        returnsService: new ReturnsService(configuration),
        returnRequestsService: new ReturnRequestsService(configuration),
        inquiriesService: new InquiriesService(configuration),
        claimsService: new ClaimsService(configuration),
        paymentCapturesService: new PaymentCapturesService(configuration),
        paymentRetryRulesService: new PaymentRetryRulesService(configuration),
        paymentApprovalRulesService: new PaymentApprovalRulesService(configuration),

        transactionsService: new TransactionsService(configuration),
        balancesService: new BalancesService(configuration),

        accountHoldersService: new AccountHoldersService(configuration),
        externalAccountsService: new ExternalAccountsService(configuration),
        internalAccountsService: new InternalAccountsService(configuration),
        directDebitMandatesService: new DirectDebitMandatesService(configuration),

        filesService: new FilesService(configuration),

        eventsService: new EventsService(configuration),
        reconciliationsService: new ReconciliationsService(configuration),
        reconciliationRulesService: new ReconciliationRulesService(configuration),

        bulkActionsService: new BulkActionsService(configuration),

        processPaymentsService: new ProcessPaymentsService(configuration),
        bankCodeLookupService: new BankCodeLookupService(configuration),
        customFieldsService: new CustomFieldsService(configuration)
    }
}
