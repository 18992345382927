import {
    QueryFallback,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import {
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllPaymentApprovalRules
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import {
    ApiObjectTypeSchema,
    PaymentApprovalRule,
    PaymentApprovalRulesServiceFindAllQueryOptions,
    PaymentApprovalRuleStatusSchema
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import {
    PAYMENT_APPROVAL_RULES_TABLE_COLUMN_CUSTOMIZERS,
    PAYMENT_APPROVAL_RULES_TABLE_STATIC_STATE
} from './PaymentApprovalRules.page.const'
import { PaymentApprovalRuleCustomRows } from './PaymentApprovalRules.page.types'
import { useIntl } from 'react-intl'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { queryDataAggregation } from '@/utils'

export const PaymentApprovalRulesPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindAllPaymentApprovalRules()
    const data = useMemo(() => {
        return queryDataAggregation(query.data).filter((item) => {
            return item.status !== PaymentApprovalRuleStatusSchema.enum.disabled
        })
    }, [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<PaymentApprovalRule>('id')
    const actions = useMemo<TableHeaderActionProps[]>(() => {
        const actions: TableHeaderActionProps[] = []
        if (hasPermission(PERMISSION.SETTINGS_CREATE_PAYMENT_APPROVAL_RULES)) {
            actions.push({
                onClick() {
                    navigateWithLegalEntityID(relativePaths.NEW)
                },
                children: intl.formatMessage({
                    id: 'app.settings.payment_approval_rules.create.form.actions.submit.label'
                })
            })
        }

        return actions
    }, [hasPermission, intl, isDataEmpty, navigateWithLegalEntityID, relativePaths.NEW])

    return (
        <PageLayout className="PaymentApprovalRules">
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_APPROVAL_RULE}>
                <TableHeader<PaymentApprovalRulesServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_approval_rule}
                    isLoading={query.isLoading}
                    actions={actions}
                />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.payment_approval_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <Table<PaymentApprovalRule, PaymentApprovalRuleCustomRows>
                        data={data}
                        onRowClick={onTableRowClick}
                        columnCustomizers={PAYMENT_APPROVAL_RULES_TABLE_COLUMN_CUSTOMIZERS}
                        state={PAYMENT_APPROVAL_RULES_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
