import { RestrictedSection } from '@/components'
import {
    useMutationCreatePaymentApprovalRule,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PaymentApprovalRuleForm } from './@components'
import { PaymentApprovalRule } from '@webapps/numeral-ui-core'
import { APIValidationErrorNotificationBody } from '@/components/@misc'

export const PaymentApprovalRuleCreatePage: React.FC = () => {
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo(() => intl.formatMessage({ id: 'app.settings.payment_approval_rules.create.title' }), [intl])
    const mutation = useMutationCreatePaymentApprovalRule({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { SETTINGS } = paths
            const path = [SETTINGS.PAYMENT_APPROVAL_RULES, response.id, relativePaths.SETTINGS.DETAILS].join('/')

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.settings.payment_approval_rules.create.form.validation.create.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.settings.payment_approval_rules.create.form.validation.create.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: (
                    <FormattedMessage id="app.settings.payment_approval_rules.create.form.validation.create.error.toast.title" />
                ),
                description: (
                    <APIValidationErrorNotificationBody<PaymentApprovalRule>
                        error={error}
                        messageId="app.settings.payment_approval_rules.create.form.validation.create.error.toast.description"
                    />
                )
            })
        }
    })

    return (
        <PageLayout className="PaymentApprovalRuleCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_APPROVAL_RULE}
                permission={PERMISSION.SETTINGS_CREATE_PAYMENT_APPROVAL_RULES}>
                <Box marginTop="calc(var(--numeral-ui-primary-spacing)/2)">
                    <PaymentApprovalRuleForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={mutation.isPending}
                        submitLabelMessageId="app.settings.roles.create.form.actions.submit.label"
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
