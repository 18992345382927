import { Nullable } from '@/types'
import { Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { usePermissions, useQueryFindByIdUser } from '@/hooks'
import React, { useMemo } from 'react'
import { getUserFullName, PERMISSION } from '@/services'
import { useIntl } from 'react-intl'
import { DetailPageLink, RestrictedData } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Skeleton, Text } from '@chakra-ui/react'

export interface UserNameProps {
    id?: Nullable<Uuid>
    asPlainText?: boolean
}

export const UserName: React.FC<UserNameProps> = ({ id, asPlainText }) => {
    const intl = useIntl()
    const hasValidUUID = useMemo(() => UuidSchema.safeParse(id).success, [id])
    const { hasPermission } = usePermissions()
    const hasViewUsersPermission = useMemo(() => {
        return hasPermission(PERMISSION.SETTINGS_VIEW_USERS)
    }, [hasPermission])
    const query = useQueryFindByIdUser(id as string, {
        enabled: hasViewUsersPermission && hasValidUUID
    })
    const formattedName = useMemo(() => {
        if (!hasViewUsersPermission) {
            return intl.formatMessage({ id: 'api.object.user.alternative' })
        }

        const emptyPlaceholder = intl.formatMessage({ id: 'app.table.cell.user_full_name_placeholder' })

        return getUserFullName(query.data, emptyPlaceholder)
    }, [hasViewUsersPermission, query.data, intl])

    switch (true) {
        case !id:
        case query.isError: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case !hasPermission(PERMISSION.SETTINGS_VIEW_USERS): {
            return <RestrictedData />
        }

        case query.isLoading: {
            return <Skeleton height="14px" width="150px" />
        }

        case asPlainText: {
            return (
                <Text as="span" title={formattedName}>
                    {formattedName}
                </Text>
            )
        }

        default: {
            return (
                <DetailPageLink objectId={query.data?.id} objectType={ApiObjectTypeExtendedSchema.enum.user}>
                    {formattedName}
                </DetailPageLink>
            )
        }
    }
}
