import { If, Select, useSearchQuerySelect } from '@/components/@misc'
import { useCustomFormikField, usePermissions, useQueryFindAllUsers } from '@/hooks'
import { getUserFullName, PERMISSION, User } from '@/services'
import { FormikInput } from '@/types'
import { queryDataAggregation } from '@/utils'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { uuidValidator } from '@/utils/@validators'
import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { TextInput } from '@/components/@inputs'

interface UsersInputInputProps extends FormikInput {
    customPlaceholderKey?: string
}

export const UsersInput: React.FC<UsersInputInputProps> = ({
    name,
    value,
    isDisabled,
    customPlaceholderKey,
    isRequired,
    searchOnMount,
    isPlaceholderDisplayed = false,
    isLabelDisplayed = true,
    validator = uuidValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const [field, meta, helpers] = useCustomFormikField<Uuid>({
        name,
        value,
        validate: validator(intl, { isRequired })
    })
    const { enabled, limit, inputText, onInputChange, onFocus } = useSearchQuerySelect(searchOnMount)
    const query = useQueryFindAllUsers(
        { limit },
        {
            enabled: !isDisabled && enabled
        }
    )
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, intl, query, isPlaceholderDisplayed])
    const onChange = useCallback(
        (value: Uuid) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps]
    )
    const isInvalid = useMemo(() => {
        return meta?.touched && !!meta?.error
    }, [meta])
    const hasViewUsersPermission = useMemo(() => {
        return hasPermission(PERMISSION.SETTINGS_VIEW_USERS)
    }, [hasPermission])

    if (hasViewUsersPermission) {
        return (
            <FormControl key={name} isInvalid={isInvalid}>
                <If condition={isLabelDisplayed}>
                    <FormLabel htmlFor={name}>
                        <FormattedMessage id={`app.common.form.input.${name}.label`} />
                    </FormLabel>
                </If>
                <Select<User>
                    {...inputProps}
                    {...field}
                    id={name}
                    options={data}
                    placeholder={placeholder}
                    isInvalid={isInvalid}
                    isRequired={isRequired}
                    isDisabled={isDisabled}
                    isLoading={query.isLoading}
                    isClearable={true}
                    inputValue={inputText}
                    onChange={onChange}
                    onFocus={onFocus}
                    onInputChange={onInputChange}
                    getOptionLabel={(option) => {
                        if (!hasViewUsersPermission) {
                            return intl.formatMessage({ id: 'api.object.user.alternative' })
                        }

                        const emptyPlaceholder = intl.formatMessage({ id: 'app.table.cell.user_full_name_placeholder' })

                        return getUserFullName(option, emptyPlaceholder)
                    }}
                    getOptionValue={(option) => option?.id as Uuid}
                />
                <FormErrorMessage>{meta?.error}</FormErrorMessage>
            </FormControl>
        )
    }

    return <TextInput {...field} {...inputProps} id={name} placeholder={placeholder} validator={validator} />
}
