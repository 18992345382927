import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    UseDisclosureProps
} from '@chakra-ui/react'
import { If } from '@/components/@misc'
import { PropsWithChildren } from 'react'

interface EntityDrawerProps extends UseDisclosureProps, PropsWithChildren {
    title?: string
}

export function EntityDrawer({ title, children, isOpen, onOpen, onClose }: EntityDrawerProps) {
    const disclosure = useDisclosure({
        isOpen,
        onOpen,
        onClose
    })

    return (
        <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="right" size="xl" closeOnEsc={true}>
            <DrawerOverlay />
            <DrawerContent>
                <If condition={globalThis.Boolean(title)}>
                    <DrawerHeader title={title} aria-label={title}>
                        {title}
                    </DrawerHeader>
                </If>
                <DrawerCloseButton />
                <DrawerBody>{children}</DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
