import { isFileBlob } from '@/utils'
import { FileFieldValidatorError, filesValidator } from '@/utils/@validators'
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import classNames from 'classnames'
import { useField } from 'formik'
import React, { ChangeEvent, createRef, DragEventHandler, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    FileUploadInputBrowseLink,
    FileUploadInputPreview,
    FileUploadInputStatusActive,
    FileUploadInputStatusProcessing,
    FileUploadInputStatusSuccess
} from './@components'
import { usePreventWindowDragOverAndDrop } from './@hooks'
import './FileUploadInput.scss'
import { FileReaderReadyState, FileUploadInputDataTransferKind, FileUploadInputProps } from './FileUploadInput.types'
import {
    acceptedFileInputExtensions,
    formatAcceptedExtensions,
    formatAcceptedSizeBytesToMB,
    getFileReadersWithEventHandlers
} from './FileUploadInput.utils'
import { If } from '@/components/@misc'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { chain } from 'lodash'
import { FILE_UPLOAD_ELEMENT_OPACITY } from '@/components/@inputs/FileUploadInput/FileUploadInput.const'

export const FileUploadInput: React.FC<FileUploadInputProps> = ({
    name,
    value,
    isDisabled,
    customLabelKey,
    acceptedExtensions,
    acceptedSize,
    isRequired,
    isErrorHidden,
    min,
    max,
    validator = filesValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [isDragging, setIsDragging] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const inputAcceptedExtensions = useMemo(() => acceptedFileInputExtensions(acceptedExtensions), [acceptedExtensions])
    const formattedAcceptedExtensions = useMemo(
        () => formatAcceptedExtensions(acceptedExtensions),
        [acceptedExtensions, intl]
    )
    const formattedAcceptedSize = useMemo(() => formatAcceptedSizeBytesToMB(acceptedSize), [acceptedSize])
    const [field, meta, helpers] = useField<File[] | File>(
        {
            name,
            value,
            accept: inputAcceptedExtensions,
            validate: validator?.(
                intl,
                { isRequired, min, max },
                {
                    acceptedExtensions,
                    acceptedSize,
                    formattedAcceptedSize,
                    formattedAcceptedExtensions
                }
            )
        } as any /* Cast value to `any` because this validator returns a custom error (form level and file level)*/
    )
    const refInput = createRef<HTMLInputElement>()
    const labelKey = useMemo<string>(
        () => customLabelKey || `app.common.form.input.${name}.label`,
        [customLabelKey, name]
    )
    const formError = useMemo<string>(() => {
        return chain(meta.error as unknown as FileFieldValidatorError)
            .get('formError')
            .value()
    }, [meta.error])
    const fileErrors = useMemo<string[]>(() => {
        return chain(meta.error as unknown as FileFieldValidatorError)
            .get('fileErrors', [])
            .value()
    }, [meta.error])

    const hasFileErrors = useMemo<boolean>(() => {
        return !chain(fileErrors).isEmpty().value()
    }, [fileErrors])

    const hasFormError = useMemo<boolean>(() => {
        const errorWrapper = chain(meta.error as unknown as FileFieldValidatorError)
        return errorWrapper.isString().value() || errorWrapper.has('formError').value()
    }, [meta.error])

    const hasSelectedFiles = useMemo(() => selectedFiles.length > 0, [selectedFiles])
    const hasAnyErrors = useMemo(() => hasFormError || hasFileErrors, [hasFormError, hasFileErrors])
    const isSuccessful = useMemo(
        () => !isDisabled && hasSelectedFiles && !hasAnyErrors,
        [isDisabled, hasSelectedFiles, hasAnyErrors]
    )
    const isActive = !isDisabled && !hasAnyErrors && !hasSelectedFiles

    const dropZoneContainerClassName = useMemo(() => {
        return classNames('FileUploadInput-Container', {
            Error: hasAnyErrors,
            Active: isActive,
            Disabled: isDisabled,
            Success: isSuccessful && !isProcessing,
            Processing: isProcessing && !isSuccessful
        })
    }, [hasAnyErrors, isProcessing, isActive, isDisabled, isSuccessful])

    const fileReaderEventHandlers = useMemo(() => {
        const errorMessage = intl.formatMessage({
            id: '"app.common.error.title"'
        })

        return {
            onload(fileBlob: File, fileReader: FileReader) {
                switch (fileReader.readyState) {
                    case FileReaderReadyState.DONE: {
                        setSelectedFiles((prevFiles) => {
                            const fileExists = prevFiles.some(
                                (file) => file.name === fileBlob.name && file.size === fileBlob.size
                            )

                            if (fileExists) {
                                return prevFiles
                            }

                            const updatedFiles = [...prevFiles, fileBlob]
                            helpers.setValue(updatedFiles)
                            helpers.setTouched(true)
                            return updatedFiles
                        })
                        break
                    }

                    case FileReaderReadyState.EMPTY: {
                        helpers.setError(errorMessage)
                        break
                    }
                }
            },
            onloadstart() {
                setIsProcessing(true)
                helpers.setError(undefined)
            },
            onloadend() {
                setIsProcessing(false)
            }
        }
    }, [intl, helpers])

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        const items = event.dataTransfer?.items
        const item = items?.[0]

        event.preventDefault()

        if (items.length > 0 && item.kind === FileUploadInputDataTransferKind.FILE) {
            setIsDragging(true)
        }
    }, [])

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const files = (event.target as HTMLInputElement).files

            if (files) {
                Array.from(files).forEach((file) => {
                    getFileReadersWithEventHandlers([file], fileReaderEventHandlers)
                })
            }
        },
        [fileReaderEventHandlers]
    )

    const onDrop = useCallback(
        (event: DragEvent) => {
            const items = event.dataTransfer?.items
            const files = event.dataTransfer?.files
            const itemsOrFiles = files || items

            event.preventDefault()
            setIsDragging(false)

            if (itemsOrFiles) {
                ;[...itemsOrFiles].forEach((item) => {
                    const file = item as File
                    const dataTransferItem = item as DataTransferItem

                    if (dataTransferItem.kind === FileUploadInputDataTransferKind.FILE) {
                        const fileBlob = dataTransferItem.getAsFile()

                        if (fileBlob) {
                            getFileReadersWithEventHandlers([fileBlob], fileReaderEventHandlers)
                        }
                    }

                    if (isFileBlob(file)) {
                        getFileReadersWithEventHandlers([file], fileReaderEventHandlers)
                    }
                })
            }
        },
        [fileReaderEventHandlers]
    )

    const onDragLeave = useCallback(() => {
        setIsDragging(false)
    }, [])
    const onRemoveFile = useCallback(
        (index: number) => {
            setSelectedFiles((prevFiles) => {
                const updatedFiles = [...prevFiles]

                updatedFiles.splice(index, 1)
                helpers.setValue(updatedFiles)

                if (refInput.current) {
                    // Reset the input element to allow re-uploading the same file
                    refInput.current.value = EMPTY_CHAR_SYMBOL
                }

                return updatedFiles
            })
        },
        [helpers, refInput]
    )

    const isInvalid = useMemo<boolean>(() => {
        return meta.touched && (hasFormError || hasFileErrors)
    }, [meta, hasFormError, hasFileErrors])

    const propsFileInput = {
        ...inputProps,
        hidden: true,
        multiple: true,
        onChange,
        ref: refInput,
        disabled: isDisabled,
        accept: inputAcceptedExtensions,
        type: FileUploadInputDataTransferKind.FILE,
        'data-testid': name
    }

    usePreventWindowDragOverAndDrop()

    return (
        <FormControl className="FileUploadInput" key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={labelKey} />
            </FormLabel>
            <Box
                className={dropZoneContainerClassName}
                onDragOver={onDragOver as unknown as DragEventHandler<HTMLDivElement>}
                onDrop={onDrop as unknown as DragEventHandler<HTMLDivElement>}
                onDragLeave={onDragLeave}
                minHeight="90px"
                gap="calc(var(--numeral-ui-primary-spacing)/2)"
                padding="var(--numeral-ui-primary-spacing)"
                marginBottom="var(--numeral-ui-primary-spacing)"
                style={{ opacity: isDragging ? FILE_UPLOAD_ELEMENT_OPACITY : undefined }}>
                {(() => {
                    const titleFontWeight = 500

                    switch (true) {
                        case isProcessing: {
                            const color = 'gray.500'
                            return <FileUploadInputStatusProcessing color={color} fontWeight={titleFontWeight} />
                        }

                        case isSuccessful: {
                            const color = 'green.500'
                            const browserLink = <FileUploadInputBrowseLink color={color} refInput={refInput} />

                            return (
                                <FileUploadInputStatusSuccess
                                    fileCount={selectedFiles.length}
                                    browseLink={browserLink}
                                    color={color}
                                    fontWeight={titleFontWeight}
                                />
                            )
                        }

                        case isDisabled:
                        case isActive:
                        default: {
                            const color = 'gray.500'
                            const linkColor = isDisabled ? color : undefined
                            const disabledLinkOpacity = isDisabled ? FILE_UPLOAD_ELEMENT_OPACITY : undefined
                            const browserLink = <FileUploadInputBrowseLink color={linkColor} refInput={refInput} />

                            return (
                                <FileUploadInputStatusActive
                                    browseLink={browserLink}
                                    formattedAcceptedSize={formattedAcceptedSize}
                                    color={color}
                                    opacity={disabledLinkOpacity}
                                    fontWeight={titleFontWeight}
                                />
                            )
                        }
                    }
                })()}
                <input {...propsFileInput} />
            </Box>
            <If condition={hasSelectedFiles}>
                <FileUploadInputPreview
                    files={selectedFiles}
                    fileErrors={fileErrors}
                    onRemoveFile={onRemoveFile}
                    isDisabled={isDisabled}
                />
            </If>
            <FormErrorMessage>{formError}</FormErrorMessage>
        </FormControl>
    )
}
