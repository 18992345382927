import { StickyFooter } from '@/components'
import { BULK_ACTIONS_FALLBACK_ACTION_KEY } from '@/constants'
import { usePermissions, useQuickFilter } from '@/hooks'
import { getQuickFilterStateValue } from '@/providers'
import { Box, Flex } from '@chakra-ui/react'
import { chain } from 'lodash'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { BulkActionsFooterDefault } from './@components'
import { BulkActionsFooterProps } from './BulkActionsFooter.types'

export const BulkActionsFooter = React.memo(<T,>({ targetObject, selection, actions }: BulkActionsFooterProps<T>) => {
    const [marginBottom, setMarginBottom] = useState(0)
    const { quickFilters, state } = useQuickFilter<T>()
    const { hasPermissions } = usePermissions()

    const activeActionKey = getQuickFilterStateValue<T>(quickFilters, state) ?? BULK_ACTIONS_FALLBACK_ACTION_KEY

    const isActionDisabled = useMemo(() => {
        return (
            !!actions?.[activeActionKey]?.configuration?.permissions?.length &&
            !hasPermissions(actions?.[activeActionKey].configuration.permissions)
        )
    }, [hasPermissions, actions])

    const { ContentComponent, ActionComponent } = useMemo(() => {
        const actionsWrapper = chain(actions)

        const ContentComponent = actionsWrapper
            .get(activeActionKey)
            .get('contentComponent', BulkActionsFooterDefault)
            .value()
        const ActionComponent = actionsWrapper
            .get(activeActionKey)
            .get('actionComponent', ({ children }: PropsWithChildren) => <>{children}</>)
            .value()

        return {
            ContentComponent,
            ActionComponent
        }
    }, [actions, quickFilters, state])

    if (!selection?.size) {
        return null
    }

    return (
        <Box className="BulkActionsFooter" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Flex gap="16px" flex="1" paddingY="16px" justifyContent="space-between">
                    <Flex gap="8px" direction="column">
                        <ContentComponent targetObject={targetObject} selection={selection} />
                    </Flex>
                    <Box margin="auto 0">
                        <ActionComponent targetObject={targetObject} isDisabled={isActionDisabled} />
                    </Box>
                </Flex>
            </StickyFooter>
        </Box>
    )
})
