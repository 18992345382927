import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { usePermissions, useQueryFindByIdPaymentApprovalRule } from '@/hooks'
import { LinkProps, Skeleton, Text, TextProps } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { HTMLChakraProps } from '@chakra-ui/system'
import { DetailPageLink, RestrictedData } from '@/components/@misc'
import { ApiObjectTypeSchema, UuidSchema } from '@webapps/numeral-ui-core'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { ApiObjectTypeExtendedSchema } from '@/schemas'

export interface PaymentApprovalRuleNameProps extends HTMLChakraProps<'a' | 'p'> {
    paymentApprovalRuleId?: string
    asPlainText?: boolean
}

export const PaymentApprovalRuleName: React.FC<PaymentApprovalRuleNameProps> = React.memo(
    ({ paymentApprovalRuleId, asPlainText, ...props }) => {
        const hasValidUUID = useMemo(() => UuidSchema.safeParse(paymentApprovalRuleId).success, [paymentApprovalRuleId])
        const { hasPermission } = usePermissions()
        const hasViewPaymentApprovalRulesPermission = useMemo(() => {
            return hasPermission(PERMISSION.SETTINGS_VIEW_PAYMENT_APPROVAL_RULES)
        }, [hasPermission])
        const query = useQueryFindByIdPaymentApprovalRule(paymentApprovalRuleId, {
            enabled: hasViewPaymentApprovalRulesPermission && hasValidUUID
        })
        const formattedName = useMemo(() => {
            return query.data?.name
        }, [query.data])

        switch (true) {
            case !paymentApprovalRuleId: {
                return <>{EMPTY_VALUE_PLACEHOLDER}</>
            }

            case !hasViewPaymentApprovalRulesPermission: {
                return <RestrictedData />
            }

            case query.isLoading: {
                return <Skeleton height="14px" width="50%" />
            }

            case query.isError:
            case isEmpty(query.data?.name): {
                return <>{EMPTY_VALUE_PLACEHOLDER}</>
            }

            case asPlainText: {
                return (
                    <Text {...(props as TextProps)} as="span" title={formattedName}>
                        {formattedName}
                    </Text>
                )
            }

            default: {
                return (
                    <DetailPageLink
                        {...(props as LinkProps)}
                        objectId={query.data?.id}
                        objectType={ApiObjectTypeExtendedSchema.enum.payment_approval_rule}>
                        {formattedName}
                    </DetailPageLink>
                )
            }
        }
    }
)
