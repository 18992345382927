import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdAPIKey } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import { APIKeyDetailsForm } from './@components'

export const APIKeysDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdAPIKey(uuid)

    return (
        <PageLayout className="APIKeyDetails">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_API_KEYS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.api_key}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <APIKeyDetailsForm data={query?.data} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
