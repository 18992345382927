import { QueryFallback, Table, TableCellShortDate, useTableRowNavigation } from '@/components'
import { APIQueryParamTypes, UseAPIQueryParamsResult, useNavigationRoutes } from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { ApiObjectTypeSchema, Balance, BalancesServiceFindAllQueryOptions, List } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import {
    BALANCE_DOCUMENTATION_LINK,
    BALANCE_TABLE_COLUMN_CUSTOMIZERS,
    BALANCE_TABLE_STATIC_STATE
} from '../Balances.page.const'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { BalanceDateWithWarning, FormattedTextIntl } from '@/components/@misc'

interface BalancesTableProps {
    queryItem: UseInfiniteQueryResult<InfiniteData<List<Balance>>>
    filterBy: UseAPIQueryParamsResult<APIQueryParamTypes.FilterBy, BalancesServiceFindAllQueryOptions>
    isSearching?: boolean
}

export const BalancesTable: React.FC<BalancesTableProps> = ({ queryItem, filterBy, isSearching }) => {
    const { relativePaths } = useNavigationRoutes()
    const data = useMemo(() => queryDataAggregation(queryItem.data), [queryItem])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<Balance>('id', relativePaths.ACCOUNTS.DETAILS)
    const rowCustomizers = useMemo(() => {
        if (filterBy.innerState?.latest) {
            return {
                ...BALANCE_TABLE_COLUMN_CUSTOMIZERS,
                date: {
                    minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
                    size: COLUMN_WIDTH_RATIO.SHORT_DATE,
                    header() {
                        return <FormattedTextIntl messageId="app.table.header.value_date" />
                    },
                    cell(info) {
                        const value = info.getValue()

                        return (
                            <BalanceDateWithWarning value={value}>
                                <TableCellShortDate value={value} />
                            </BalanceDateWithWarning>
                        )
                    }
                }
            }
        }

        return BALANCE_TABLE_COLUMN_CUSTOMIZERS
    }, [filterBy])

    return (
        <QueryFallback
            objectType={ApiObjectTypeSchema.enum.balance}
            isLoading={queryItem.isLoading}
            isSuccess={queryItem.isSuccess}
            isError={queryItem.isError}
            isDataEmpty={isDataEmpty}
            isFilteringOrSearching={filterBy.isActive || isSearching}
            learnMoreLinkUrl={BALANCE_DOCUMENTATION_LINK}>
            <Table<Balance>
                data={data}
                onRowClick={onTableRowClick}
                onScrollToBottom={queryItem.fetchNextPage}
                isLoading={queryItem.hasNextPage}
                state={BALANCE_TABLE_STATIC_STATE}
                columnCustomizers={rowCustomizers}
            />
        </QueryFallback>
    )
}
