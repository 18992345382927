import { ENTITY_DETAIL_CARD_ROW_ACTION_TYPE, EntityDetailCard, EntityDetailCardRowKey } from '@/components'
import { RightArrowIcon } from '@/components/@icons'
import { If } from '@/components/@misc'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { usePage } from '@/hooks'
import { TransactionExcludeConfirmationModal } from '@/pages'
import { RECONCILE_ENTITY_PAGE_CONTEXT } from '@/pages/Common'
import { isEntityUnreconciled } from '@/services'
import { Button, ButtonProps, Stack } from '@chakra-ui/react'
import { ReconciliationStatusSchema, Transaction } from '@webapps/numeral-ui-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { NextUnreconciledTransactionButton } from '../NextUnreconciledTransactionButton/NextUnreconciledTransactionButton'
import {
    TRANSACTION_RECONCILE_HEADER_CARD_ROW_CUSTOMIZERS,
    TRANSACTION_RECONCILE_HEADER_CARD_ROW_KEYS
} from './TransactionReconcileHeaderCard.const'
import { TransactionReconcileHeaderCardProps } from './TransactionReconcileHeaderCard.types'
import {
    getMatchingExpectedPaymentFilterByData,
    getTransactionSearchData
} from './TransactionReconcileHeaderCard.utils'

export const TransactionReconcileHeaderCard: React.FC<TransactionReconcileHeaderCardProps> = ({
    transaction,
    search,
    filterBy
}) => {
    const intl = useIntl()
    const page = usePage<Transaction>()
    const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false)

    const isExcludeTransactionAvailable = useMemo(() => {
        return isEntityUnreconciled(transaction)
    }, [transaction])

    const onOpenTransactionExcludeConfirmationModal = useCallback(() => {
        setIsExcludeModalOpen(true)
    }, [])
    const onCloseTransactionExcludeConfirmationModal = useCallback(() => {
        setIsExcludeModalOpen(false)
    }, [])
    const onClickSearchOrFilterByAction = useCallback(
        (rowKey: EntityDetailCardRowKey<Transaction>, actionType: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE) => {
            switch (actionType) {
                case ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.SEARCH: {
                    const searchData = getTransactionSearchData(transaction, rowKey)

                    if (searchData) {
                        search?.setState({ search: searchData })
                    }
                    break
                }

                case ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY: {
                    const filterByData = getMatchingExpectedPaymentFilterByData(intl, transaction, rowKey)

                    if (filterByData) {
                        filterBy?.setState({ ...filterBy.innerState, ...filterByData })
                    }
                    break
                }
            }
        },
        [transaction, filterBy, search]
    )

    useEffect(() => {
        if (transaction) {
            search?.setState({ search: EMPTY_CHAR_SYMBOL })

            filterBy?.setState({
                reconciliation_status: ReconciliationStatusSchema.enum.unreconciled,
                direction: transaction.direction,
                currency: transaction.currency
            })
            /**
             * In order to re-use the complex logic of the bulk action reconcile entity footer,
             * we set the page state to the current transaction.
             */
            page.setNonPersistedState({ [RECONCILE_ENTITY_PAGE_CONTEXT]: transaction })

            return () => {
                page.resetNonPersistedState()
            }
        }
    }, [transaction])

    const propsButtons: ButtonProps = {
        variant: 'link',
        color: 'numeralAccent.500',
        fontWeight: 'normal'
    }

    return (
        <Stack alignItems="flex-end" gap="var(--numeral-ui-primary-spacing)">
            <NextUnreconciledTransactionButton
                currentTransactionID={transaction?.id}
                propsButton={{ ...propsButtons, rightIcon: <RightArrowIcon marginLeft="4px" /> }}
            />
            <EntityDetailCard<Transaction>
                data={transaction}
                rowKeys={TRANSACTION_RECONCILE_HEADER_CARD_ROW_KEYS}
                rowCustomizers={TRANSACTION_RECONCILE_HEADER_CARD_ROW_CUSTOMIZERS}
                handleRowAction={onClickSearchOrFilterByAction}
            />
            <Button
                {...propsButtons}
                isDisabled={!isExcludeTransactionAvailable}
                onClick={onOpenTransactionExcludeConfirmationModal}>
                <FormattedMessage id="app.accounts.transactions.reconcile.action.exclude_transaction.label" />
            </Button>
            <If condition={isExcludeTransactionAvailable}>
                <TransactionExcludeConfirmationModal
                    uuid={transaction?.id}
                    isOpen={isExcludeModalOpen}
                    onClose={onCloseTransactionExcludeConfirmationModal}
                />
            </If>
        </Stack>
    )
}
