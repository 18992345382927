import { useEffect } from 'react'
import { NavigationType, useLocation, useNavigationType } from 'react-router'

export const ScrollToTop: React.FC = () => {
    const location = useLocation()
    const navigationType = useNavigationType()

    useEffect(() => {
        if (navigationType !== NavigationType.Pop) {
            globalThis.window.scrollTo(0, 0)
        }
    }, [navigationType, location])

    return null
}
