import { CopyToClipboardButton } from '@/components/@misc'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { contactSupport } from '@/utils'
import { Box, Flex, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react'
import * as Sentry from '@sentry/browser'
import { Scope } from '@sentry/react'
import { Extras } from '@sentry/core/build/types'
import { chain } from 'lodash'
import React, { ErrorInfo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types'
import { ERROR_BOUNDARY_ERROR_TEXT_BOX_PROPS } from './ErrorBoundary.const'

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state = {
        eventId: '',
        error: undefined,
        errorInfo: '',
        hasError: false
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.withScope((scope: Scope) => {
            scope.setExtras(errorInfo as unknown as Extras)

            this.setState({
                eventId: Sentry.captureException(error),
                errorInfo: errorInfo.componentStack
            })
        })
    }

    render() {
        const { hasError, errorInfo, eventId, error } = this.state
        const { children } = this.props

        if (hasError) {
            const propsPageHeaderLayout = {
                title: <FormattedMessage id="app.common.error.title" />,
                actions: [
                    {
                        onClick: contactSupport,
                        children: <FormattedMessage id="app.common.actions.contact_us" />
                    }
                ]
            }

            const errorMessage = chain(error as unknown as Error)
                .get('message', '')
                .value()

            return (
                <PageLayout className="ErrorBoundary">
                    <PageHeaderLayout {...propsPageHeaderLayout} />
                    <Box marginY="16px" />
                    <Stack gap="16px">
                        <FormControl>
                            <FormLabel htmlFor="stacktrace">
                                <Flex justifyContent="space-between">
                                    <Text>Stacktrace</Text>
                                    <CopyToClipboardButton value={errorInfo} />
                                </Flex>
                            </FormLabel>
                            <Box {...ERROR_BOUNDARY_ERROR_TEXT_BOX_PROPS} id="stacktrace" height="248px">
                                {errorInfo}
                            </Box>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor={eventId}>
                                <Flex justifyContent="space-between">
                                    <Text>Error message</Text>
                                    <CopyToClipboardButton value={errorMessage} />
                                </Flex>
                            </FormLabel>
                            <Box {...ERROR_BOUNDARY_ERROR_TEXT_BOX_PROPS} id={eventId} as="pre">
                                {errorMessage}
                            </Box>
                        </FormControl>
                    </Stack>
                </PageLayout>
            )
        }

        return children
    }
}
