import { useQueryFindByIdIncomingPayment } from '@/hooks'
import { ReconciliationsPage } from '@/pages'
import { IncomingPayment, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { useParams } from 'react-router'

export const IncomingPaymentsReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdIncomingPayment(uuid)

    return (
        <ReconciliationsPage<IncomingPayment> paymentType={PaymentTypeSchema.enum.incoming_payment} queryItem={query} />
    )
}
