import { Flex, Spinner, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'
import { useRef } from 'react'

export const FileUploadInputStatusProcessing: React.FC<FileUploadInputStatusProps> = ({ color, fontWeight }) => {
    const iconDimension = useRef('33px').current

    return (
        <Flex direction="column" alignItems="center" justifyContent="center" justifySelf="center">
            <Spinner width={iconDimension} height={iconDimension} color={color} />
            <Text color={color} fontWeight={fontWeight}>
                <FormattedMessage id="app.common.loading" />
            </Text>
        </Flex>
    )
}
