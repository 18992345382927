import { addMonths, differenceInDays } from 'date-fns'

//@description - Adjusting for different month lengths and leap years.
const LOOK_BEHIND_SIX_MONTH_OFFSET_DYNAMIC = (() => {
    const date = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))

    return differenceInDays(addMonths(date, 6), date)
})()

export const WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES = {
    NO_DAY: 0,
    ONE_DAY: 1,
    SEVEN_DAYS: 7,
    SIX_MONTHS_AS_DAYS: LOOK_BEHIND_SIX_MONTH_OFFSET_DYNAMIC
} as const
