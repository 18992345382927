import { Box, Button, Divider, Flex, FormErrorMessage, Stack, Text } from '@chakra-ui/react'
import { isLastIndex } from '@/utils'
import { If } from '@/components/@misc'
import React from 'react'
import { CrossIcon } from '@/components/@icons'
import { fileSizeFormatter } from '@/utils/@formatters'
import { useIntl } from 'react-intl'
import { chain } from 'lodash'

interface FileUploadInputPreviewProps {
    files?: File[]
    fileErrors?: string[]
    isDisabled?: boolean

    onRemoveFile(index: number): void
}

export const FileUploadInputPreview: React.FC<FileUploadInputPreviewProps> = ({
    files,
    fileErrors,
    onRemoveFile,
    isDisabled
}) => {
    const intl = useIntl()
    return (
        <Stack marginTop="var(--numeral-ui-primary-spacing)">
            <Box borderWidth="1px" marginBottom="calc(var(--numeral-ui-primary-spacing)/2)">
                <Stack
                    gap="calc(var(--numeral-ui-primary-spacing)/2)"
                    padding="calc(var(--numeral-ui-primary-spacing)/2)"
                    paddingLeft="var(--numeral-ui-primary-spacing)">
                    {chain(files)
                        .map((file, index, array) => {
                            const hasLastIndex = isLastIndex(index, array)
                            const hasFileError = globalThis.Boolean(fileErrors?.[index])
                            const formattedFileSize = fileSizeFormatter(file.size)
                            const formattedRemoveFileLabel = intl.formatMessage({
                                id: 'app.common.form.input.file.actions.remove'
                            })

                            return (
                                <Stack key={`${file.name}-${index}`}>
                                    <Flex justify="space-between" align="center">
                                        <Stack gap="calc(var(--numeral-ui-primary-spacing)/4)">
                                            <Flex gap="var(--numeral-ui-primary-spacing)" fontWeight="400">
                                                <Text aria-label={file.name} title={file.name}>
                                                    {file.name}
                                                </Text>
                                                <Text
                                                    aria-label={formattedFileSize}
                                                    title={formattedFileSize}
                                                    color="gray.500">
                                                    {formattedFileSize}
                                                </Text>
                                            </Flex>
                                            <If condition={hasFileError}>
                                                <FormErrorMessage>{fileErrors?.[index]}</FormErrorMessage>
                                            </If>
                                        </Stack>
                                        <Button
                                            aria-label={formattedRemoveFileLabel}
                                            title={formattedRemoveFileLabel}
                                            onClick={() => onRemoveFile(index)}
                                            isDisabled={isDisabled}
                                            padding="unset"
                                            margin="unset"
                                            variant="ghost"
                                            _hover={{
                                                backgroundColor: 'gray.50'
                                            }}>
                                            <CrossIcon />
                                        </Button>
                                    </Flex>
                                    <If condition={!hasLastIndex}>
                                        <Divider />
                                    </If>
                                </Stack>
                            )
                        })
                        .value()}
                </Stack>
            </Box>
        </Stack>
    )
}
