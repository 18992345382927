import { ExtendedRouteObject, isCurrentLocationPathActive } from '@/providers'
import { Button, ButtonProps } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'

interface TabBarNavigationItemProps {
    route: ExtendedRouteObject
    onClick(...args: any[]): void
}

export const TabBarNavigationItem: React.FC<TabBarNavigationItemProps> = ({ route, onClick }) => {
    const intl = useIntl()
    const location = useLocation()

    const onClickNavigationItem = useCallback(() => {
        onClick(route.path)
    }, [route, onClick])
    const title = intl.formatMessage({ id: route.title })
    const propsButton = useMemo<ButtonProps>(() => {
        const isActive = isCurrentLocationPathActive(location.pathname, route.path || `/${route?.path}`)
        const propsDynamic = isActive
            ? {
                  variant: 'solidAlternative'
              }
            : { variant: 'ghost', color: 'gray.500' }
        const propsCommon = {
            disabled: route?.configuration?.isDisabled,
            onClick: onClickNavigationItem,
            fontWeight: 500,
            marginX: '8px'
        }
        return {
            ...propsDynamic,
            ...propsCommon
        }
    }, [location, route, onClickNavigationItem])

    return (
        <Button
            {...propsButton}
            marginStart="0"
            onClick={onClickNavigationItem}
            disabled={route?.configuration?.isDisabled}
            data-testid="tab-bar-navigation-item">
            {title}
        </Button>
    )
}
