import { blobToTextRequestInterceptor, removeNilHeadersRequestInterceptor } from '@/services/@requestInterceptors'
import { isEmptyFileSize } from 'src/utils/@validators/files'
import {
    BaseHttpClientService,
    extractResponseData,
    PaymentOrder,
    ServicesConfiguration
} from '@webapps/numeral-ui-core'
import { UPLOAD_PAYMENT_FILE_EMPTY_CONTENT_ERROR_MESSAGE } from './Uploader.service.const'
import { UploaderCustomHeaders, UploadFileData, UploadFileResponse } from './Uploader.service.types'

export class UploaderService extends BaseHttpClientService {
    get url() {
        return '/v1/uploader'
    }

    get headers() {
        return {
            'Content-type': 'application/xml'
        }
    }

    constructor(configuration?: ServicesConfiguration) {
        super(configuration)
    }

    async uploadFile(data: UploadFileData<PaymentOrder>, config?: ServicesConfiguration): Promise<UploadFileResponse> {
        if (isEmptyFileSize(data?.file?.size)) {
            /**
             * @description
             * The axios http client explicitly ignores `Content-Type` header value if the payload is empty.
             */
            return Promise.reject(UPLOAD_PAYMENT_FILE_EMPTY_CONTENT_ERROR_MESSAGE)
        }

        const requestInterceptorsManager = this.getRequestInterceptorsManager()

        requestInterceptorsManager.use(blobToTextRequestInterceptor)
        requestInterceptorsManager.use(removeNilHeadersRequestInterceptor)

        return this.httpClient
            .post<UploadFileResponse>(`${this.url}/connected_accounts/${data.connected_account_id}/file`, data.file, {
                ...config,
                transformRequest: [
                    /**
                     * @description
                     * No implicit transformation has to be done for this request.
                     */
                ],
                headers: {
                    ...this.headers,
                    [UploaderCustomHeaders.NumeralPaymentType]: data?.type,
                    [UploaderCustomHeaders.NumeralPaymentDirection]: data?.direction,
                    [UploaderCustomHeaders.NumeralPaymentRetryRuleId]: data?.retry_details?.payment_retry_rule_id,
                    [UploaderCustomHeaders.NumeralPaymentTreasuryOption]: data?.treasury_option,
                    [UploaderCustomHeaders.NumeralFileName]: data.file?.name
                }
            })
            .then(extractResponseData)
    }
}
