import { COMMA_SYMBOL, DOT_SYMBOL } from '@/constants'
import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { amountValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel, Input, Tooltip } from '@chakra-ui/react'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { toString, noop } from 'lodash'
import { useCallback, useMemo } from 'react'
import { IMask, IMaskInput, ReactMaskProps } from 'react-imask'
import { FormattedMessage, useIntl } from 'react-intl'
import { getAmountInputPlaceholder, getAmountScale } from './AmountInput.utils'
import { If } from '@/components/@misc'

interface AmountInputProps extends Omit<FormikInput, 'value'> {
    value?: string
    currency?: CurrencyCode
}

export const AmountInput: React.FC<AmountInputProps> = ({
    name,
    value,
    currency,
    customPlaceholderKey,
    isErrorHidden,
    validator = amountValidator,
    isLabelDisplayed = true,
    isRequired = false,
    ...inputProps
}) => {
    const intl = useIntl()
    const { values } = useFormikContext<{ currency?: CurrencyCode }>()
    const [field, meta, helpers] = useCustomFormikField<string>({
        name,
        value,
        validate: validator?.(intl, { isRequired, name }, values)
    })
    const selectedCurrency: CurrencyCode | undefined = useMemo(() => {
        return currency || values['currency']
    }, [currency, values['currency']])

    const placeholder = useMemo<string>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }

        return getAmountInputPlaceholder(intl, selectedCurrency, inputProps.placeholder)
    }, [customPlaceholderKey, intl, selectedCurrency, inputProps.placeholder])

    const tooltip = useMemo<string>(() => {
        return intl.formatMessage({ id: 'app.common.form.input.amount.tooltip.content' })
    }, [intl])

    const isDisabled = useMemo<boolean>(() => {
        return inputProps.isDisabled || !selectedCurrency
    }, [inputProps, selectedCurrency])
    const isInvalid = useMemo(() => {
        return !isErrorHidden && meta?.touched && !!meta?.error
    }, [isErrorHidden, meta])
    const onChange = useCallback(
        (value: string) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps.onChange]
    )

    const IMaskInputElement = useCallback(
        ({ value, ...rest }: ReactMaskProps<any>) => {
            const amountScale = getAmountScale(selectedCurrency)
            const amountAsString = toString(value)
            return (
                <IMaskInput
                    {...rest}
                    // Specific IMaskInput props
                    value={amountAsString}
                    scale={amountScale}
                    mask={IMask.MaskedNumber}
                    thousandsSeparator={COMMA_SYMBOL}
                    radix={DOT_SYMBOL}
                    mapToRadix={[COMMA_SYMBOL, DOT_SYMBOL]}
                    unmask={true}
                />
            )
        },
        [selectedCurrency]
    )

    return (
        <Tooltip key={name} isDisabled={!isDisabled} label={tooltip} placement="top" hasArrow={true}>
            <FormControl isInvalid={isInvalid}>
                <If condition={isLabelDisplayed}>
                    <FormLabel htmlFor={name}>
                        <FormattedMessage id={`app.common.form.input.${name}.label`} />
                    </FormLabel>
                </If>
                <Input
                    as={IMaskInputElement} // Scale must be provided here, otherwise it gets intercepted by the Chakra Input
                    {...field}
                    {...inputProps}
                    min={0}
                    id={name}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    isRequired={isRequired}
                    onChange={noop}
                    onAccept={onChange}
                />
                <FormErrorMessage>{meta?.error}</FormErrorMessage>
            </FormControl>
        </Tooltip>
    )
}
