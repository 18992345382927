import { chain } from 'lodash'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import {
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Stack,
    Tag,
    Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { PaymentApprovalRuleApprover } from '@/components/@misc'
import React, { ReactNode } from 'react'
import { Nullable } from '@/types'
import { PaymentApprovalRule } from '@webapps/numeral-ui-core'

interface PaymentApprovalRuleStepsProps {
    paymentApprovalRule?: Nullable<PaymentApprovalRule>
}

export const PaymentApprovalRuleSteps: React.FC<PaymentApprovalRuleStepsProps> = React.memo(
    ({ paymentApprovalRule }) => {
        const stepsWrapper = chain(paymentApprovalRule).get('steps')

        if (stepsWrapper.isEmpty().value()) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        return stepsWrapper
            .flatten()
            .map((subStep, index) => {
                const subStepWrapper = chain(subStep)
                const subStepApproversWrapper = subStepWrapper.get('approvers.values')
                const subStepApproverTypeWrapper = subStepWrapper.get('approvers.type')

                const messageId = subStep.self_approval_allowed
                    ? 'app.settings.payment_approval_rules.details.steps.self_approval_allowed'
                    : 'app.settings.payment_approval_rules.details.steps.no_self_approval_allowed'

                const firstValueWrapper = subStepApproversWrapper.head()
                const hasMoreOfTheSameType = subStepApproversWrapper.size().gt(1).value()

                let popover: ReactNode = null

                if (hasMoreOfTheSameType) {
                    const numberOfValuesLeftInCriteria = subStepApproversWrapper.tail().size().value()

                    popover = (
                        <>
                            {SPACE_SYMBOL}
                            <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.functions.or.label" />
                            {SPACE_SYMBOL}
                            <Popover
                                returnFocusOnClose={true}
                                autoFocus={false}
                                closeOnBlur={true}
                                closeOnEsc={true}
                                isLazy={true}
                                placement="top"
                                trigger="hover">
                                <PopoverTrigger>
                                    <Tag
                                        variant="subtle"
                                        flexShrink="0"
                                        backgroundColor="gray.100"
                                        cursor="pointer"
                                        size="sm">
                                        <FormattedMessage
                                            id="app.common.pluralization.label"
                                            values={{
                                                count: numberOfValuesLeftInCriteria,
                                                singular: `${numberOfValuesLeftInCriteria} other`
                                            }}
                                        />
                                    </Tag>
                                </PopoverTrigger>
                                <Portal>
                                    <PopoverContent maxWidth="400px">
                                        <PopoverArrow />
                                        <PopoverHeader>
                                            {SPACE_SYMBOL}
                                            <FormattedMessage
                                                id="app.common.pluralization.label"
                                                values={{
                                                    count: numberOfValuesLeftInCriteria,
                                                    singular: `${numberOfValuesLeftInCriteria} other`
                                                }}
                                            />
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <Stack padding="calc(--numeral-ui-primary-spacing/2)">
                                                {subStepApproversWrapper
                                                    .tail()
                                                    .map((value, index) => (
                                                        <PaymentApprovalRuleApprover
                                                            key={index}
                                                            approverType={subStepApproverTypeWrapper.value()}
                                                            approverId={value}
                                                        />
                                                    ))
                                                    .value()}
                                            </Stack>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Portal>
                            </Popover>
                            {SPACE_SYMBOL}
                        </>
                    )
                }

                const formattedNumberOfApprovals = (
                    <FormattedMessage
                        id="app.common.pluralization.label"
                        values={{
                            count: subStep.approvers?.number,
                            singular: `${subStep.approvers?.number} approval`
                        }}
                    />
                )

                const formattedApprovers = (
                    <Text noOfLines={1}>
                        <PaymentApprovalRuleApprover
                            approverType={subStepApproverTypeWrapper.value()}
                            approverId={firstValueWrapper.value()}
                        />
                        {popover}
                    </Text>
                )

                return (
                    <Flex flexWrap="wrap" whiteSpace="break-spaces" key={index}>
                        <FormattedMessage
                            id={messageId}
                            values={{
                                formattedNumberOfApprovals,
                                formattedApprovers
                            }}
                        />
                    </Flex>
                )
            })
            .value()
    }
)
