import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { FilterIcon, SearchIcon } from '../@icons'
import { ENTITY_DETAIL_CARD_ROW_ACTION_TYPE } from './EntityDetailCard.const'
import {
    EntityDetailCardGeneratedRow,
    EntityDetailCardRowCustomizers,
    EntityDetailCardRowKey
} from './EntityDetailCard.types'

export function getEntityDetailCardGeneratedRows<T>(
    data?: T,
    rowKeys?: EntityDetailCardRowKey<T>[],
    rowCustomizers?: EntityDetailCardRowCustomizers<T>
): EntityDetailCardGeneratedRow<T>[] {
    if (!rowKeys?.length) {
        return []
    }

    return rowKeys.map((rowKey) => {
        const defaultLabel = (
            <FormattedMessage
                id={`app.page.details.fields.${rowKey as string}.label`}
                defaultMessage={prettyPrintCasedWords(rowKey as string)}
            />
        )
        const defaultCell = <> {data?.[rowKey as keyof T] ? data?.[rowKey as keyof T] : EMPTY_VALUE_PLACEHOLDER} </>

        const customLabel = !!data && rowCustomizers?.[rowKey as keyof T]?.label?.(data)
        const customCell = !!data && rowCustomizers?.[rowKey as keyof T]?.cell?.(data)

        const actionType = rowCustomizers?.[rowKey as keyof T]?.action?.type
        const isActionHidden = !!data && rowCustomizers?.[rowKey as keyof T]?.action?.isHidden?.(data)
        const isActionDisabled = !!data && rowCustomizers?.[rowKey as keyof T]?.action?.isDisabled?.(data)

        return {
            key: rowKey,
            label: customLabel || defaultLabel,
            cell: customCell || defaultCell,
            action: {
                type: actionType,
                isHidden: isActionHidden ?? false,
                isDisabled: isActionDisabled ?? false
            }
        }
    })
}

export function getEntityDetailCardRowActionIcon(actionType?: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE): ReactNode {
    switch (actionType) {
        case ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.SEARCH: {
            return <SearchIcon />
        }
        case ENTITY_DETAIL_CARD_ROW_ACTION_TYPE.FILTER_BY: {
            return <FilterIcon />
        }
    }
}
