import { QueryFallback, RestrictedSection, Table } from '@/components'
import { useQueryFindAllAuditTrails } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { AuditTrail, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import { AUDIT_TRAIL_TABLE_COLUMN_CUSTOMIZERS } from './AuditTrail.page.const'
import { AuditTrailPageProps } from './AuditTrail.page.types'
import {
    getAuditTrailAPIQueryParams,
    getAuditTrailEmptyStateDescriptionKey,
    getAuditTrailTableStaticState
} from './AuditTrail.page.utils'

export const AuditTrailPage: React.FC<AuditTrailPageProps> = ({ filterBy }) => {
    const { uuid } = useParams()
    const location = useLocation()
    const params = useMemo(() => {
        return getAuditTrailAPIQueryParams(filterBy, uuid)
    }, [location, uuid])
    const query = useQueryFindAllAuditTrails(params)
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const tableState = useMemo(() => {
        return getAuditTrailTableStaticState(filterBy)
    }, [filterBy])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyStateCustomDescriptionKey = useMemo<string | undefined>(() => {
        return getAuditTrailEmptyStateDescriptionKey(filterBy)
    }, [filterBy])

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_AUDIT_TRAILS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.audit_trail}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    customEmptyStateDescriptionKey={emptyStateCustomDescriptionKey}>
                    <Table<AuditTrail>
                        data={data}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        state={tableState}
                        columnCustomizers={AUDIT_TRAIL_TABLE_COLUMN_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
