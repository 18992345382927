import { Button } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { MainNavigationProcessPaymentsConfirmationModal } from './MainNavigationProcessPaymentsConfirmationModal'

export const MainNavigationProcessPayments: React.FC = () => {
    const [isProcessPaymentsConfirmationModalModalOpen, setIsProcessPaymentsConfirmationModalOpen] = useState(false)
    const onOpenProcessPaymentsConfirmationModal = useCallback(() => {
        setIsProcessPaymentsConfirmationModalOpen(true)
    }, [])
    const onCloseProcessPaymentsConfirmationModal = useCallback(() => {
        setIsProcessPaymentsConfirmationModalOpen(false)
    }, [])

    return (
        <>
            <Button
                onClick={onOpenProcessPaymentsConfirmationModal}
                isDisabled={isProcessPaymentsConfirmationModalModalOpen}
                margin="var(--numeral-ui-primary-spacing)"
                marginTop="0px">
                <FormattedMessage id="app.payments.process_payments.actions.process.label" />
            </Button>
            <MainNavigationProcessPaymentsConfirmationModal
                isOpen={isProcessPaymentsConfirmationModalModalOpen}
                onClose={onCloseProcessPaymentsConfirmationModal}
            />
        </>
    )
}
