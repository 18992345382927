import { addDays, differenceInMonths } from 'date-fns'

/**
 * @description
 * Converts a number of days into months using `date-fns`.
 * - buffer day to account for edge cases in month boundary calculations (daylight savings, leap years, etc.)
 * @returns
 * The number of months between the start date and the calculated end date.
 */
export function daysToMonths(startDate: Date, days: number): number {
    const MONTH_BOUNDARY_BUFFER_DAYS = 1

    const endDate = addDays(startDate, days + MONTH_BOUNDARY_BUFFER_DAYS)
    return differenceInMonths(endDate, startDate)
}
