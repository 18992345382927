import { QueryFallback, RestrictedSection, Table, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import {
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllReconciliations
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { useReconciliation } from '@/pages/Common/ReconcileEntity/@hooks'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { ENTITY_FEATURE, isCreateReconciliationAvailable, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, Reconciliation, UuidSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router'
import { ReconciliationComputedStatus } from './@components'
import { ReconciliationsListPageProps } from './Reconciliations.page.types'
import {
    getActiveOnlyReconciliationQueryOption,
    getReconciliationAPIQueryParamsForAnEntityType,
    getReconciliationsContentButtonLabel,
    getReconciliationsEmptyStateDescription,
    getReconciliationsTableStateByEntityType,
    getReconciliationTableColumnCustomizers
} from './Reconciliations.page.utils'

export function ReconciliationsPage<T>({ paymentType, transactionId, queryItem }: ReconciliationsListPageProps<T>) {
    const intl = useIntl()
    const { uuid } = useParams()
    const location = useLocation()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { hasPermission } = usePermissions()
    const reconciliation = useReconciliation()
    const { relativePaths } = useNavigationRoutes()

    const hasValidUUID = useMemo(() => UuidSchema.safeParse(uuid).success, [uuid])

    const APIQueryParams = useMemo(() => {
        const partialAPIQueryParams = getReconciliationAPIQueryParamsForAnEntityType(
            {
                payment_id: uuid,
                payment_type: paymentType,
                transaction_id: transactionId
            },
            queryItem.data
        )

        return getActiveOnlyReconciliationQueryOption(partialAPIQueryParams)
    }, [queryItem, uuid, paymentType, transactionId])

    const query = useQueryFindAllReconciliations(APIQueryParams, { enabled: hasValidUUID })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const columnCustomizers = useMemo<TableColumnCustomizers<Reconciliation>>(() => {
        return getReconciliationTableColumnCustomizers(queryItem.data)
    }, [queryItem])

    const customEmptyStateDescription = useMemo<string>(
        () => getReconciliationsEmptyStateDescription(queryItem.data),
        [queryItem.data]
    )

    const emptyStateButtonProps = useMemo(() => {
        const canCreateReconciliation = isCreateReconciliationAvailable(queryItem.data?.reconciliation_status)
        const hasReconciliationsConfigurePermission = hasPermission(
            PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS
        )

        if (!canCreateReconciliation || !hasReconciliationsConfigurePermission) {
            return
        }

        return {
            children: getReconciliationsContentButtonLabel(intl, queryItem.data),
            onClick() {
                switch (queryItem.data?.object) {
                    case ApiObjectTypeSchema.enum.transaction: {
                        const { ACCOUNTS } = relativePaths
                        const path = updateUUIDRelativeActionInPathname(location.pathname, ACCOUNTS.RECONCILE)

                        navigateWithLegalEntityID(path)
                        break
                    }

                    default: {
                        reconciliation.onStartReconciliation(ApiObjectTypeSchema.enum.transaction, queryItem.data)
                    }
                }
            }
        }
    }, [intl, queryItem, reconciliation, navigateWithLegalEntityID])
    const tableState = useMemo<TableStateWithEntity<Reconciliation>>(() => {
        return getReconciliationsTableStateByEntityType(queryItem.data)
    }, [queryItem])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.RECONCILIATIONS_VIEW_RECONCILIATIONS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.reconciliation}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={!hasValidUUID || query.isError}
                    isDataEmpty={isDataEmpty}
                    customEmptyStateDescriptionKey={customEmptyStateDescription}
                    emptyStateButtonProps={emptyStateButtonProps}>
                    <Table<Reconciliation>
                        data={data}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={columnCustomizers}
                        state={tableState}
                        hasRowOptionsEnabled={hasPermission(PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS)}
                    />
                    {queryItem.data?.id && <ReconciliationComputedStatus item={queryItem.data} />}
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
