import { getFormButtonLoadingLeftIcon } from '@/components'
import { useBulkActions } from '@/hooks'
import { Button, Flex } from '@chakra-ui/react'
import { ApiObjectType, RequestActionTypes } from '@webapps/numeral-ui-core'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaymentOrderPendingApprovalBulkActionsConfirmationModal } from './PaymentOrderPendingApprovalBulkActionsConfirmationModal'

export const PaymentOrderPendingApprovalBulkActionsFooterActions: React.FC<{
    targetObject: ApiObjectType
    isDisabled?: boolean
}> = ({ targetObject, isDisabled }) => {
    const { isLoading } = useBulkActions()
    const [openedModals, setOpenedModals] = useState<any>(Object.create(null))
    const onOpen = useCallback((action: RequestActionTypes) => {
        setOpenedModals({ [action]: true })
    }, [])
    const onClose = useCallback((action: RequestActionTypes) => {
        setOpenedModals({ [action]: false })
    }, [])

    const isCancelLoading = useMemo(
        () => isLoading && RequestActionTypes.CANCEL in openedModals,
        [isLoading, openedModals]
    )
    const isApproveLoading = useMemo(
        () => isLoading && RequestActionTypes.APPROVE in openedModals,
        [isLoading, openedModals]
    )

    return (
        <>
            <Flex gap="16px">
                <Button
                    onClick={() => onOpen(RequestActionTypes.CANCEL)}
                    leftIcon={getFormButtonLoadingLeftIcon(isCancelLoading)}
                    isDisabled={isDisabled || isLoading}
                    colorScheme="gray"
                    variant="outline"
                    backgroundColor="white">
                    <FormattedMessage id="app.payments.payment_orders.approval.actions.cancel.label" />
                </Button>
                <Button
                    onClick={() => onOpen(RequestActionTypes.APPROVE)}
                    leftIcon={getFormButtonLoadingLeftIcon(isApproveLoading)}
                    isDisabled={isDisabled || isLoading}
                    variant="solid">
                    <FormattedMessage id="app.payments.payment_orders.approval.actions.approve.label" />
                </Button>
            </Flex>
            <PaymentOrderPendingApprovalBulkActionsConfirmationModal
                targetObject={targetObject}
                action={RequestActionTypes.CANCEL}
                isOpen={openedModals[RequestActionTypes.CANCEL]}
                onClose={onClose}
            />
            <PaymentOrderPendingApprovalBulkActionsConfirmationModal
                targetObject={targetObject}
                action={RequestActionTypes.APPROVE}
                isOpen={openedModals[RequestActionTypes.APPROVE]}
                onClose={onClose}
            />
        </>
    )
}
