import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, UPLOADER_REJECTIONS, UploadFileData, UploadFileResponse } from '@/services'
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { APIError } from '@/types'

export type ItemStatus<TData> = {
    isPending: boolean
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    data?: TData
    error?: AxiosError<APIError<Error>>
}

export type ItemsStatus<TData> = Record<string, ItemStatus<TData>>

export type UseConcurrentMutationResult<TData, TError, TVariables extends { id: string }[]> = UseMutationResult<
    TData[],
    TError,
    TVariables
> & {
    itemsStatus: ItemsStatus<TData>
}

/**
 * @todo
 * Move when the hook is consumed by other components
 * @type
 * Utility
 * @description
 * Hook for handling concurrent mutations with individual item status tracking
 */
export function useConcurrentMutation<TData, TError, TVariables extends { id: string }[]>(
    mutationFn: (item: TVariables[number]) => Promise<TData>,
    options?: Omit<UseMutationOptions<TData[], TError, TVariables>, 'mutationFn'>
): UseConcurrentMutationResult<TData, TError, TVariables> {
    const [itemsStatus, setItemsStatus] = useState<ItemsStatus<TData>>(Object.create(null))
    const updateItemStatus = useCallback((id: string, status: Partial<ItemStatus<TData>>) => {
        setItemsStatus((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                ...status
            }
        }))
    }, [])

    const mutation = useMutation<TData[], TError, TVariables>({
        ...options,
        async mutationFn(items) {
            const results: TData[] = []
            const errors: AxiosError[] = []
            const initialStatus = items.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: {
                        isPending: true,
                        isLoading: false,
                        isSuccess: false,
                        isError: false
                    }
                }),
                Object.create(null) as ItemsStatus<TData>
            )

            setItemsStatus(initialStatus)

            const responses = await Promise.allSettled(
                items.map(async (item) => {
                    try {
                        updateItemStatus(item.id, {
                            isPending: false,
                            isLoading: true
                        })

                        const result = await mutationFn(item)

                        updateItemStatus(item.id, {
                            isLoading: false,
                            isSuccess: true,
                            data: result
                        })

                        results.push(result)
                    } catch (error) {
                        errors.push(error as AxiosError<APIError<Error>>)

                        updateItemStatus(item.id, {
                            isLoading: false,
                            isError: true,
                            error: error as AxiosError<APIError<Error>>
                        })
                    }
                })
            )

            const rejections = responses?.filter(({ status }) => status === 'rejected')

            switch (true) {
                case rejections?.length === results.length: {
                    return Promise.reject(UPLOADER_REJECTIONS.FULL_REJECTION)
                }

                case errors.length > 0:
                case rejections?.length > 0: {
                    return Promise.reject(UPLOADER_REJECTIONS.PARTIAL_REJECTION)
                }
            }

            return results
        }
    })

    return {
        ...mutation,
        itemsStatus
    }
}

export function useMutationUploadFilesConcurrent(
    options?: Omit<UseMutationOptions<UploadFileResponse[], string, UploadFileData[]>, 'mutationFn'>
) {
    const { selectedUserAccess } = useAuth()
    const { uploaderService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useConcurrentMutation<UploadFileResponse, string, UploadFileData[]>(
        (item) => uploaderService.uploadFile(item, { params: paramsWithUserAccess }),
        {
            ...options,
            onSuccess(data, variables, context) {
                options?.onSuccess?.(data, variables, context)
                queryClient.invalidateQueries({ queryKey: [uploaderService.url] })
            }
        }
    )
}
