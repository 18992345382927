import {
    BANK_ACCOUNT_DEFAULT_LAST_DIGITS_NB,
    BANK_ACCOUNT_UK_LAST_DIGITS_NB,
    BULLET_SEPARATOR,
    SPACE_SYMBOL
} from '@/constants'
import { truncateMiddle } from '@/utils'
import { UKBankAccountNumberSchema } from '@/utils/@validators'
import { ConnectedAccount } from '@webapps/numeral-ui-core'

export function connectedAccountNameFormatter(item?: Partial<ConnectedAccount>, showAccountNumber?: boolean): string {
    const separatorWithSpaces = `${SPACE_SYMBOL}${BULLET_SEPARATOR}${SPACE_SYMBOL}`

    let label = ''
    const name = item?.name
    const bankName = item?.bank_name
    const accountNumber = item?.account_number

    if (bankName) {
        label += bankName
    }

    if (name) {
        label += `${separatorWithSpaces}${name}`
    }

    if (showAccountNumber && accountNumber) {
        const isUKBankAccountNumber = UKBankAccountNumberSchema.safeParse(accountNumber).success
        const numberOfChars = isUKBankAccountNumber
            ? BANK_ACCOUNT_UK_LAST_DIGITS_NB
            : BANK_ACCOUNT_DEFAULT_LAST_DIGITS_NB

        label += `${separatorWithSpaces}${truncateMiddle(item.account_number, numberOfChars, numberOfChars)}`
    }

    return label
}
