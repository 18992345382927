import { useConfiguration, useNavigationRoutes, useServices } from '@/hooks'
import { AUTH_QUERY_PARAMS } from '@/providers'
import { STORAGE_KEYS, StorageService } from '@/services'
import { getUrlParameter } from '@/utils'
import { useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { generateAccessTokenOnce } from './Callback.page.utils'

type CallbackPageProps = {
    isInitialLogin?: boolean
}

/**
 * The default behavior of the callback component is to log the user in the default env (primaryEnvironment)
 *
 * In a redirection context with double login, the user is first
 * If the login is successful we then process to redirect to the default login flow
 */
export const CallbackPage: React.FC<CallbackPageProps> = ({ isInitialLogin = true }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { authenticationService } = useServices()
    const { apiEnvironment } = useConfiguration()
    const { paths } = useNavigationRoutes()
    const urlCode = useRef<string>(getUrlParameter(AUTH_QUERY_PARAMS.CODE)).current
    const urlState = useRef<string>(getUrlParameter(AUTH_QUERY_PARAMS.STATE)).current
    const navigationOptions = useRef({ replace: true }).current

    const baseApiUrlOverride = useMemo(() => {
        if (!isInitialLogin && apiEnvironment.secondary) {
            return apiEnvironment.secondary.url
        }
    }, [isInitialLogin, apiEnvironment])

    useEffect(() => {
        generateAccessTokenOnce(authenticationService, urlCode, urlState, { baseURL: baseApiUrlOverride })
            .then(() => {
                if (isInitialLogin && apiEnvironment.secondary) {
                    authenticationService.login(apiEnvironment.secondary.url)
                } else {
                    const redirectionUrl = StorageService.getItem(STORAGE_KEYS.POST_LOGIN_REDIRECTION_URL)
                    if (redirectionUrl) {
                        navigate(redirectionUrl)
                        StorageService.removeItem(STORAGE_KEYS.POST_LOGIN_REDIRECTION_URL)
                    } else {
                        navigate(paths.ROOT, navigationOptions)
                    }
                }
            })
            .catch(() => {
                navigate(paths.ACCOUNT.ERROR, {
                    state: {
                        description: intl.formatMessage({ id: 'app.account.login_error.no_access_credentials.content' })
                    }
                })
            })
    }, [])

    return null
}
