import { QueryFallback, RestrictedSection, Table, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryFindAllInternalAccounts } from '@/hooks'
import { PageLayout } from '@/layouts'
import { INTERNAL_ACCOUNT_DOCUMENTATION_LINK } from '@/pages/Counterparties/InternalAccounts/InternalAccounts.page.const'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, InternalAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import {
    CONNECTED_ACCOUNT_INTERNAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS,
    CONNECTED_ACCOUNT_INTERNAL_ACCOUNTS_TABLE_STATIC_STATE
} from './ConnectedAccountInternalAccounts.page.const'

export const ConnectedAccountInternalAccountsPage = () => {
    const { uuid } = useParams()
    const { paths } = useNavigationRoutes()

    const query = useQueryFindAllInternalAccounts({ connected_account_id: uuid })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<InternalAccount>(
        'id',
        `${paths.COUNTERPARTIES.INTERNAL_ACCOUNTS}`,
        true
    )

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.internal_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    customEmptyStateDescriptionKey="app.settings.connected_accounts.internal_accounts.empty_state.description"
                    learnMoreLinkUrl={INTERNAL_ACCOUNT_DOCUMENTATION_LINK}>
                    <Table<InternalAccount>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={CONNECTED_ACCOUNT_INTERNAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS}
                        state={CONNECTED_ACCOUNT_INTERNAL_ACCOUNTS_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
