import { EntityDetailCardRowCustomizers } from '@/components'
import { DEFAULT_DATE_TEMPLATE_FORMAT } from '@/constants'
import { convertToMajorUnitAmount } from '@/utils'
import { ExpectedPaymentsServiceFindAllQueryOptions, Transaction } from '@webapps/numeral-ui-core'
import { format } from 'date-fns'
import { isNumber, isString } from 'lodash'
import { IntlShape } from 'react-intl'

export function getTransactionSearchData(
    transaction?: Transaction,
    key?: keyof EntityDetailCardRowCustomizers<Transaction>
): string | undefined {
    if (!transaction || !key) {
        return
    }

    switch (key) {
        case 'external_account_holder_name': {
            return String(transaction.external_account?.holder_name)
        }
        case 'external_account_number': {
            return String(transaction.external_account?.account_number)
        }
        default: {
            const defaultSearchData = transaction?.[key as keyof Transaction]

            if (!defaultSearchData) {
                return
            }

            return String(defaultSearchData)
        }
    }
}

export function getMatchingExpectedPaymentFilterByData(
    intl: IntlShape,
    transaction?: Transaction,
    key?: keyof EntityDetailCardRowCustomizers<Transaction>
): Partial<ExpectedPaymentsServiceFindAllQueryOptions> | undefined {
    if (!transaction || !key) {
        return
    }

    switch (key) {
        case 'created_at': {
            const createdAtDate = new Date(transaction.created_at)
            return { matching_expected_date: format(createdAtDate, DEFAULT_DATE_TEMPLATE_FORMAT) }
        }
        case 'value_date': {
            return { matching_expected_date: transaction.value_date }
        }

        case 'amount': {
            const formattedAmount = convertToMajorUnitAmount(transaction.amount, transaction.currency)
            return { currency: transaction.currency, matching_amount: formattedAmount }
        }
        case 'unreconciled_amount': {
            const unreconciledAmount = Math.max(0, transaction.amount - transaction.reconciled_amount)
            const formattedUnreconciledAmount = convertToMajorUnitAmount(unreconciledAmount, transaction.currency)
            return { currency: transaction.currency, matching_amount: formattedUnreconciledAmount }
        }
        case 'external_account_holder_name': {
            return { external_account_holder_name: transaction.external_account.holder_name }
        }
        case 'external_account_number': {
            return { external_account_number: transaction.external_account.account_number }
        }
        // By default, attempt to use the provided key from the transaction, as an expected payment filter
        default: {
            const data = transaction?.[key as keyof Transaction]

            if (isString(data) || isNumber(data)) {
                return { [key]: String(data) }
            }
        }
    }
}
