import { PaymentApprovalRuleCriteria, PaymentApprovalRuleOperatorSchema } from '@webapps/numeral-ui-core'
import { PaymentApprovalRuleAttributePairConfiguration } from '../PaymentApprovalRule.form.types'
import { chain } from 'lodash'
import { useEffect, useMemo } from 'react'
import { getPaymentApprovalRuleConfigurationPairFields } from '../PaymentApprovalRule.form.utils'
import { PaymentApprovalRuleVirtualOperatorSchema } from '../PaymentApprovalRule.form.const'

export function usePaymentApprovalRuleAmountCriterion(
    criterion: PaymentApprovalRuleCriteria,
    selectedCriteria: PaymentApprovalRuleCriteria[],
    config: PaymentApprovalRuleAttributePairConfiguration
) {
    const criterionWrapper = chain(criterion)
    const currentIndex = selectedCriteria?.indexOf(criterion)
    const pairedCriteria = useMemo(() => {
        const [_, secondAttribute] = getPaymentApprovalRuleConfigurationPairFields(config)

        const nextCriteria = selectedCriteria[currentIndex + 1]
        const hasNextCriterionAttributeSameAsPairConfig = nextCriteria?.attribute === secondAttribute
        const hasValidRelationKeyConstraint = chain(nextCriteria)
            .get(config.relationKey)
            .thru((result) => {
                return chain(criterion).get(config.relationKey).eq(result).value()
            })
            .value()

        const hasValidPairedCriteria = hasNextCriterionAttributeSameAsPairConfig && hasValidRelationKeyConstraint
        return hasValidPairedCriteria ? nextCriteria : undefined
    }, [selectedCriteria, criterion, currentIndex, config])
    const currentVirtualOperator = useMemo(() => {
        const [_, secondAttribute] = getPaymentApprovalRuleConfigurationPairFields(config)

        if (criterion.attribute === secondAttribute) {
            return PaymentApprovalRuleVirtualOperatorSchema.enum.less_than
        }
        return pairedCriteria
            ? PaymentApprovalRuleVirtualOperatorSchema.enum.between
            : PaymentApprovalRuleVirtualOperatorSchema.enum.more_than
    }, [criterion.attribute, pairedCriteria])
    const currentCriterionOperator = useMemo(() => criterionWrapper.get('operator').value(), [criterionWrapper])

    useEffect(() => {
        if (!currentCriterionOperator) {
            criterionWrapper.set('operator', PaymentApprovalRuleOperatorSchema.enum.equals).value()
        }
    }, [currentCriterionOperator, criterionWrapper])

    return {
        pairedCriteria,
        currentVirtualOperator,
        currentIndex,
        nextIndex: currentIndex + 1
    }
}
