import {
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    QueryFallback,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdConnectedAccount, useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import {
    getPaymentOrderCounterpartiesRowCustomizers,
    getPaymentOrderCounterpartiesRows
} from './PaymentOrderCounterparties.page.utils'

export const PaymentOrderCounterpartiesPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(query.data?.connected_account_id, {
        enabled: globalThis.Boolean(query.data?.connected_account_id)
    })
    const rows = useMemo<EntityDetailRow<PaymentOrder>[]>(() => {
        return getPaymentOrderCounterpartiesRows(query.data)
    }, [query])

    const rowCustomizers = useMemo<EntityDetailRowCustomizers<PaymentOrder>>(() => {
        return getPaymentOrderCounterpartiesRowCustomizers(query.data, queryConnectedAccount.data)
    }, [query, queryConnectedAccount])

    return (
        <PageLayout className="PaymentOrderCounterparty">
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_order}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<PaymentOrder> data={query.data} rows={rows} rowCustomizers={rowCustomizers} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
