import { QueryFallback, RestrictedSection } from '@/components'
import {
    useMutationDenyExternalAccount,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    useQueryFindByIdExternalAccount,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS, updateUUIDRelativeActionInPathname } from '@/providers'
import { PERMISSION } from '@/services'
import { ApiObjectTypeSchema, ExternalAccountStatus, ExternalAccountStatusSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router'
import { ExternalAccountDenyForm } from './@components'

export function canAcceptOrDenyExternalAccountByStatus(status?: ExternalAccountStatus) {
    return status === ExternalAccountStatusSchema.enum.pending_approval
}

export const ExternalAccountDenyPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()

    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.external_accounts.deny.title' }),
        [intl]
    )
    const { onAdd } = useToasts()
    const query = useQueryFindByIdExternalAccount(uuid)
    const mutation = useMutationDenyExternalAccount(uuid, {
        onSuccess(response) {
            const { COUNTERPARTIES } = relativePaths
            const path = updateUUIDRelativeActionInPathname(location.pathname, COUNTERPARTIES.DETAILS)

            navigateWithLegalEntityID(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.counterparties.external_accounts.deny.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.counterparties.external_accounts.deny.success.toast.description" />
                )
            })
        }
    })

    const isDisabled = useMemo<boolean>(() => {
        const canAcceptOrDeny = canAcceptOrDenyExternalAccountByStatus(query.data?.status)
        const isLoading = query.isLoading || mutation.isPending
        return !canAcceptOrDeny || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="ExternalAccountDeny">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_APPROVE_EXTERNAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.external_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <ExternalAccountDenyForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={isDisabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
