import { Separator, SeparatorVariant } from '@/components/@misc'
import { usePermissions, useQueryFindByIdInquiry } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { prettyPrintCasedWords } from '@/utils'

export const InquiryDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdInquiry(uuid)
    const intl = useIntl()
    const { hasPermission } = usePermissions()

    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.INVESTIGATIONS_VIEW_INQUIRIES)) {
            return null
        }

        const formattedRelatedRequestType = prettyPrintCasedWords(query.data?.related_request_type)
        const formattedDirection = intl.formatMessage({
            id: `app.investigations.inquiries.direction.${query.data.related_request_type}.label`
        })
        const formattedTitle = intl.formatMessage(
            {
                id: 'app.investigations.inquiries.details.title'
            },
            {
                direction: formattedDirection,
                objectType: formattedRelatedRequestType
            }
        )

        return (
            <Text as="span">
                {query.data?.related_request_type && (
                    <>
                        {formattedTitle}
                        <Separator variant={SeparatorVariant.Bullet} />
                    </>
                )}
                <FormattedDate value={query.data.created_at} />
            </Text>
        )
    }, [query, intl])

    return <PageHeaderLayout title={title} isLoading={query.isLoading} />
}
