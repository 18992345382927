import { Flex, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { prettyPrintCasedWords } from '@/utils'

export const FileUploadInputStatusSuccess: React.FC<FileUploadInputStatusProps> = ({
    color,
    fileCount,
    browseLink,
    fontWeight
}) => {
    return (
        <Flex direction="column" alignItems="center">
            <Text color={color} fontWeight={fontWeight} marginBottom="8px">
                <FormattedMessage
                    id="app.common.form.input.file.alt"
                    values={{
                        formattedPaymentFile: (
                            <FormattedMessage
                                id="app.common.pluralization.label"
                                values={{
                                    singular: prettyPrintCasedWords(ApiObjectTypeExtendedSchema.enum.payment_file),
                                    count: fileCount
                                }}
                            />
                        )
                    }}
                />
            </Text>
            <Text color={color} fontWeight="400">
                <FormattedMessage id="app.common.form.input.file.placeholder" values={{ browseLink }} />
            </Text>
        </Flex>
    )
}
