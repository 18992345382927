import { EntityAction, TableCellAccount } from '@/components'
import { Separator, SeparatorVariant } from '@/components/@misc'
import {
    useMutationAuthorizeByIdDirectDebitMandate,
    useMutationBlockByIdDirectDebitMandate,
    useMutationDisableByIdDirectDebitMandate,
    usePermissions,
    useQueryFindByIdDirectDebitMandate,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import {
    showAuthorizeMandateAction,
    showBlockMandateAction,
    showDisableMandateAction
} from './DirectDebitMandateDetails.header.utils'

export const DirectDebitMandatesDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdDirectDebitMandate(uuid)
    const authorizeDirectDebitMandateMutation = useMutationAuthorizeByIdDirectDebitMandate(uuid, {
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.authorize.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.authorize.success.toast.description" />
                )
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.authorize.error.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.authorize.error.toast.description" />
                )
            })
        }
    })
    const disableDirectDebitMandateMutation = useMutationDisableByIdDirectDebitMandate(uuid, {
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.disable.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.disable.success.toast.description" />
                )
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.disable.error.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.disable.error.toast.description" />
                )
            })
        }
    })
    const blockDirectDebitMandateMutation = useMutationBlockByIdDirectDebitMandate(uuid, {
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.block.success.toast.title" />
                ),
                description: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.block.success.toast.description" />
                )
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.block.error.toast.title" />,
                description: (
                    <FormattedMessage id="app.direct_debit_mandates.tabs.details.actions.block.error.toast.description" />
                )
            })
        }
    })

    const actionsProps = useMemo<EntityAction[]>(() => {
        if (!query.data || !hasPermission(PERMISSION.COUNTERPARTIES_EDIT_DIRECT_DEBIT_MANDATES)) {
            return []
        }

        const authorizeAction = {
            async onClick() {
                authorizeDirectDebitMandateMutation.mutate()
            },
            isLoading: query.isLoading,
            children: intl.formatMessage({
                id: 'app.direct_debit_mandates.tabs.details.actions.authorize.label'
            })
        }

        const disableAction = {
            async onClick() {
                disableDirectDebitMandateMutation.mutate()
            },
            isLoading: query.isLoading,
            color: 'red.500',
            children: intl.formatMessage({
                id: 'app.direct_debit_mandates.tabs.details.actions.disable.label'
            })
        }

        const blockAction = {
            async onClick() {
                blockDirectDebitMandateMutation.mutate()
            },
            isLoading: query.isLoading,
            color: 'red.500',
            children: intl.formatMessage({
                id: 'app.direct_debit_mandates.tabs.details.actions.block.label'
            })
        }

        const actions: EntityAction[] = []

        if (showAuthorizeMandateAction(query.data)) {
            actions.push(authorizeAction)
        }

        if (showDisableMandateAction(query.data)) {
            actions.push(disableAction)
        }

        if (showBlockMandateAction(query.data)) {
            actions.push(blockAction)
        }

        return actions
    }, [query, hasPermission])
    const title = useMemo<ReactNode>(() => {
        if (query.isError || !query.data) {
            return intl.formatMessage({
                id: 'app.common.not_found.title'
            })
        }

        const objectType = intl.formatMessage({
            id: `api.object.${query.data?.object}`,
            defaultMessage: query.data.object
        })

        const type = intl.formatMessage({
            id: `api.direct_debit_mandate.type.${query.data.type}`,
            defaultMessage: query.data.type
        })
        const sequence = intl.formatMessage({
            id: `api.direct_debit_mandate.sequence.${query.data.sequence}`,
            defaultMessage: query.data.sequence
        })

        return (
            <>
                <Text as="span">{objectType}</Text>
                <Separator variant={SeparatorVariant.Bullet} />
                <Text as="span">{`${type} ${sequence}`}</Text>
                <Separator variant={SeparatorVariant.Bullet} />
                <TableCellAccount value={query.data.receiving_account} />
            </>
        )
    }, [intl, query, uuid])
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        return {
            title,
            actions: actionsProps,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [title, query, actionsProps])

    if (!hasPermission(PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES)) {
        return null
    }

    return <PageHeaderLayout {...propsPageHeaderLayout} />
}
