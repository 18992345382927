import { UserInviteRequest } from '@/services'
import {
    BaseHttpClientService,
    extractResponseData,
    List,
    Pagination,
    RequestActionTypes,
    ServicesConfiguration,
    Uuid
} from '@webapps/numeral-ui-core'
import { User } from './Users.service.types'

export class UsersService extends BaseHttpClientService {
    get url() {
        return '/users'
    }

    findAll(params: Partial<Pagination>, config?: ServicesConfiguration): Promise<List<User>> {
        return this.httpClient
            .get<List<User>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    findById(id: Uuid, config?: ServicesConfiguration): Promise<User> {
        return this.httpClient.get<User>(`${this.url}/${id}`, config).then(extractResponseData)
    }

    sendInvitation(data: UserInviteRequest, config?: ServicesConfiguration): Promise<Partial<User>> {
        const dispatcher = this.getRequestDispatcherByAction(RequestActionTypes.INVITE)
        return dispatcher<User>(`${this.url}`, data, config).then(extractResponseData)
    }

    updateById(id: Uuid, data: User, config?: ServicesConfiguration): Promise<User> {
        return this.httpClient.post<User>(`${this.url}/${id}`, data, config).then(extractResponseData)
    }

    resendInviteById(id: Uuid, config?: ServicesConfiguration): Promise<User> {
        const dispatcher = this.getRequestDispatcherByAction(RequestActionTypes.RESEND_INVITE)
        return dispatcher<User>(`${this.url}/${id}`, undefined, config).then(extractResponseData)
    }

    resetMFAById(id: Uuid, config?: ServicesConfiguration): Promise<User> {
        const dispatcher = this.getRequestDispatcherByAction(RequestActionTypes.RESET_MFA)
        return dispatcher<User>(`${this.url}/${id}`, undefined, config).then(extractResponseData)
    }

    enableById(id: Uuid, config?: ServicesConfiguration): Promise<User> {
        const dispatcher = this.getRequestDispatcherByAction(RequestActionTypes.ENABLE)
        return dispatcher<User>(`${this.url}/${id}`, undefined, config).then(extractResponseData)
    }

    disableById(id: Uuid, config?: ServicesConfiguration): Promise<User> {
        const dispatcher = this.getRequestDispatcherByAction(RequestActionTypes.DISABLE)
        return dispatcher<User>(`${this.url}/${id}`, undefined, config).then(extractResponseData)
    }
}
