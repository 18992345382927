import React from 'react'
import { PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { If } from '@/components/@misc'
import { Flex, Stack } from '@chakra-ui/react'
import { PaymentApprovalRuleCriterionListItemOperatorInput } from './PaymentApprovalRuleCriterionListItemOperatorInput'
import { PaymentApprovalRuleCriterionListItemValues } from './PaymentApprovalRuleCriterionListItemValues'

interface PaymentApprovalRuleCriterionDefaultProps {
    index: number
    criterion: PaymentApprovalRuleCriteria
    onRemove: () => void
    isDisabled?: boolean
}

export const PaymentApprovalRuleCriterionDefault: React.FC<PaymentApprovalRuleCriterionDefaultProps> = ({
    index,
    criterion,
    isDisabled,
    onRemove
}) => {
    const hasCriterionAttribute = !!criterion?.attribute

    return (
        <Flex gap="calc(var(--numeral-ui-primary-spacing)/2)" flex="1">
            <If condition={hasCriterionAttribute}>
                <Stack minWidth="110px">
                    <PaymentApprovalRuleCriterionListItemOperatorInput
                        name={`criteria.${index}.operator`}
                        value={criterion.operator}
                        criterion={criterion}
                        isDisabled={isDisabled}
                        isRequired={true}
                    />
                </Stack>
            </If>
            <PaymentApprovalRuleCriterionListItemValues
                name={`criteria.${index}.values`}
                criterion={criterion}
                isDisabled={isDisabled}
                onRemoveParent={onRemove}
            />
        </Flex>
    )
}
