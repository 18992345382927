export const UPLOADER_PAYMENT_MAX_ACCEPTED_FILES = 5

export const UPLOADER_PAYMENT_FILE_ACCEPTED_EXTENSIONS = ['xml', 'csv', 'txt']
export const UPLOADER_PAYMENT_FILE_ACCEPTED_SIZE_IN_BYTES = 1049000
export const UPLOAD_PAYMENT_FILE_EMPTY_CONTENT_ERROR_MESSAGE = {
    message: 'File content is empty'
}

export const UPLOADER_REJECTIONS = {
    FULL_REJECTION: 'full_rejection',
    PARTIAL_REJECTION: 'partial_rejection'
}
