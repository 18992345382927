import { BULK_ACTIONS_FALLBACK_ACTION_KEY } from '@/constants'
import { APIQueryParamStateFilterBy } from '@/hooks'
import { BulkActions, BulkActionsActiveContext } from '@/providers'
import { setTimeout } from '@/utils'
import { Query } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { chain, get, isEqual, isObject } from 'lodash'

/**
 * @description
 * - Checks if one of the defined bulk actions is applied simultaneously to currentActive and filterBy state.
 */
export function isBulkActionsActive<T = any>(
    actions?: BulkActions,
    activeContext?: BulkActionsActiveContext<any>,
    filterByState?: APIQueryParamStateFilterBy<any>
): boolean {
    const availableActions = chain(actions).keys().value()
    const hasIntersectionWithBulkActionContext = chain(activeContext)
        .values()
        .intersection(availableActions)
        .size()
        .gt(0)
        .value()

    const hasIntersectionWithFilterByContext = chain(filterByState)
        .values()
        .intersection(availableActions)
        .size()
        .gt(0)
        .value()

    const hasFallbackKey = availableActions.includes(BULK_ACTIONS_FALLBACK_ACTION_KEY)

    return (hasIntersectionWithBulkActionContext && hasIntersectionWithFilterByContext) || hasFallbackKey
}

export function getResolvedIds<T extends { id?: Uuid }>(resolvedItems?: T[]): Set<Uuid> {
    const data = resolvedItems?.map((item) => item.id).filter((id): id is Uuid => id !== undefined)

    return new Set(data)
}

/**
 * @usage
 * Utility for `react-query` specific cache updates for performance optimization.
 * eg:
 * 1. queryClient.invalidateQueries({ predicate: queryClientPredicate });
 * 2. queryClient.refetchQueries({ predicate: queryClientPredicate });
 *
 * @description
 * This pridcate return true when the state (eg: search params) is part of the query keys.
 * If the state is undefined, the predicate always return true.
 */
export function getQueriesMatchingQuickFiltersPredicate<T>(state?: APIQueryParamStateFilterBy<T>) {
    return function queriesMatchingQuickFiltersPredicate(query: Query) {
        if (!state) {
            return true
        }

        return query.queryKey.filter(isObject).some((queryKey) => {
            return chain(state)
                .map((value, key) => isEqual(value, get(queryKey, key)))
                .every()
                .value()
        })
    }
}

/**
 * @usage
 * Utility function
 */
export function wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
