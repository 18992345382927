import { OptionProps } from 'react-select/dist/declarations/src/components/Option'
import { get } from 'lodash'
import { SelectOption } from '@/components/@misc/Select/@components'
import { Role } from '@/services'
import { UserRoleWithCustomRoleTag } from '@/components/@misc'

export const UserRoleNameSelectOption: React.FC<OptionProps<Role>> = ({ children, innerRef, innerProps, ...props }) => {
    const role: Role = get(props, 'data.original') as unknown as Role
    return (
        <SelectOption innerRef={innerRef} innerProps={innerProps} {...props}>
            <UserRoleWithCustomRoleTag role={role} />
        </SelectOption>
    )
}
