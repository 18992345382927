import { Approval, CreatedByTypeSchema } from '@webapps/numeral-ui-core'
import React, { ReactNode, useMemo } from 'react'
import { APIKeyName, FormattedDateTime, UserName } from '@/components/@misc'
import { FormattedMessage } from 'react-intl'
import { Flex } from '@chakra-ui/react'
import { Nullable } from '@/types'

interface PaymentOrderApprovalStatusProps {
    approval?: Nullable<Approval>
}

export const PaymentOrderApprovalStatus: React.FC<PaymentOrderApprovalStatusProps> = ({ approval }) => {
    const values = useMemo(() => {
        const hasBeenApproved = globalThis.Boolean(approval?.approved_at)
        const approvedAt = hasBeenApproved ? <FormattedDateTime value={approval?.approved_at} /> : null
        let approvedBy: ReactNode = null

        switch (approval?.type) {
            case CreatedByTypeSchema.enum.user: {
                approvedBy = <UserName id={approval.id} />
                break
            }

            case CreatedByTypeSchema.enum.api_key: {
                approvedBy = <APIKeyName id={approval.id} />
                break
            }

            case CreatedByTypeSchema.enum.system: {
                approvedBy = <FormattedMessage id="app.common.system" />
            }
        }

        return {
            approvedBy,
            approvedAt
        }
    }, [approval])

    if (!approval) {
        return null
    }

    return (
        <Flex gap="4px" alignItems="center">
            <FormattedMessage id="app.payments.payment_orders.tabs.approvals.approved_status.label" values={values} />
        </Flex>
    )
}
