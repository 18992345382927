import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS, NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ApiObjectTypeExtended } from '@/types'
import { Uuid } from '@webapps/numeral-ui-core'

export function getObjectDetailPagePath(objectId?: Uuid, objectType?: ApiObjectTypeExtended): string | undefined {
    const isValidType = ApiObjectTypeExtendedSchema.safeParse(objectType)
    const absolutePath = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS
    const relativePath = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS

    if (!objectId || !isValidType) {
        return
    }

    let basePath = ''
    let detailPagePath = ''

    switch (objectType) {
        case ApiObjectTypeExtendedSchema.enum.payment_order: {
            basePath = absolutePath.PAYMENTS.PAYMENT_ORDERS
            detailPagePath = relativePath.PAYMENTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.payment_file: {
            basePath = absolutePath.PAYMENTS.PAYMENT_FILES
            detailPagePath = relativePath.PAYMENTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.expected_payment: {
            basePath = absolutePath.PAYMENTS.EXPECTED_PAYMENTS
            detailPagePath = relativePath.PAYMENTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.incoming_payment: {
            basePath = absolutePath.PAYMENTS.INCOMING_PAYMENTS
            detailPagePath = relativePath.PAYMENTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.return: {
            basePath = absolutePath.PAYMENTS.RETURNS
            detailPagePath = relativePath.PAYMENTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.payment_capture: {
            basePath = absolutePath.PAYMENTS.PAYMENT_CAPTURES
            detailPagePath = relativePath.PAYMENTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.transaction: {
            basePath = absolutePath.ACCOUNTS.TRANSACTIONS
            detailPagePath = relativePath.ACCOUNTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.balance: {
            basePath = absolutePath.ACCOUNTS.BALANCES
            detailPagePath = relativePath.ACCOUNTS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.direct_debit_mandate: {
            basePath = absolutePath.COUNTERPARTIES.DIRECT_DEBIT_MANDATES
            detailPagePath = relativePath.ACCOUNTS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.account_holder: {
            basePath = absolutePath.COUNTERPARTIES.ACCOUNT_HOLDERS
            detailPagePath = relativePath.COUNTERPARTIES.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.internal_account: {
            basePath = absolutePath.COUNTERPARTIES.INTERNAL_ACCOUNTS
            detailPagePath = relativePath.COUNTERPARTIES.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.external_account: {
            basePath = absolutePath.COUNTERPARTIES.EXTERNAL_ACCOUNTS
            detailPagePath = relativePath.COUNTERPARTIES.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.file: {
            basePath = absolutePath.FILES
            detailPagePath = relativePath.FILES.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.return_request: {
            basePath = absolutePath.INVESTIGATIONS.RETURN_REQUESTS
            detailPagePath = relativePath.INVESTIGATIONS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.claim: {
            basePath = absolutePath.INVESTIGATIONS.CLAIMS
            detailPagePath = relativePath.INVESTIGATIONS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.inquiry: {
            basePath = absolutePath.INVESTIGATIONS.INQUIRIES
            detailPagePath = relativePath.INVESTIGATIONS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.connected_account: {
            basePath = absolutePath.SETTINGS.CONNECTED_ACCOUNTS
            detailPagePath = relativePath.SETTINGS.DETAILS
            break
        }
        case ApiObjectTypeExtendedSchema.enum.user: {
            basePath = absolutePath.SETTINGS.USERS
            detailPagePath = relativePath.SETTINGS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.api_key: {
            basePath = absolutePath.DEVELOPERS.API_KEYS
            detailPagePath = relativePath.DEVELOPERS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.webhook: {
            basePath = absolutePath.DEVELOPERS.WEB_HOOKS
            detailPagePath = relativePath.DEVELOPERS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.role: {
            basePath = absolutePath.SETTINGS.ROLES
            detailPagePath = relativePath.SETTINGS.DETAILS
            break
        }

        case ApiObjectTypeExtendedSchema.enum.payment_approval_rule: {
            basePath = absolutePath.SETTINGS.PAYMENT_APPROVAL_RULES
            detailPagePath = relativePath.SETTINGS.DETAILS
            break
        }
    }

    if (!basePath) {
        return
    }

    return [basePath, objectId, detailPagePath].join('/')
}
