import { Button, Flex } from '@chakra-ui/react'
import React, { memo, PropsWithChildren } from 'react'
import { TableHeaderActionsProps } from './TableHeaderActions.types'
import { isLastIndex } from '@/utils'
import { omit } from 'lodash'

export const TableHeaderActions: React.FC<TableHeaderActionsProps> = memo(({ actions, ...chakraProps }) => {
    return (
        <Flex {...chakraProps} className="TableHeaderActions" gap="16px">
            {actions?.map((action, index, array) => {
                const { Container = ({ children }: PropsWithChildren) => <>{children}</> } = action
                const style = isLastIndex(index, array) ? null : { colorScheme: 'numeralAccent', variant: 'outline' }
                const propsButton = omit(action, 'Container')

                return (
                    <Container key={index}>
                        <Button {...style} {...propsButton} />
                    </Container>
                )
            })}
        </Flex>
    )
})
