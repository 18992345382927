import { EntityAction } from '@/components'
import { AmountRange, DateRange, If, Separator, SeparatorVariant } from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { usePermissions, useQueryFindByIdExpectedPayment } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { BaseReconcileEntityHeaderProps } from '@/pages'
import { isEntityUnreconciled, PERMISSION } from '@/services'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { ExpectedPaymentCancelConfirmationModal } from './@components'

export type ExpectedPaymentHeaderProps = BaseReconcileEntityHeaderProps

export const ExpectedPaymentHeader: React.FC<ExpectedPaymentHeaderProps> = ({ id, isReconciling, ...props }) => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
    const intl = useIntl()
    const query = useQueryFindByIdExpectedPayment(uuid || id)
    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS)) {
            return null
        }

        const hasAmountInterval = globalThis.Boolean(query.data.amount_from && query.data.amount_to)
        const hasDateInterval = globalThis.Boolean(query.data.start_date && query.data.end_date)
        const counterpartyHolderName = query.data?.external_account?.holder_name

        return (
            <>
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <If condition={hasAmountInterval}>
                    <AmountRange
                        from={query.data.amount_from}
                        to={query.data.amount_to}
                        currency={query.data.currency}
                    />
                    {SPACE_SYMBOL}
                    {query.data.direction}
                </If>
                <If condition={!!counterpartyHolderName}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    {counterpartyHolderName}
                </If>
                <If condition={hasDateInterval}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <DateRange from={query.data.start_date} to={query.data.end_date} />
                </If>
            </>
        )
    }, [isReconciling, query, hasPermission])
    const actions = useMemo(() => {
        if (isReconciling) {
            return
        }

        const buttons: EntityAction[] = []
        const hasEditExpectedPaymentsPermission = hasPermission(PERMISSION.PAYMENTS_EDIT_EXPECTED_PAYMENTS)
        const isUnreconciled = isEntityUnreconciled(query.data)
        const isCanceled = globalThis.Boolean(query.data?.canceled_at)

        if (hasEditExpectedPaymentsPermission && isUnreconciled && !isCanceled) {
            buttons.push({
                onClick() {
                    setIsCancelModalOpen(true)
                },
                color: 'red.500',
                isDisabled: query.isLoading || !isUnreconciled,
                children: intl.formatMessage({ id: 'app.payments.expected_payments.tabs.details.actions.cancel.label' })
            })
        }

        return buttons
    }, [query, intl, hasPermission])
    const onCloseExpectedPaymentCancelConfirmationModal = useCallback(() => {
        setIsCancelModalOpen(false)
    }, [])

    return (
        <>
            <PageHeaderLayout {...props} title={title} actions={actions} isLoading={query.isLoading} />
            <ExpectedPaymentCancelConfirmationModal
                uuid={uuid}
                isOpen={isCancelModalOpen}
                onClose={onCloseExpectedPaymentCancelConfirmationModal}
            />
        </>
    )
}
