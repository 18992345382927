import { FilterInputs, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import {
    AmountInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    PaymentDirectionInput,
    ReconciliationStatusInput
} from '@/components/@inputs'
import { AmountRange, ConnectedAccountName, DateRange, ExpectedPaymentDescriptions } from '@/components/@misc'
import { HIGHLIGHTED_CURRENCY_CODES } from '@/constants'
import { BULK_ACTIONS_FALLBACK_ACTION_KEY } from '@/constants/bulkActions.const'
import { ReconcileEntityFooterAction, ReconcileEntityFooterContent } from '@/pages/Common'
import { BulkActions } from '@/providers'
import { PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import { ExpectedPayment, ExpectedPaymentsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { TransactionReconcileExpectedPaymentsCustomColumns } from './TransactionReconcile.page.types'

export const TRANSACTION_RECONCILE_EXPECTED_PAYMENT_TABLE_STATIC_STATE: TableStateWithEntity<
    ExpectedPayment,
    TransactionReconcileExpectedPaymentsCustomColumns
> = {
    columnOrder: Object.freeze([
        'amount_interval',
        'date_interval',
        'descriptions',
        'external_account_holder',
        'external_account_number',
        'connected_account_id'
    ])
}

export const TRANSACTION_RECONCILE_EXPECTED_PAYMENT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<
    ExpectedPayment,
    TransactionReconcileExpectedPaymentsCustomColumns
> = {
    amount_interval: {
        cell(info) {
            const { amount_from, amount_to, currency } = info.row.original
            return (
                <Text textAlign="right">
                    <AmountRange from={amount_from} to={amount_to} currency={currency} />
                </Text>
            )
        }
    },
    date_interval: {
        cell(info) {
            const { start_date, end_date } = info.row.original
            return <DateRange from={start_date} to={end_date} />
        }
    },
    descriptions: {
        cell(info) {
            return <ExpectedPaymentDescriptions descriptions={info.getValue()} inLineFormatting={true} />
        }
    },
    external_account_holder: {
        cell(info) {
            const { external_account } = info.row.original
            return external_account?.holder_name
        }
    },
    external_account_number: {
        cell(info) {
            const { external_account } = info.row.original
            return external_account?.account_number
        }
    },
    connected_account_id: {
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    }
}

export const TRANSACTION_RECONCILE_EXPECTED_PAYMENT_FILTERS: FilterInputs<ExpectedPaymentsServiceFindAllQueryOptions> =
    {
        matching_expected_date: <DateInput name="matching_expected_date" />,
        start_expected_date: <DateInput name="start_expected_date" />,
        end_expected_date: <DateInput name="end_expected_date" />,
        currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
        matching_amount: <AmountInput name="matching_amount" />,
        amount_from: <AmountInput name="amount_from" />,
        amount_to: <AmountInput name="amount_to" />,
        direction: <PaymentDirectionInput name="direction" isPlaceholderDisplayed={true} />,
        connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
        reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />
    }

export const TRANSACTION_RECONCILE_BULK_ACTIONS_CONFIGURATION: BulkActions = {
    [BULK_ACTIONS_FALLBACK_ACTION_KEY]: {
        configuration: {
            isSingleSelect: false,
            isSelectAllDisabled: true,
            permissions: [PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS]
        },
        actionComponent: ReconcileEntityFooterAction,
        contentComponent: ReconcileEntityFooterContent
    }
}
