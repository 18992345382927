import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { PaymentApprovalRuleApproverType, PaymentApprovalRuleApproverTypeSchema } from '@webapps/numeral-ui-core'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { If, Select } from '@/components/@misc'
import { useCustomFormikField } from '@/hooks'
import { anythingValidator } from '@/utils/@validators'

export const PaymentApprovalRuleStepsApproversTypesInput: React.FC<FormikInput> = ({
    name,
    value,
    isLabelDisplayed,
    isErrorHidden,
    isRequired,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<PaymentApprovalRuleApproverType>({
        name,
        value,
        validate: validator?.(intl, { name, isRequired })
    })
    const isInvalid = useMemo(() => {
        return !isErrorHidden && meta?.touched && !!meta?.error
    }, [isErrorHidden, meta])
    const onChange = useCallback(
        (value: PaymentApprovalRuleApproverType) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps.onChange]
    )

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id={`app.common.form.input.${name}.label`} />
                </FormLabel>
            </If>
            <Select<PaymentApprovalRuleApproverType>
                {...inputProps}
                {...field}
                id={name}
                isInvalid={isInvalid}
                isSearchable={false}
                isClearable={false}
                onChange={onChange}
                options={PaymentApprovalRuleApproverTypeSchema.options}
                getOptionLabel={(option) => {
                    return intl.formatMessage({
                        id: `app.settings.payment_approval_rules.create.form.inputs.steps.approvers.type.${option}`
                    })
                }}
            />
            <If condition={!isErrorHidden}>
                <FormErrorMessage>{meta?.error}</FormErrorMessage>
            </If>
        </FormControl>
    )
}
