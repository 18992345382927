import { COMMA_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { chain, noop, template } from 'lodash'
import { Nullable } from '@/types'

export function getFileReadersWithEventHandlers(
    fileBlobs: File[],
    { onloadstart = noop, onloadend = noop, onerror = noop, onload = noop }
): Nullable<FileReader>[] {
    if (!fileBlobs || fileBlobs.length === 0) {
        return []
    }

    return fileBlobs
        .map((fileBlob) => {
            try {
                const fileReader = new FileReader()
                const fileEncoding = 'UTF-8'

                fileReader.onload = onload.bind(null, fileBlob, fileReader)
                fileReader.onerror = onerror.bind(null, fileBlob, fileReader)
                fileReader.onloadend = onloadend.bind(null, fileBlob, fileReader)
                fileReader.onloadstart = onloadstart.bind(null, fileBlob, fileReader)

                fileReader.readAsText(fileBlob, fileEncoding)

                return fileReader
            } catch (error) {
                console.error(`Error creating FileReader instance for file: ${fileBlob.name}`, error)
                return null
            }
        })
        .filter(globalThis.Boolean)
}

export function acceptedFileInputExtensions(acceptedFileTypes?: string[]) {
    const extensionTemplate = template('.${acceptedFileType}')

    return chain(acceptedFileTypes)
        .toArray()
        .map((acceptedFileType) =>
            extensionTemplate({
                acceptedFileType
            })
        )
        .join(COMMA_SYMBOL)
        .value()
}

export function formatAcceptedSizeBytesToMB(acceptedSize?: number) {
    return chain(acceptedSize).divide(1048576).round(1).value().toString()
}

export function formatAcceptedExtensions(acceptedExtensions?: string[]) {
    return chain(acceptedExtensions)
        .toArray()
        .map((acceptedType) => acceptedType.toUpperCase())
        .join(`${COMMA_SYMBOL}${SPACE_SYMBOL}`)
        .value()
}
