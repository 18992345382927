import { shouldRenderOutlet } from '@/providers'
import { ReactElement } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'

export const ParentPageLayout: React.FC<{ element?: ReactElement }> = ({ element }) => {
    const { uuid } = useParams()
    const location = useLocation()

    if (shouldRenderOutlet(location.pathname, uuid)) {
        return <Outlet />
    }

    if (element) {
        return element
    }

    return null
}
