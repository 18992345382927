export function setTimeout(callback: CallableFunction, delay = 0): CallableFunction {
    // Fallback for older browsers
    const raf = globalThis.requestAnimationFrame || ((fn) => globalThis.setTimeout(fn, 16.67)) // ~60fps
    const caf = globalThis.cancelAnimationFrame || globalThis.clearTimeout

    const startedAt = globalThis.Date.now()
    let timeoutId = raf(tick)

    function tick() {
        if (globalThis.Date.now() - startedAt >= delay) {
            callback()
        } else {
            timeoutId = raf(tick)
        }
    }

    return () => caf(timeoutId)
}
