import { Box, Flex, List, ListItem, ListItemProps, Stack, Text } from '@chakra-ui/react'
import { If, PaymentApprovalRuleApprover, PaymentApprovalRuleName } from '@/components/@misc'
import { FormattedMessage } from 'react-intl'
import { Approval, PaymentOrder, Uuid } from '@webapps/numeral-ui-core'
import { useQueryFindByIdPaymentApprovalRule } from '@/hooks'
import { COMMA_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { PaymentOrderApprovalStatus, PaymentOrderApprovalStatusIcon } from '../'
import { chain } from 'lodash'
import { isLastIndex } from '@/utils'
import { Nullable } from '@/types'
import { useMemo } from 'react'
import { UuidSchema } from '@/utils/@validators'
import { paymentApprovalRuleApiKeyTypeFilter } from 'src/services'

export interface PaymentOrderMatchingPaymentApprovalRuleItemProps extends ListItemProps {
    paymentOrder?: Nullable<PaymentOrder>
    paymentApprovalRuleId?: Uuid
    hasBeenApprovedByAPIKey?: boolean
}

export const PaymentOrderMatchingPaymentApprovalRuleItem: React.FC<
    PaymentOrderMatchingPaymentApprovalRuleItemProps
> = ({ paymentOrder, paymentApprovalRuleId, hasBeenApprovedByAPIKey, ...props }) => {
    const hasValidUUID = useMemo(() => {
        return UuidSchema.safeParse(paymentApprovalRuleId).success
    }, [paymentApprovalRuleId])
    const query = useQueryFindByIdPaymentApprovalRule(paymentApprovalRuleId, {
        enabled: hasValidUUID
    })
    const paymentOrderApprovals = useMemo<Approval[]>(() => {
        return (paymentOrder?.approvals || []).filter(paymentApprovalRuleApiKeyTypeFilter)
    }, [paymentOrder?.approvals])

    const hasPaymentOrderApprovals = useMemo(() => {
        return paymentOrderApprovals.length > 0
    }, [paymentOrderApprovals])

    const totalApprovals = useMemo<number>(() => {
        return chain(query.data?.steps)
            .flatten()
            .reduce((acc, item) => {
                acc += item.approvers?.number
                return acc
            }, 0)
            .value()
    }, [query.data])
    const numberOfPendingApprovals = useMemo<number>(() => {
        return chain(totalApprovals).subtract(paymentOrderApprovals.length).value()
    }, [totalApprovals, paymentOrderApprovals])
    const hasPaymentApprovalRuleBeenApprovedCompletely = useMemo<boolean>(() => {
        return chain(paymentOrderApprovals).size().eq(totalApprovals).value()
    }, [paymentOrderApprovals, totalApprovals])

    return (
        <ListItem {...props}>
            <Stack gap="calc(var(--numeral-ui-primary-spacing)/2)">
                <Text fontWeight="bold" marginBottom="calc(var(--numeral-ui-primary-spacing)/2)">
                    <FormattedMessage id="Rule" />
                    {SPACE_SYMBOL}
                    <PaymentApprovalRuleName paymentApprovalRuleId={paymentApprovalRuleId} fontWeight="500" />
                </Text>
                <List>
                    {chain(query.data?.steps)
                        .flatten()
                        .map((subStep, index) => {
                            const approverIds = subStep.approvers?.values
                            const approverType = subStep.approvers?.type

                            return (
                                <ListItem key={index} marginBottom="calc(var(--numeral-ui-primary-spacing)/2)">
                                    <Flex gap="calc(var(--numeral-ui-primary-spacing)/2)">
                                        <PaymentOrderApprovalStatusIcon
                                            hasBeenApproved={hasPaymentApprovalRuleBeenApprovedCompletely}
                                            hasBeenApprovedByAPIKey={hasBeenApprovedByAPIKey}
                                        />
                                        <Stack paddingLeft="calc(var(--numeral-ui-primary-spacing)/3)">
                                            <Flex gap="calc(var(--numeral-ui-primary-spacing)/4)" flexWrap="wrap">
                                                <FormattedMessage id="app.payments.payment_orders.tabs.approvals.approval_from.label" />
                                                {chain(approverIds)
                                                    .map((value, index, array) => {
                                                        const hasLastIndex = isLastIndex(index, array)
                                                        const hasMoreThanOneElement = array.length > 1
                                                        const hasMoreThanTwoElements = array.length > 2

                                                        return (
                                                            <Flex
                                                                gap="calc(var(--numeral-ui-primary-spacing)/4)"
                                                                flexWrap="wrap"
                                                                key={index}>
                                                                <If condition={hasLastIndex && hasMoreThanOneElement}>
                                                                    <FormattedMessage id="app.payments.payment_orders.tabs.approvals.functions.or.label" />
                                                                </If>
                                                                <Flex>
                                                                    <PaymentApprovalRuleApprover
                                                                        approverType={approverType}
                                                                        approverId={value}
                                                                    />
                                                                    <If
                                                                        condition={
                                                                            !hasLastIndex && hasMoreThanTwoElements
                                                                        }>
                                                                        {COMMA_SYMBOL}
                                                                    </If>
                                                                </Flex>
                                                            </Flex>
                                                        )
                                                    })
                                                    .value()}
                                            </Flex>
                                            <If condition={hasPaymentOrderApprovals}>
                                                <List
                                                    marginY="calc(var(--numeral-ui-primary-spacing)/2)"
                                                    fontSize="12px">
                                                    <Stack gap="calc(var(--numeral-ui-primary-spacing)/2)">
                                                        {chain(paymentOrderApprovals)
                                                            .filter(paymentApprovalRuleApiKeyTypeFilter)
                                                            .map((item, index, array) => {
                                                                return (
                                                                    <ListItem key={index}>
                                                                        <PaymentOrderApprovalStatus approval={item} />
                                                                    </ListItem>
                                                                )
                                                            })
                                                            .value()}
                                                    </Stack>
                                                </List>
                                            </If>
                                            <If condition={!hasPaymentApprovalRuleBeenApprovedCompletely}>
                                                <Flex gap="4px">
                                                    <FormattedMessage
                                                        id="app.payments.payment_orders.tabs.approvals.pending_approvals.label"
                                                        values={{
                                                            numberOfPendingApprovals,
                                                            formattedApprovals: (
                                                                <FormattedMessage
                                                                    id="app.common.pluralization.label"
                                                                    values={{
                                                                        count: numberOfPendingApprovals,
                                                                        singular: 'approval'
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                </Flex>
                                            </If>
                                        </Stack>
                                    </Flex>
                                </ListItem>
                            )
                        })
                        .value()}
                </List>
            </Stack>
        </ListItem>
    )
}
