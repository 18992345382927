import { EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { PaymentApprovalRule } from '@webapps/numeral-ui-core'
import {
    ConnectedAccountName,
    ExternalAccountName,
    FormattedDateTime,
    RoleName,
    StatusLabel,
    UserName
} from '@/components/@misc'
import { PaymentApprovalRuleCustomRows } from './PaymentApprovalRuleDetails.page.types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Text } from '@chakra-ui/react'
import {
    factoryAmountsPaymentApprovalRuleCriteriaWithIntl,
    factoryDefaultPaymentApprovalRuleCriteria
} from './PaymentApprovalRuleDetails.page.utils'
import { PaymentApprovalRuleSteps } from '@/pages'
import { prettyPrintCasedWords } from '@/utils'

export const PAYMENT_APPROVAL_RULE_DETAILS_ROWS: EntityDetailRow<PaymentApprovalRule, PaymentApprovalRuleCustomRows>[] =
    ['scope', 'criteria_custom', 'steps', 'status', 'created_at']

export const PAYMENT_APPROVAL_RULE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    PaymentApprovalRule,
    PaymentApprovalRuleCustomRows
> = {
    scope: {
        cell(item) {
            const formattedValue = prettyPrintCasedWords(item.scope)

            return <>{formattedValue}</>
        }
    },
    criteria_custom: {
        nested: true,
        rows: [
            'criteria_custom.created_by_user_id',
            'criteria_custom.created_by_role_id',
            'criteria_custom.amount',
            'criteria_custom.connected_account_id',
            'criteria_custom.external_account_id'
        ],
        rowCustomizers: {
            'criteria_custom.created_by_user_id': {
                label() {
                    return (
                        <Text noOfLines={1}>
                            <FormattedMessage id="app.page.details.fields.criteria_custom.created_by_user_id.label" />
                        </Text>
                    )
                },
                cell(item) {
                    const factoryComponent = (value: string) => {
                        return <UserName id={value} />
                    }

                    return factoryDefaultPaymentApprovalRuleCriteria(
                        item as unknown as PaymentApprovalRule,
                        'created_by_user_id',
                        'operator',
                        factoryComponent
                    )
                }
            },
            'criteria_custom.created_by_role_id': {
                label() {
                    return <FormattedMessage id="app.page.details.fields.criteria_custom.created_by_role_id.label" />
                },
                cell(item) {
                    const factoryComponent = (value: string) => {
                        return <RoleName id={value} />
                    }

                    return factoryDefaultPaymentApprovalRuleCriteria(
                        item as unknown as PaymentApprovalRule,
                        'created_by_role_id',
                        'operator',
                        factoryComponent
                    )
                }
            },
            'criteria_custom.amount': {
                label() {
                    return (
                        <Text noOfLines={1}>
                            <FormattedMessage id="app.page.details.fields.criteria_custom.amount.label" />
                        </Text>
                    )
                },
                cell(item) {
                    const intl = useIntl()
                    return factoryAmountsPaymentApprovalRuleCriteriaWithIntl(intl)(
                        item as unknown as PaymentApprovalRule
                    )
                }
            },
            'criteria_custom.connected_account_id': {
                label() {
                    return (
                        <Text noOfLines={1}>
                            <FormattedMessage id="app.page.details.fields.criteria_custom.connected_account_id.label" />
                        </Text>
                    )
                },
                cell(item) {
                    const factoryComponent = (value: string) => {
                        return <ConnectedAccountName connectedAccountId={value} />
                    }

                    return factoryDefaultPaymentApprovalRuleCriteria(
                        item as unknown as PaymentApprovalRule,
                        'connected_account_id',
                        'operator',
                        factoryComponent
                    )
                }
            },
            'criteria_custom.external_account_id': {
                label() {
                    return (
                        <Text noOfLines={1}>
                            <FormattedMessage id="app.page.details.fields.criteria_custom.external_account_id.label" />
                        </Text>
                    )
                },
                cell(item) {
                    const factoryComponent = (value: string) => {
                        return <ExternalAccountName externalAccountId={value} />
                    }

                    return factoryDefaultPaymentApprovalRuleCriteria(
                        item as unknown as PaymentApprovalRule,
                        'external_account_id',
                        'operator',
                        factoryComponent
                    )
                }
            }
        }
    },
    steps: {
        cell(item) {
            return <PaymentApprovalRuleSteps paymentApprovalRule={item} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    }
}
