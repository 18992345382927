import { PERMISSION } from '@/services'
import { useCallback } from 'react'
import { useAuth } from '../context'
import { getGroupAdminPermissionName } from './usePermissions.utils'

export const usePermissions = () => {
    const { userPermissions } = useAuth()

    const hasPermission = useCallback(
        (permission: PERMISSION): boolean =>
            !!userPermissions?.has(permission) || !!userPermissions?.has(getGroupAdminPermissionName(permission)),
        [userPermissions]
    )

    const hasPermissions = useCallback(
        (permissions: PERMISSION[]): boolean => permissions.every((permission) => hasPermission(permission)),
        []
    )

    return { hasPermission, hasPermissions }
}
