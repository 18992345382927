import { DevelopersIcon } from '@/components/@icons'
import { MainNestedLayout, ParentPageLayout } from '@/layouts'
import {
    APIKeyCreatePage,
    APIKeyEditPage,
    APIKeysDetailsPage,
    APIKeysPage,
    AuditTrailFilterByOption,
    AuditTrailPage,
    EventDetailsPage,
    EventsListPage,
    WebhookCreatePage,
    WebhookDetailsPage,
    WebhookEditPage,
    WebhooksPage
} from '@/pages'
import { APIKeyHeader } from '@/pages/Developers/APIKeys/@components'
import { WebhookHeader } from '@/pages/Developers/Webhooks/@components'
import { ExtendedRouteObject, getNestedRoutesWithTabLayout, NotFoundRoute } from '@/providers'
import { Navigate } from 'react-router'
import { WebhookEventsPage } from 'src/pages/Developers/Webhooks/WebhookEvents'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

export function getDevelopersNavigationRoutes(): ExtendedRouteObject {
    const { UUID, EDIT, NEW, DEVELOPERS } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const apiKeysRoutes = [
        {
            title: 'app.developers.tabs.details.title',
            path: DEVELOPERS.DETAILS,
            element: <APIKeysDetailsPage />
        },
        {
            title: 'app.developers.tabs.audit_trail.title',
            path: DEVELOPERS.AUDIT_TRAIL,
            element: <AuditTrailPage filterBy={AuditTrailFilterByOption.OBJECT} />
        }
    ]
    const webhookRoutes = [
        {
            title: 'app.developers.tabs.details.title',
            path: DEVELOPERS.DETAILS,
            element: <WebhookDetailsPage />
        },
        {
            title: 'app.developers.tabs.events.title',
            path: DEVELOPERS.EVENTS,
            element: <WebhookEventsPage />
        },
        {
            title: 'app.developers.tabs.audit_trail.title',
            path: DEVELOPERS.AUDIT_TRAIL,
            element: <AuditTrailPage filterBy={AuditTrailFilterByOption.OBJECT} />
        }
    ]

    return {
        title: 'app.developers.title',
        path: 'developers',
        configuration: {
            isFooter: true
        },
        element: <MainNestedLayout />,
        icon: <DevelopersIcon />,
        children: [
            { index: true, element: <Navigate replace={true} to="api-keys" /> },
            {
                title: 'app.developers.api_keys.title',
                path: 'api-keys',
                element: <ParentPageLayout element={<APIKeysPage />} />,
                children: [
                    {
                        title: 'app.developers.api_keys.edit.title',
                        path: EDIT,
                        element: <APIKeyEditPage />
                    },
                    {
                        title: 'app.developers.api_keys.add.title',
                        path: NEW,
                        element: <APIKeyCreatePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, apiKeysRoutes, {
                        header: <APIKeyHeader />
                    })
                ]
            },
            {
                title: 'app.developers.webhooks.title',
                path: 'webhooks',
                element: <ParentPageLayout element={<WebhooksPage />} />,
                children: [
                    {
                        title: 'app.developers.webhooks.edit.title',
                        path: EDIT,
                        element: <WebhookEditPage />
                    },
                    {
                        title: 'app.developers.webhooks.add.title',
                        path: NEW,
                        element: <WebhookCreatePage />
                    },
                    ...getNestedRoutesWithTabLayout(UUID, webhookRoutes, {
                        header: <WebhookHeader />
                    })
                ]
            },
            {
                title: 'app.developers.events.title',
                path: 'events',
                element: <ParentPageLayout element={<EventsListPage />} />,
                children: [
                    {
                        title: 'app.developers.tabs.details.title',
                        path: UUID,
                        element: <EventDetailsPage />
                    }
                ]
            },
            NotFoundRoute
        ]
    }
}
