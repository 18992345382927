import { useQueryFindByIdCustomField } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { useCustomFieldActions } from '../../../@hooks'

export const CustomFieldDetailsHeader: React.FC = () => {
    const intl = useIntl()
    const { uuid } = useParams()
    const query = useQueryFindByIdCustomField(uuid)
    const actions = useCustomFieldActions(query.data)

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        const title = query.data?.name

        return {
            title: query.isError ? notFoundTitle : title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [intl, query])

    return <PageHeaderLayout {...propsPageHeaderLayout} actions={actions} />
}
