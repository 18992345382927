import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { addToast, TOAST_VARIANTS } from '@/providers'
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query'
import { chain } from 'lodash'
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { QUERY_CLIENT_CONFIGURATION } from './CustomQueryClientProvider.const'
import { CustomQueryClientContext } from './CustomQueryClientProvider.context'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { If } from '@/components/@misc'

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools').then(({ ReactQueryDevtools }) => ({
        default: ReactQueryDevtools
    }))
)
export const CustomQueryClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const intl = useIntl()
    const queryClient = useMemo(() => {
        const queryClientConfig: QueryClientConfig = chain(QUERY_CLIENT_CONFIGURATION)
            .merge({
                defaultOptions: {
                    mutations: {
                        onError(data: unknown, variables: unknown, context: unknown) {
                            addToast({
                                variant: TOAST_VARIANTS.ERROR,
                                status: 'error',
                                title: intl.formatMessage({ id: 'app.common.error.title' }),
                                description: intl.formatMessage(
                                    { id: 'app.common.error.description' },
                                    {
                                        /**
                                         * @description
                                         * Optionally expose information about the error here.
                                         */
                                        errorContext: EMPTY_CHAR_SYMBOL
                                    }
                                )
                            })
                        }
                    }
                }
            })
            .value()

        return new QueryClient(queryClientConfig)
    }, [intl])
    const [showDevtools, setShowDevtools] = useState(false)

    useEffect(() => {
        globalThis.window.toggleReactQueryDevtools = () => {
            setShowDevtools((prevState) => !prevState)
        }
    }, [])

    return (
        <CustomQueryClientContext.Provider value={null}>
            <QueryClientProvider client={queryClient}>
                {children}
                {/* Excluded from production builds automatically*/}
                <ReactQueryDevtools initialIsOpen={false} />
                <If condition={showDevtools}>
                    <React.Suspense fallback={null}>
                        <ReactQueryDevtoolsProduction />
                    </React.Suspense>
                </If>
            </QueryClientProvider>
        </CustomQueryClientContext.Provider>
    )
}
