import { UploadFileData, UploadFilesData } from '@/services'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { chain, omit } from 'lodash'

export function getPaymentOrderUploadFormInitialValues(
    data?: UploadFilesData<PaymentOrder>
): Partial<UploadFilesData<PaymentOrder>> {
    return {
        connected_account_id: undefined,
        files: undefined,
        type: undefined,
        direction: undefined,
        treasury_option: undefined,
        retry_details: {
            payment_retry_rule_id: undefined
        } as any,
        ...data
    }
}

/**
 * @todo
 * -Investigate if this transformation can be added to the service layer
 * - Tests
 */
export function transformUploadFilesRequest(data: UploadFilesData<PaymentOrder>): UploadFileData<PaymentOrder>[] {
    const singleRequestBodyUploadFileData: UploadFileData = omit(data, 'files')

    return chain(data)
        .cloneDeep()
        .get('files')
        .reduce<UploadFileData[]>((acc, file, index) => {
            const tempFileId = chain(file.name).deburr().snakeCase().uniqueId().value()

            acc[index] = {
                ...singleRequestBodyUploadFileData,
                id: tempFileId,
                file
            }

            return acc
        }, [])
        .value()
}
