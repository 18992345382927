import { useQueryFindByIdReturn } from '@/hooks'
import { ReconciliationsPage } from '@/pages'
import { PaymentTypeSchema, Return } from '@webapps/numeral-ui-core'
import { useParams } from 'react-router'

export const ReturnsReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdReturn(uuid)

    return <ReconciliationsPage<Return> paymentType={PaymentTypeSchema.enum.return} queryItem={query} />
}
