import { If } from '@/components/@misc'
import { useAuth, usePermissions } from '@/hooks'
import { isProductionUserAccessEnv, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { MainNavigationDivider, MainNavigationSectionListItem } from '../'
import { MainNavigationFooterCollapseItem, MainNavigationProcessPayments } from './@components'
import { MainNavigationFooterProps } from './MainNavigationFooter.types'

export const MainNavigationFooter: React.FC<MainNavigationFooterProps> = (props) => {
    const { className, isCollapsed, routes, onClick } = props

    const { selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const hasPaymentsApprovePaymentOrdersPermission = useMemo<boolean>(() => {
        return hasPermission(PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS)
    }, [hasPermission])
    const hasProductionUserAccessEnv = useMemo<boolean>(() => {
        return isProductionUserAccessEnv(selectedUserAccess)
    }, [selectedUserAccess])

    const hasVisibleProcessPayments = useMemo<boolean>(() => {
        const isProcessPaymentsDisplayed = selectedUserAccess?.configuration?.is_process_payments_displayed

        switch (true) {
            case !isProcessPaymentsDisplayed:
            case isCollapsed:
                return false

            case !hasProductionUserAccessEnv:
            case hasProductionUserAccessEnv && hasPaymentsApprovePaymentOrdersPermission:
                return isProcessPaymentsDisplayed
            default:
                return false
        }
    }, [hasProductionUserAccessEnv, hasPaymentsApprovePaymentOrdersPermission, selectedUserAccess, isCollapsed])

    return (
        <Box className={className}>
            <MainNavigationDivider isCollapsed={isCollapsed} marginY="var(--numeral-ui-primary-spacing)" />
            <If condition={hasVisibleProcessPayments}>
                <MainNavigationProcessPayments />
            </If>
            {routes.map((route, index) => {
                const onClickNavigationItem = () => onClick(route.path)
                return (
                    <MainNavigationSectionListItem
                        isCollapsed={isCollapsed}
                        route={route}
                        onClick={onClickNavigationItem}
                        key={index}
                    />
                )
            })}
            <MainNavigationFooterCollapseItem {...props} />
        </Box>
    )
}
