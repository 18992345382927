import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { usePermissions, useQueryFindByIdExternalAccount } from '@/hooks'
import { PERMISSION } from '@/services'
import { Skeleton, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { RestrictedData } from '../RestrictedData'
import { DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { externalAccountNameFormatter } from './ExternalAccountName.utils'
import { Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { Nullable } from '@/types'

export interface ExternalAccountNameProps {
    externalAccountId?: Nullable<Uuid>
    asPlainText?: boolean
}

export const ExternalAccountName: React.FC<ExternalAccountNameProps> = React.memo(
    ({ externalAccountId, asPlainText }) => {
        const hasValidUUID = useMemo(() => UuidSchema.safeParse(externalAccountId).success, [externalAccountId])
        const { hasPermission } = usePermissions()
        const hasViewExternalAccountsPermission = useMemo(() => {
            return hasPermission(PERMISSION.COUNTERPARTIES_VIEW_EXTERNAL_ACCOUNTS)
        }, [hasPermission])
        const query = useQueryFindByIdExternalAccount(externalAccountId as Uuid, {
            enabled: hasViewExternalAccountsPermission && hasValidUUID
        })
        const formattedName = useMemo(() => {
            return externalAccountNameFormatter(query.data, true)
        }, [query.data])

        switch (true) {
            case !externalAccountId: {
                return <>{EMPTY_VALUE_PLACEHOLDER}</>
            }

            case !hasViewExternalAccountsPermission: {
                return <RestrictedData />
            }

            case query.isLoading: {
                return <Skeleton height="14px" width="150px" />
            }

            case query.isError:
            case isEmpty(query.data?.name): {
                return <>{EMPTY_VALUE_PLACEHOLDER}</>
            }

            case asPlainText: {
                return (
                    <Text as="span" title={formattedName}>
                        {formattedName}
                    </Text>
                )
            }

            default: {
                return (
                    <DetailPageLink
                        objectId={query.data?.id}
                        objectType={ApiObjectTypeExtendedSchema.enum.external_account}>
                        {formattedName}
                    </DetailPageLink>
                )
            }
        }
    }
)
