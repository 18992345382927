import { useQueryFindByIdUser } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { getUserFullName } from '@/services'
import { useMemo } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { UserDisableConfirmationModal } from '../../../@components'
import { useUserActions } from '../../../@hooks'

export const UserDetailsHeader: React.FC = () => {
    const intl = useIntl()
    const { uuid } = useParams()
    const disclosure = useDisclosure()
    const query = useQueryFindByIdUser(uuid)
    const actions = useUserActions(query.data, disclosure)

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        const title = getUserFullName(query.data, query.data?.email)
        return {
            title: query.isError ? notFoundTitle : title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [intl, query])

    return (
        <PageHeaderLayout {...propsPageHeaderLayout} actions={actions}>
            <UserDisableConfirmationModal {...disclosure} uuid={uuid} />
        </PageHeaderLayout>
    )
}
