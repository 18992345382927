import { isDateBeforeYesterdayUTC } from '@/services'
import { Box, Flex, FlexProps, Text, Tooltip } from '@chakra-ui/react'
import { If } from '@/components/@misc'
import { FormattedMessage } from 'react-intl'
import { WarningIcon } from '@/components/@icons'
import { DateFormat } from '@webapps/numeral-ui-core'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { memo, useMemo } from 'react'

const WARNING_COLOR_TOKEN = 'orange.500'

interface BalanceDateWithWarningProps extends FlexProps {
    value: DateFormat
    hasColoredText?: boolean
}

export const BalanceDateWithWarning: React.FC<BalanceDateWithWarningProps> = memo(
    ({ value, children, hasColoredText, ...props }) => {
        if (!value) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        const hasDateValueBeforeYesterday = useMemo<boolean>(() => {
            return value ? isDateBeforeYesterdayUTC(new Date(value)) : false
        }, [value])
        const propsFlex = useMemo<FlexProps>(() => {
            if (hasColoredText) {
                return {
                    ...props,
                    flexDirection: 'row-reverse'
                }
            }

            return {
                ...props
            }
        }, [hasColoredText, props])

        const showColoredTextWarning = hasDateValueBeforeYesterday && hasColoredText

        return (
            <Flex {...propsFlex} gap="4px" alignItems="center" lineHeight={1}>
                <If condition={showColoredTextWarning}>
                    <Text lineHeight={1} color={WARNING_COLOR_TOKEN}>
                        {children}
                    </Text>
                </If>
                <If condition={!showColoredTextWarning}>{children}</If>
                <If condition={hasDateValueBeforeYesterday}>
                    <Tooltip label={<FormattedMessage id="app.common.balances.date_warning.tooltip" />} hasArrow={true}>
                        <Box>
                            <WarningIcon color={WARNING_COLOR_TOKEN} />
                        </Box>
                    </Tooltip>
                </If>
            </Flex>
        )
    }
)
