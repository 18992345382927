import { If } from '@/components/@misc'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ENTITY_DETAIL_CARD_ROW_ACTION_TYPE } from './EntityDetailCard.const'
import { EntityDetailCardProps, EntityDetailCardRowKey } from './EntityDetailCard.types'
import { getEntityDetailCardGeneratedRows, getEntityDetailCardRowActionIcon } from './EntityDetailCard.utils'

export function EntityDetailCard<T>({
    data,
    rowKeys,
    rowCustomizers,
    handleRowAction,
    style
}: EntityDetailCardProps<T>) {
    const intl = useIntl()
    const generatedRows = useMemo(() => {
        return getEntityDetailCardGeneratedRows(data, rowKeys, rowCustomizers)
    }, [data, rowCustomizers])
    const onRowActionClick = useCallback(
        (rowKey: EntityDetailCardRowKey<T>, actionType?: ENTITY_DETAIL_CARD_ROW_ACTION_TYPE) => {
            return () => {
                if (!rowKey || !actionType) {
                    return
                }
                handleRowAction?.(rowKey, actionType)
            }
        },
        [handleRowAction]
    )

    return (
        <Flex
            width="100%"
            gap="8px"
            padding="var(--numeral-ui-primary-spacing)"
            justifyContent="space-between"
            flexWrap="wrap"
            borderWidth="1px"
            borderRadius="var(--numeral-ui-primary-border-radius)"
            {...style}>
            {generatedRows.map((row) => {
                const actionLabel = intl.formatMessage({
                    id: `app.common.entity_details_card.action.${row.action.type}`,
                    defaultMessage: row.action?.type
                })
                const actionIcon = getEntityDetailCardRowActionIcon(row.action?.type)

                return (
                    <Flex
                        key={row.key as string}
                        width="calc(50% - var(--numeral-ui-space-4, 16px))"
                        justifyContent="space-between"
                        alignItems="center">
                        <Flex color="gray.500" flex="1" maxWidth="200px" noOfLines={1}>
                            {row.label}
                        </Flex>
                        <Flex flex="1" minWidth="0" alignItems="center">
                            <Box
                                flex="1"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                display="flex"
                                alignItems="center">
                                <Box as="span" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                    {row.cell}
                                </Box>
                                <If condition={globalThis.Boolean(row.action?.type) && !row.action?.isHidden}>
                                    <Button
                                        variant="link"
                                        color="numeralAccent.500"
                                        title={actionLabel}
                                        aria-label={actionLabel}
                                        isDisabled={row.action?.isDisabled}
                                        onClick={onRowActionClick(row.key, row.action?.type)}
                                        flexShrink={0}
                                        marginLeft="4px">
                                        {actionIcon}
                                    </Button>
                                </If>
                            </Box>
                        </Flex>
                    </Flex>
                )
            })}
        </Flex>
    )
}
