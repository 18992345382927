import { EntityDetail, EntityDrawer, QueryFallback, RestrictedSection, TableHeader } from '@/components'
import { If } from '@/components/@misc'
import { useAPIQueryParams, useQueryFindAllExpectedPayments, useQueryFindByIdTransaction } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ExpectedPaymentCustomRows } from '@/pages'
import { EXPECTED_PAYMENT_DETAILS_ROW_CUSTOMIZERS, EXPECTED_PAYMENT_DETAILS_ROWS } from '@/pages/Payments'
import { BulkActionsProvider, BulkActionsTable } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, isEntityReconciled, PERMISSION } from '@/services'
import { prettyPrintCasedWords, queryDataAggregation } from '@/utils'
import { Row } from '@tanstack/react-table'
import { ExpectedPayment, ExpectedPaymentsServiceFindAllQueryOptions, UuidSchema } from '@webapps/numeral-ui-core'
import { chain, get, isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { TransactionReconcileFullyReconciledContent, TransactionReconcileHeaderCard } from './@components'
import {
    TRANSACTION_RECONCILE_BULK_ACTIONS_CONFIGURATION,
    TRANSACTION_RECONCILE_EXPECTED_PAYMENT_FILTERS,
    TRANSACTION_RECONCILE_EXPECTED_PAYMENT_TABLE_COLUMN_CUSTOMIZERS,
    TRANSACTION_RECONCILE_EXPECTED_PAYMENT_TABLE_STATIC_STATE
} from './TransactionReconcile.page.const'
import { TransactionReconcileExpectedPaymentsCustomColumns } from './TransactionReconcile.page.types'

/**
 * Reconciliation flow started from a transaction
 * Only expected payment can be reconciled with the transaction when starting the flow from a transaction
 */
export const TransactionReconcilePage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
    const { search, filterBy } = useAPIQueryParams<ExpectedPaymentsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                filters: TRANSACTION_RECONCILE_EXPECTED_PAYMENT_FILTERS
            }
        }
    })

    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.accounts.transactions.reconcile.title' }, { uuid }),
        [intl]
    )

    const hasValidUUID = useMemo(() => UuidSchema.safeParse(uuid).success, [uuid])
    const queryTransaction = useQueryFindByIdTransaction(uuid, { enabled: hasValidUUID })
    const isTransactionFullyReconciled = useMemo(() => {
        return isEntityReconciled(queryTransaction.data)
    }, [queryTransaction])

    const queryExpectedPayments = useQueryFindAllExpectedPayments({ ...search.parsedState, ...filterBy.parsedState })
    const expectedPaymentData = useMemo(() => queryDataAggregation(queryExpectedPayments.data), [queryExpectedPayments])
    const isExpectedPaymentDataEmpty = useMemo<boolean>(() => isEmpty(expectedPaymentData), [expectedPaymentData])

    const onTableRowClick = useCallback((row: Row<ExpectedPayment>) => {
        const id = get(row, 'original.id')
        setSelectedId(id)
    }, [])

    const propsEntityDrawer = useMemo(
        () => ({
            isOpen: UuidSchema.safeParse(selectedId).success,
            title: prettyPrintCasedWords(ApiObjectTypeExtendedSchema.enum.expected_payment),
            onClose() {
                setSelectedId(undefined)
            }
        }),
        [selectedId]
    )

    return (
        <PageLayout className="TransactionReconcilePage">
            <PageHeaderLayout title={title}>
                <TransactionReconcileHeaderCard
                    transaction={queryTransaction.data}
                    search={search}
                    filterBy={filterBy}
                />
            </PageHeaderLayout>
            <If condition={isTransactionFullyReconciled}>
                <TransactionReconcileFullyReconciledContent transactionID={uuid} />
            </If>

            <If condition={!isTransactionFullyReconciled}>
                <RestrictedSection
                    feature={ENTITY_FEATURE.RECONCILIATION}
                    permission={PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS}>
                    <BulkActionsProvider<ExpectedPayment, ExpectedPaymentsServiceFindAllQueryOptions>
                        targetObjectType={ApiObjectTypeExtendedSchema.enum.expected_payment}
                        actions={TRANSACTION_RECONCILE_BULK_ACTIONS_CONFIGURATION}
                        filterBy={filterBy}
                        search={search}
                        data={expectedPaymentData}>
                        <TableHeader<ExpectedPaymentsServiceFindAllQueryOptions>
                            objectType={ApiObjectTypeExtendedSchema.enum.expected_payment}
                            isLoading={queryExpectedPayments.isLoading}
                            filterBy={filterBy}
                            search={search}
                        />
                        <QueryFallback
                            objectType={ApiObjectTypeExtendedSchema.enum.expected_payment}
                            isLoading={queryExpectedPayments.isLoading}
                            isSuccess={queryExpectedPayments.isSuccess}
                            isError={queryExpectedPayments.isError}
                            isDataEmpty={isExpectedPaymentDataEmpty}
                            isFilteringOrSearching={search?.isActive || filterBy?.isActive}>
                            <BulkActionsTable<ExpectedPayment, TransactionReconcileExpectedPaymentsCustomColumns>
                                data={expectedPaymentData}
                                onScrollToBottom={queryExpectedPayments.fetchNextPage}
                                onRowClick={onTableRowClick}
                                isLoading={queryExpectedPayments.hasNextPage}
                                state={TRANSACTION_RECONCILE_EXPECTED_PAYMENT_TABLE_STATIC_STATE}
                                columnCustomizers={TRANSACTION_RECONCILE_EXPECTED_PAYMENT_TABLE_COLUMN_CUSTOMIZERS}
                            />
                            <EntityDrawer {...propsEntityDrawer}>
                                <EntityDetail<ExpectedPayment, ExpectedPaymentCustomRows>
                                    data={chain(expectedPaymentData).find({ id: selectedId }).value()}
                                    rows={EXPECTED_PAYMENT_DETAILS_ROWS}
                                    rowCustomizers={EXPECTED_PAYMENT_DETAILS_ROW_CUSTOMIZERS}
                                />
                            </EntityDrawer>
                        </QueryFallback>
                    </BulkActionsProvider>
                </RestrictedSection>
            </If>
        </PageLayout>
    )
}
