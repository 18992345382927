import { Flex, Text } from '@chakra-ui/react'
import { chain, last } from 'lodash'
import { PropsWithChildren, ReactNode } from 'react'
import { components, ControlProps } from 'react-select'

export const SelectControlLabel: React.FC<ControlProps<any>> = ({ innerRef, innerProps, children, ...props }) => {
    //@ts-expect-error Fix typing
    const label = chain(props).invoke('getValue').head().get('label').value()

    return (
        <components.Control innerRef={innerRef} innerProps={innerProps} {...props}>
            <SelectControlLabelChakraUIWrapper>
                {(() => {
                    switch (true) {
                        case !!label?.length: {
                            return (
                                <>
                                    <Text alignSelf="center" noOfLines={1}>
                                        {label}
                                    </Text>
                                    {last(children as ReactNode[])}
                                </>
                            )
                        }

                        case !label?.length: {
                            return (
                                <>
                                    <Text alignSelf="center" color="gray.500" noOfLines={1}>
                                        {props.selectProps.placeholder}
                                    </Text>
                                    {last(children as ReactNode[])}
                                </>
                            )
                        }

                        default: {
                            return children
                        }
                    }
                })()}
            </SelectControlLabelChakraUIWrapper>
        </components.Control>
    )
}

export const SelectControlLabelChakraUIWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Flex
            paddingLeft="calc(var(--numeral-ui-primary-spacing)/2)"
            marginLeft="2px"
            flex="1 1 100%"
            justifyContent="space-between">
            {children}
        </Flex>
    )
}
