import { EntityAction } from '@/components'
import { FormattedAmount, FormattedDate, If, PaymentType, Separator, SeparatorVariant } from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import {
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdConnectedAccount,
    useQueryFindByIdIncomingPayment
} from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { BaseReconcileEntityHeaderProps } from '@/pages'
import { updateUUIDRelativeActionInPathname } from '@/providers'
import { isFinancialInstitutionConnectedAccount, isSCTInstPayment, PERMISSION } from '@/services'
import { Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router'
import { isStatusReceived } from '../../IncomingPayments.page.utils'

export type IncomingPaymentHeaderProps = BaseReconcileEntityHeaderProps

export const IncomingPaymentHeader: React.FC<IncomingPaymentHeaderProps> = ({ id, isReconciling, ...props }) => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const intl = useIntl()
    const incomingPaymentQuery = useQueryFindByIdIncomingPayment(uuid || id)
    const relatedConnectedAccountQuery = useQueryFindByIdConnectedAccount(
        incomingPaymentQuery.data?.connected_account_id,
        { enabled: !!incomingPaymentQuery.data?.connected_account_id }
    )
    const location = useLocation()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<ReactNode>(() => {
        if (!incomingPaymentQuery.data || !hasPermission(PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS)) {
            return null
        }

        const displayDate = incomingPaymentQuery.data.created_at
        const originatingAccountHolderName = incomingPaymentQuery.data.originating_account?.holder_name
        const receivingAccountHolderName = incomingPaymentQuery.data.receiving_account?.holder_name
        const hasPaymentType = globalThis.Boolean(incomingPaymentQuery.data.type && incomingPaymentQuery.data.direction)

        return (
            <>
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <Text as="span">
                    <FormattedAmount
                        amount={incomingPaymentQuery.data.amount}
                        currency={incomingPaymentQuery.data.currency}
                    />
                </Text>
                <If condition={hasPaymentType}>
                    {SPACE_SYMBOL}
                    <PaymentType
                        type={incomingPaymentQuery.data.type}
                        direction={incomingPaymentQuery.data.direction}
                    />
                </If>
                <If condition={!!originatingAccountHolderName}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <Text as="span">{originatingAccountHolderName}</Text>
                </If>
                <If condition={!!receivingAccountHolderName}>
                    <Separator variant={SeparatorVariant.Arrow} />
                    <Text as="span">{receivingAccountHolderName}</Text>
                </If>
                <If condition={!!displayDate}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={displayDate} />
                </If>
            </>
        )
    }, [isReconciling, incomingPaymentQuery, hasPermission])

    const actions = useMemo(() => {
        const buttons: EntityAction[] = []
        const hasStatusReceived = isStatusReceived(incomingPaymentQuery.data?.status)
        const hasFinancialInstitutionConnectedAccount = isFinancialInstitutionConnectedAccount(
            relatedConnectedAccountQuery.data
        )
        const hasSCTInstPayment = isSCTInstPayment(
            incomingPaymentQuery.data?.type,
            incomingPaymentQuery.data?.direction
        )

        if (
            hasPermission(PERMISSION.PAYMENTS_CREATE_RETURNS) &&
            hasFinancialInstitutionConnectedAccount &&
            !hasSCTInstPayment &&
            hasStatusReceived
        ) {
            buttons.push({
                onClick() {
                    const { PAYMENTS } = relativePaths
                    const path = updateUUIDRelativeActionInPathname(location.pathname, PAYMENTS.RETURN)

                    navigateWithLegalEntityID(path)
                },
                isDisabled: incomingPaymentQuery.isLoading || !isStatusReceived,
                children: intl.formatMessage({ id: 'app.payments.incoming_payments.tabs.details.actions.return.label' })
            })
        }

        return buttons
    }, [
        incomingPaymentQuery,
        relatedConnectedAccountQuery,
        hasPermission,
        intl,
        relativePaths,
        location.pathname,
        navigateWithLegalEntityID
    ])

    return <PageHeaderLayout {...props} title={title} isLoading={incomingPaymentQuery.isLoading} actions={actions} />
}
