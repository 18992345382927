import { ConfigurationService } from '@webapps/numeral-ui-core'
import { ENVIRONMENT_VARIABLES } from './env.config'

/**
 * @todo
 * Add tests for the  default configuration of `_services` and `headers`
 */
export function setupNumeralCoreConfiguration() {
    const numeralUiCoreConfiguration = [
        {
            _services: {
                withCredentials: true,
                timeout: Number(ENVIRONMENT_VARIABLES.VITE_DEFAULT_API_TIMEOUT),
                paramsSerializer: {
                    /**
                     * @description
                     * 1. golang standard library does not support repeated query params with brackets;
                     * 2. axios library add brackets by default, thus we need to disable them (indexes: null);
                     */
                    indexes: null
                }
            }
        },
        {
            headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: 0
            }
        }
    ]

    ConfigurationService?.setConfig(...numeralUiCoreConfiguration)

    return ConfigurationService?.getConfig()
}
