import { PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { useCallback } from 'react'
import { getPaymentApprovalRuleConfigurationPairFields } from '../PaymentApprovalRule.form.utils'
import { PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS } from '@/pages/Settings/PaymentApprovalRules/PaymentApprovalRuleCreate/@components/PaymentApprovalRuleForm/PaymentApprovalRule.form.const'

export function usePaymentApprovalRuleDefaultCriterion(criteria: PaymentApprovalRuleCriteria[]) {
    const config = PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS.AMOUNT
    const getActualIndex = useCallback(
        (visualIndex: number): number => {
            const [firstAttribute, secondAttribute] = getPaymentApprovalRuleConfigurationPairFields(config)

            return criteria.slice(0, visualIndex).reduce((acc, item, index) => {
                const next = criteria[index + 1]
                const hasPair = item?.attribute === firstAttribute && next?.attribute === secondAttribute

                return acc + (hasPair ? 1 : 0)
            }, visualIndex)
        },
        [criteria, config.fields]
    )

    const getVisibleCriteria = useCallback(() => {
        return criteria.filter((criterion, index) => {
            const [firstAttribute, secondAttribute] = getPaymentApprovalRuleConfigurationPairFields(config)

            if (criterion.attribute === secondAttribute && index > 0) {
                const prevCriteria = criteria[index - 1]

                return !(prevCriteria?.attribute === firstAttribute)
            }
            return true
        })
    }, [criteria, config.fields])

    return { getActualIndex, getVisibleCriteria }
}
