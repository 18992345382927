import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdWebhook } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import { WebhookDetailsForm } from '../@components'

export const WebhookDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdWebhook(uuid)

    return (
        <PageLayout className="WebhookDetails">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_WEBHOOKS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.webhook}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <WebhookDetailsForm data={query.data} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
