import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import { PAYMENT_COUNTERS_WIDGET_COLUMN_CUSTOMIZERS } from './PaymentCountersWidget.const'
import { TableColumnCustomizers } from '@/components'
import {
    computeDateRangeFilterByLookBehindOffset,
    PaymentCounters,
    WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES
} from '@/services'
import { format } from 'date-fns'
import { DEFAULT_DATE_TEMPLATE_FORMAT, EMPTY_CHAR_SYMBOL } from '@/constants'
import {
    PaymentCountersFindAllQueryOptionsMap,
    PaymentCountersStatusQueryOptionsMap
} from './PaymentCountersWidget.types'
import { ExtractType } from '@/types'
import { TableCellPaymentsLink } from './@components'

export function computePaymentCountersFilterByOptions(
    paymentType: PaymentType,
    status: ExtractType<typeof paymentType, PaymentCountersStatusQueryOptionsMap>,
    lookBehindOffset = 0
): ExtractType<typeof paymentType, PaymentCountersFindAllQueryOptionsMap> {
    const [startDate, endDate] = computeDateRangeFilterByLookBehindOffset(lookBehindOffset)

    return {
        start_date: format(startDate, DEFAULT_DATE_TEMPLATE_FORMAT),
        end_date: format(endDate, DEFAULT_DATE_TEMPLATE_FORMAT),
        status
    }
}

export function getPaymentCountersWidgetColumnCustomizers(
    objectType: PaymentType
): TableColumnCustomizers<PaymentCounters> {
    const customizers = { ...PAYMENT_COUNTERS_WIDGET_COLUMN_CUSTOMIZERS }

    customizers.today = {
        size: 0.1,
        cell(info) {
            const { status } = info.row.original

            return <TableCellPaymentsLink objectType={objectType} status={status} value={info.getValue()} />
        }
    }
    customizers.yesterday = {
        size: 0.1,
        cell(info) {
            const { status } = info.row.original
            return (
                <TableCellPaymentsLink
                    objectType={objectType}
                    status={status}
                    value={info.getValue()}
                    lookBehindOffset={WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES.ONE_DAY}
                />
            )
        }
    }
    customizers.last_seven_days = {
        size: 0.1,
        cell(info) {
            const { status } = info.row.original
            return (
                <TableCellPaymentsLink
                    objectType={objectType}
                    status={status}
                    value={info.getValue()}
                    lookBehindOffset={WIDGETS_SERVICE_LOOK_BEHIND_OFFSET_VALUES.SEVEN_DAYS}
                />
            )
        }
    }

    return customizers
}

export function getNavigationRouteByPaymentType(paymentType: PaymentType) {
    const { PAYMENTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    switch (paymentType) {
        case PaymentTypeSchema.enum.payment_order: {
            return PAYMENTS.PAYMENT_ORDERS
        }

        case PaymentTypeSchema.enum.incoming_payment: {
            return PAYMENTS.INCOMING_PAYMENTS
        }

        case PaymentTypeSchema.enum.return: {
            return PAYMENTS.RETURNS
        }

        default: {
            return EMPTY_CHAR_SYMBOL
        }
    }
}
