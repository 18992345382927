import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { InternalAccountType, InternalAccountTypeSchema } from '@webapps/numeral-ui-core'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Select, SelectProps } from '@/components/@misc'
import { prettyPrintCasedWords } from '@/utils'
import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { anythingValidator } from '@/utils/@validators'

interface InternalAccountTypeInputProps
    extends FormikInput,
        Pick<SelectProps<InternalAccountType>, 'isClearable' | 'components'> {}

export const InternalAccountTypeInput: React.FC<InternalAccountTypeInputProps> = ({
    name,
    value,
    isRequired,
    customPlaceholderKey,
    customPlaceholderValues,
    isPlaceholderDisplayed,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<InternalAccountType>({
        name,
        value,
        validate: validator?.(intl, { isRequired })
    })
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey }, customPlaceholderValues)
        }
        return getFormSelectPlaceholderByReactQueryState(intl, null, isPlaceholderDisplayed)
    }, [customPlaceholderKey, customPlaceholderValues, intl, isPlaceholderDisplayed])
    const onChange = useCallback(
        (value: InternalAccountType) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps.onChange]
    )
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<InternalAccountType>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={onChange}
                isInvalid={isInvalid}
                isSearchable={false}
                options={InternalAccountTypeSchema.options}
                getOptionLabel={prettyPrintCasedWords}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
