import { useActivatedFeatures, useAuth, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessAPIQueryParams } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { PaymentApprovalRule, Uuid } from '@webapps/numeral-ui-core'
import { Nullable } from '@/types'

export function useQueryFindByIdPaymentApprovalRule(
    id?: Nullable<Uuid>,
    options?: Partial<UndefinedInitialDataOptions<PaymentApprovalRule, Error>>
) {
    const { paymentApprovalRulesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()

    const isQueryEnabled =
        isUserLoggedIn && options?.enabled && isFeatureActivated(ENTITY_FEATURE.PAYMENT_APPROVAL_RULE)

    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<PaymentApprovalRule, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [paymentApprovalRulesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return paymentApprovalRulesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
