import { QueryFallback, RestrictedSection, Table, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, useQueryFindAllEvents } from '@/hooks'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, NumeralEvent } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import {
    EVENTS_DOCUMENTATION_LINK,
    EVENTS_TABLE_COLUMN_CUSTOMIZERS,
    EVENTS_TABLE_STATIC_STATE
} from './Events.page.const'

export const EventsPage: React.FC = () => {
    const { uuid } = useParams()
    const { paths } = useNavigationRoutes()
    const query = useQueryFindAllEvents({ related_object_id: uuid })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const onTableRowClick = useTableRowNavigation<NumeralEvent>('id', paths.DEVELOPERS.EVENTS, true)

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_EVENTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.event}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}
                    customEmptyStateDescriptionKey="app.payments.tabs.events.empty_state.description"
                    learnMoreLinkUrl={EVENTS_DOCUMENTATION_LINK}>
                    <Table<NumeralEvent>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={EVENTS_TABLE_COLUMN_CUSTOMIZERS}
                        state={EVENTS_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
