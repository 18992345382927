import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { usePermissions, useQueryFindByIdRole } from '@/hooks'
import { PERMISSION } from '@/services'
import { Skeleton, Text } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { RestrictedData } from '../RestrictedData'
import { UserRoleWithCustomRoleTag, DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { Nullable } from '@/types'

export interface RoleNameProps {
    id?: Nullable<Uuid>
    asPlainText?: boolean
}

export const RoleName: React.FC<RoleNameProps> = React.memo(({ id, asPlainText }) => {
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdRole(id, {
        enabled: globalThis.Boolean(id)
    })
    const formattedName = useMemo(() => {
        return <UserRoleWithCustomRoleTag role={query.data} />
    }, [query.data])

    switch (true) {
        case !id:
        case query.isError:
        case isEmpty(query.data?.name): {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }
        case !hasPermission(PERMISSION.SETTINGS_VIEW_ROLES): {
            return <RestrictedData />
        }
        case query.isLoading: {
            return <Skeleton height="14px" width="50%" />
        }

        case asPlainText: {
            return <Text as="span">{formattedName}</Text>
        }

        default: {
            return (
                <DetailPageLink objectId={query.data?.id} objectType={ApiObjectTypeExtendedSchema.enum.role}>
                    {formattedName}
                </DetailPageLink>
            )
        }
    }
})
