import { useAuth, useNavigateWithLegalEntityID } from '@/hooks'
import { TabBarNavigationItem } from '@/layouts/@components'
import {
    ExtendedRouteObject,
    defaultMainNavigationRoutesFilter,
    getNavigationLegalEntityFeatureFilter,
    navigationProviderOutletExceptionRoutesFilter,
    shouldRenderOutlet,
    useNavigationRouteGuard
} from '@/providers'
import { combineFilters } from '@/utils'
import { uuidValidator } from '@/utils/@validators'
import { Box } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Outlet, useLocation, useParams } from 'react-router'
import './NestedTabs.layout.scss'
import { NestedTabsLayoutProps } from './NestedTabs.layout.types'

export const NestedTabsLayout: React.FC<NestedTabsLayoutProps> = ({ routes, header }) => {
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const location = useLocation()
    const { uuid } = useParams()
    const { selectedUserAccess } = useAuth()
    const mainNavigationRoutesFilter = useMemo(() => {
        const legalEntityFeatureRoutesFilter = getNavigationLegalEntityFeatureFilter(selectedUserAccess?.features)
        return combineFilters<ExtendedRouteObject>(
            defaultMainNavigationRoutesFilter,
            navigationProviderOutletExceptionRoutesFilter,
            legalEntityFeatureRoutesFilter
        )
    }, [selectedUserAccess])
    const uuidValidation = useMemo(() => uuidValidator(intl), [intl])
    const filteredRoutes = useMemo<ReactNode>(() => {
        if (shouldRenderOutlet(location.pathname)) {
            return null
        }

        const routeItemMapper = (route: ExtendedRouteObject) => {
            const onClick = () => {
                if (!route.path) {
                    return
                }

                navigateWithLegalEntityID(route?.path, { replace: true })
            }

            return <TabBarNavigationItem route={route} onClick={onClick} key={route.path} />
        }

        return routes?.filter(mainNavigationRoutesFilter).map(routeItemMapper)
    }, [routes, location, mainNavigationRoutesFilter, navigateWithLegalEntityID])

    useNavigationRouteGuard(() => !uuidValidation(uuid))

    return (
        <Box className="NestedTabsLayout" data-testid="nested-tabs-layout">
            <Box className="NestedTabsLayout-Navigation">
                {header && <div className="NestedTabsLayout-Navigation-Header">{header}</div>}
                {filteredRoutes}
            </Box>
            <Outlet />
        </Box>
    )
}
