import { TableHeaderActionProps } from '@/components'
import {
    useActivatedFeatures,
    useNavigateWithLegalEntityID,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdRole
} from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { ENTITY_FEATURE, isDefaultRole, PERMISSION } from '@/services'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'

export const RoleHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const { isFeatureActivated } = useActivatedFeatures()

    const query = useQueryFindByIdRole(uuid)
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        const title = query.data?.name

        return {
            title: query.isError ? notFoundTitle : title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [intl, query])

    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []
        const hasDefaultRole = isDefaultRole(query.data)

        if (
            isFeatureActivated(ENTITY_FEATURE.CUSTOM_ROLE) &&
            hasPermission(PERMISSION.SETTINGS_CONFIGURE_ROLES) &&
            !hasDefaultRole
        ) {
            actions.push({
                onClick() {
                    const path = [paths.SETTINGS.ROLES, uuid, relativePaths.SETTINGS.EDIT].join('/')
                    navigateWithLegalEntityID(path)
                },
                children: intl.formatMessage({
                    id: 'app.common.actions.edit.label'
                })
            })
        }

        return actions
    }, [query, hasPermission, isFeatureActivated, navigateWithLegalEntityID, intl, paths, relativePaths])

    return <PageHeaderLayout {...propsPageHeaderLayout} actions={actions} />
}
