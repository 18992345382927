import {
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    QueryFallback,
    RestrictedSection
} from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    getPaymentOrderEntityDetailRowCustomizers,
    getPaymentOrderEntityDetailRows
} from './PaymentOrderDetails.page.utils'
import { PaymentOrderDetailsCustomRows } from './PaymentOrderDetails.types'
import { PaymentOrderApproval } from '../@components'

export const PaymentOrderDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const rows = useMemo<EntityDetailRow<PaymentOrder, PaymentOrderDetailsCustomRows>[]>(() => {
        return getPaymentOrderEntityDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<PaymentOrder, PaymentOrderDetailsCustomRows>>(() => {
        return getPaymentOrderEntityDetailRowCustomizers(query.data)
    }, [query])
    const hasApprovalPermission = hasPermission(PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_order}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<PaymentOrder, PaymentOrderDetailsCustomRows>
                        data={query.data}
                        rows={rows}
                        rowCustomizers={rowCustomizers}
                    />
                    <If condition={hasApprovalPermission}>
                        <PaymentOrderApproval data={query.data} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
