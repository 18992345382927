import { QueryFallback, RestrictedSection } from '@/components'
import { usePermissions, useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { chain, isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { Box, List, ListItem, ListItemProps, Stack } from '@chakra-ui/react'
import { If } from '@/components/@misc'
import { PaymentOrderApproval } from '../@components'
import { PaymentOrderApprovalStatus, PaymentOrderMatchingPaymentApprovalRuleItem } from './@components'
import { isLastIndex } from '@/utils'
import { Nullable } from '@/types'
import { Approval, CreatedByTypeSchema } from '@webapps/numeral-ui-core'

export const PaymentOrderApprovalsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const hasApprovalPermission = hasPermission(PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS)
    const query = useQueryFindByIdPaymentOrder(uuid)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data?.payment_approval_rule_ids)
    }, [query?.data?.payment_approval_rule_ids])

    const lastPaymentOrderApproval = useMemo<Nullable<Approval>>(() => {
        const approvals = query.data?.approvals || []
        return approvals[approvals.length - 1]
    }, [query.data?.approvals])
    const hasBeenApprovedByAPIKey = useMemo(() => {
        return lastPaymentOrderApproval?.type === CreatedByTypeSchema.enum.api_key
    }, [lastPaymentOrderApproval])

    return (
        <PageLayout className="PaymentOrderApprovals">
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_APPROVAL_RULE}
                permission={PERMISSION.SETTINGS_VIEW_PAYMENT_APPROVAL_RULES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_approval_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <List
                        padding="var(--numeral-ui-primary-spacing)"
                        borderRadius="var(--numeral-ui-primary-border-radius)"
                        border="1px solid"
                        borderColor="inherit">
                        <Stack gap="var(--numeral-ui-primary-spacing)">
                            <If condition={hasBeenApprovedByAPIKey}>
                                <ListItem>
                                    <Box
                                        backgroundColor="gray.200"
                                        color="gray.500"
                                        padding="12px"
                                        borderRadius="var(--numeral-ui-primary-border-radius)">
                                        <PaymentOrderApprovalStatus approval={lastPaymentOrderApproval} />
                                    </Box>
                                </ListItem>
                            </If>
                            {chain(query.data?.payment_approval_rule_ids)
                                .map((item, index, array) => {
                                    const hasLastIndex = isLastIndex(index, array)
                                    const propsListItem: Nullable<ListItemProps> = hasLastIndex
                                        ? null
                                        : {
                                              borderBottom: '1px solid',
                                              borderColor: 'inherit'
                                          }

                                    return (
                                        <PaymentOrderMatchingPaymentApprovalRuleItem
                                            {...propsListItem}
                                            hasBeenApprovedByAPIKey={hasBeenApprovedByAPIKey}
                                            paymentApprovalRuleId={item}
                                            paymentOrder={query.data}
                                            key={index}
                                        />
                                    )
                                })
                                .value()}
                        </Stack>
                    </List>
                </QueryFallback>
                <If condition={hasApprovalPermission}>
                    <PaymentOrderApproval data={query.data} />
                </If>
            </RestrictedSection>
        </PageLayout>
    )
}
