import { If } from '@/components/@misc'
import { DEFAULT_INPUT_NUMBER_MAX, SPACE_SYMBOL } from '@/constants'
import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { numberValidator } from '@/utils/@validators'

export const NumberInput: React.FC<FormikInput> = ({
    name,
    value,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    max = DEFAULT_INPUT_NUMBER_MAX,
    isRequired,
    isErrorHidden = true,
    isLabelDisplayed = true,
    isDisabled = false,
    validator = numberValidator,
    width = '100%',
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<typeof value>({
        name,
        value,
        validate: validator?.(intl, { name, max, isRequired, ...inputProps })
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])

    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.${name}.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL }, customPlaceholderValues)
    }, [intl, name, customPlaceholderKey, customPlaceholderValues])

    const isInvalid = useMemo(() => {
        return meta?.touched && !!meta.error
    }, [isErrorHidden, meta])

    return (
        <FormControl width={width} isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
            </If>
            <Input
                max={max}
                placeholder={placeholder}
                {...field}
                {...inputProps}
                id={name}
                disabled={isDisabled}
                isRequired={isRequired}
                type="number"
                aria-label={label}
            />
            <If condition={!isErrorHidden}>
                <FormErrorMessage>{meta?.error}</FormErrorMessage>
            </If>
        </FormControl>
    )
}
