import { Form, FormPageFooter } from '@/components'
import { PaymentApprovalRule } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import { TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { Box, FormControl, FormLabel } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { PaymentApprovalRuleFormProps } from './PaymentApprovalRule.form.types'
import { PaymentApprovalRuleCriterionList, PaymentApprovalRuleSteps } from './@components'
import { getPaymentApprovalRuleFormInitialValues } from './PaymentApprovalRule.form.utils'
import { prettyPrintCasedWords } from '@/utils'

export const PaymentApprovalRuleForm: React.FC<PaymentApprovalRuleFormProps> = ({
    data,
    loading,
    disabled,
    onSubmit = noop
}) => {
    const initialValues = useMemo(() => getPaymentApprovalRuleFormInitialValues(data), [data])

    return (
        <Form<PaymentApprovalRule> onSubmit={onSubmit} initialValues={initialValues}>
            {({ values, handleSubmit, dirty }) => {
                const hasNoCriteria = !values.criteria?.length
                const formattedScope = prettyPrintCasedWords(values.scope)?.toLowerCase()

                return (
                    <>
                        <TextInput
                            name="name"
                            customPlaceholderKey="app.settings.payment_approval_rules.create.form.inputs.name.placeholder"
                            isDisabled={disabled}
                            isRequired={true}
                        />
                        <FormControl width="800px">
                            <FormLabel htmlFor="criteria">
                                <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.criteria.label" />
                            </FormLabel>
                            <If condition={hasNoCriteria}>
                                <Box marginBottom="var(--numeral-ui-primary-spacing)">
                                    <FormattedMessage
                                        id="app.settings.payment_approval_rules.create.form.inputs.criteria.no_data_available.title"
                                        values={{
                                            scope: (
                                                <FormattedMessage
                                                    id="app.common.pluralization.label"
                                                    values={{
                                                        singular: formattedScope,
                                                        count: 2
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                </Box>
                            </If>
                            <PaymentApprovalRuleCriterionList criteria={values.criteria} isDisabled={disabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="steps">
                                <FormattedMessage id="app.settings.payment_approval_rules.create.form.inputs.steps.label" />
                            </FormLabel>
                            <PaymentApprovalRuleSteps steps={values.steps} isDisabled={disabled} />
                        </FormControl>
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.settings.payment_approval_rules.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
