import React, { useMemo } from 'react'
import { Nullable } from '@/types'
import { Approval, CreatedByTypeSchema } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { APIKeyName, UserName } from '@/components/@misc'
import { FormattedMessage } from 'react-intl'

export const FormattedLastApprovedBy: React.FC<{ approvals?: Nullable<Approval[]> }> = ({ approvals }) => {
    const lastApproved = useMemo(() => chain(approvals).last().value(), [approvals])

    switch (lastApproved?.type) {
        case CreatedByTypeSchema.enum.user: {
            return <UserName id={lastApproved?.id} />
        }

        case CreatedByTypeSchema.enum.api_key: {
            return <APIKeyName id={lastApproved?.id} />
        }

        case CreatedByTypeSchema.enum.system: {
            return <FormattedMessage id="app.common.system" />
        }

        default: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }
    }
}
