import { BULLET_SEPARATOR, EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { If } from '../If'
import { ExpectedPaymentDescriptionsProps } from './ExpectedPaymentDescriptions.types'
import { isExpectedPaymentDescriptionsEmpty } from './ExpectedPaymentDescriptions.utils'
import { isLastIndex } from '@/utils'

export const ExpectedPaymentDescriptions: React.FC<ExpectedPaymentDescriptionsProps> = ({
    descriptions,
    inLineFormatting
}) => {
    const hasNoExpectedPaymentDescriptions = useMemo(() => {
        return !descriptions || isExpectedPaymentDescriptionsEmpty(descriptions)
    }, [descriptions])

    if (hasNoExpectedPaymentDescriptions) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    if (inLineFormatting) {
        return (
            <>
                {descriptions?.filter(globalThis.Boolean).map((description, index, array) => {
                    const hasLastIndex = isLastIndex(index, array)

                    return (
                        <Text as="span" key={index}>
                            {description} <If condition={!hasLastIndex}> {BULLET_SEPARATOR} </If>
                        </Text>
                    )
                })}
            </>
        )
    }

    return (
        <Flex direction="column" gap="calc(var(--numeral-ui-primary-spacing)/2)">
            {descriptions
                ?.filter(globalThis.Boolean)
                .map((description, index) => <Text key={index}>{description}</Text>)}
        </Flex>
    )
}
