import { ExternalAccount } from '@webapps/numeral-ui-core'
import {
    BANK_ACCOUNT_DEFAULT_LAST_DIGITS_NB,
    BANK_ACCOUNT_UK_LAST_DIGITS_NB,
    BULLET_SEPARATOR,
    SPACE_SYMBOL
} from '@/constants'
import { truncateMiddle } from '@/utils'
import { UKBankAccountNumberSchema } from '@/utils/@validators'

export function externalAccountNameFormatter(item?: Partial<ExternalAccount>, showAccountNumber?: boolean) {
    const separatorWithSpaces = `${SPACE_SYMBOL}${BULLET_SEPARATOR}${SPACE_SYMBOL}`

    let label = ''
    const holderName = item?.holder_name
    const name = item?.name
    const accountNumber = item?.account_number

    if (holderName) {
        label += holderName
    }

    if (name) {
        label += `${separatorWithSpaces}${name}`
    }

    if (showAccountNumber && accountNumber) {
        const isUKBankAccountNumber = UKBankAccountNumberSchema.safeParse(accountNumber).success
        const numberOfChars = isUKBankAccountNumber
            ? BANK_ACCOUNT_UK_LAST_DIGITS_NB
            : BANK_ACCOUNT_DEFAULT_LAST_DIGITS_NB

        label += `${separatorWithSpaces}${truncateMiddle(item.account_number, numberOfChars, numberOfChars)}`
    }

    return label
}
