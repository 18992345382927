import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import {
    useNavigateWithLegalEntityID,
    useQueryFindByIdConnectedAccount,
    useQueryFindByIdExternalAccount,
    useQueryFindByIdInternalAccount
} from '@/hooks'
import { ApiObjectTypeExtended } from '@/types'
import { prettyPrintCasedWords } from '@/utils'
import { Link, LinkProps } from '@chakra-ui/react'
import { ApiObjectTypeSchema, Uuid } from '@webapps/numeral-ui-core'
import { PropsWithChildren, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getObjectDetailPagePath } from './DetailPageLink.utils'
import { isString } from 'lodash'

interface DetailPageLinkProps extends PropsWithChildren, LinkProps {
    objectId?: Uuid
    objectType: ApiObjectTypeExtended
    customLabelMessageKey?: string
}

export const DetailPageLink: React.FC<DetailPageLinkProps> = ({
    objectId,
    objectType,
    customLabelMessageKey,
    children,
    ...chakraProps
}) => {
    const intl = useIntl()

    const queryConnectedAccount = useQueryFindByIdConnectedAccount(objectId, {
        enabled:
            !children &&
            !customLabelMessageKey &&
            objectType === ApiObjectTypeSchema.enum.connected_account &&
            globalThis.Boolean(objectId)
    })

    const queryInternalAccount = useQueryFindByIdInternalAccount(objectId, {
        enabled:
            !children &&
            !customLabelMessageKey &&
            objectType === ApiObjectTypeSchema.enum.internal_account &&
            globalThis.Boolean(objectId)
    })

    const queryExternalAccount = useQueryFindByIdExternalAccount(objectId, {
        enabled:
            !children &&
            !customLabelMessageKey &&
            objectType === ApiObjectTypeSchema.enum.external_account &&
            globalThis.Boolean(objectId)
    })

    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const label = useMemo<ReactNode>(() => {
        const formattedObjectType = intl
            .formatMessage({ id: `api.object.${objectType}`, defaultMessage: prettyPrintCasedWords(objectType) })
            .toLowerCase()

        switch (true) {
            case globalThis.Boolean(children):
                return children
            case globalThis.Boolean(customLabelMessageKey):
                return intl.formatMessage(
                    {
                        id: customLabelMessageKey,
                        defaultMessage: formattedObjectType
                    },
                    {
                        objectType: formattedObjectType
                    }
                )
            case queryConnectedAccount.isSuccess:
                return queryConnectedAccount.data.name
            case queryInternalAccount.isSuccess:
                return queryInternalAccount.data.name
            case queryExternalAccount.isSuccess:
                return queryExternalAccount.data.name
            default:
                return intl.formatMessage(
                    {
                        id: 'app.common.link.view_object.label',
                        defaultMessage: formattedObjectType
                    },
                    {
                        objectType: formattedObjectType
                    }
                )
        }
    }, [intl, objectType, customLabelMessageKey, children])

    const path = useMemo<string | undefined>(
        () => getObjectDetailPagePath(objectId, objectType),
        [objectType, objectId]
    )
    const title = useMemo<string | undefined>(() => {
        return isString(label) ? label : undefined
    }, [label])
    const onNavigate = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()

            if (path) {
                navigateWithLegalEntityID(path)
            }
        },
        [path, navigateWithLegalEntityID]
    )

    if (!path) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Link {...chakraProps} onClick={onNavigate} title={title}>
            {label}
        </Link>
    )
}
