import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdEvent } from '@/hooks'
import { PageLayout } from '@/layouts'
import { EventForm } from '@/pages'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router'
import { EventDetailsHeader } from './@components'

export const EventDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdEvent(uuid)

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_EVENTS}>
                <EventDetailsHeader />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.event}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EventForm data={query?.data} readonly={true} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
