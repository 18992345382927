import { PaymentApprovalRuleAttributeSchema, PaymentApprovalRuleCriteria } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'
import {
    isPaymentApprovalRuleAttributeAmountToFilter,
    isPaymentApprovalRuleAttributeUsedFilter,
    isPaymentApprovalRulePairUsedFilter
} from '../PaymentApprovalRule.form.utils'
import { PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS } from '../PaymentApprovalRule.form.const'

/**
 * @description
 * Get the available attributes for the payment approval rule:
 * - Skip amount attributes that should be filtered (amount_to)
 * - Skip attributes already used in criteria
 * - Skip attributes that are part of a pair where either attribute is already used
 */
export function usePaymentApprovalRuleAvailableAttributes(criteria?: PaymentApprovalRuleCriteria[]) {
    return chain(PaymentApprovalRuleAttributeSchema.options)
        .filter((attribute) => {
            if (isPaymentApprovalRuleAttributeAmountToFilter(attribute)) {
                return false
            }

            if (isPaymentApprovalRuleAttributeUsedFilter(attribute, criteria)) {
                return false
            }

            return !isPaymentApprovalRulePairUsedFilter(
                attribute,
                criteria,
                PAYMENT_APPROVAL_RULE_ATTRIBUTE_PAIRS.AMOUNT
            )
        })
        .value()
}
