import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdTransaction } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, Transaction } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { TRANSACTION_COUNTERPARTIES_ROWS } from './TransactionCounterparties.page.const'
import { getTransactionCounterpartiesRowCustomizers } from './TransactionCounterparties.page.utils'
import { TransactionCounterpartiesRows } from './TransactionCounterparties.types'

export const TransactionCounterpartiesPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdTransaction(uuid)

    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data)
    }, [query.data])

    const rowCustomizer = useMemo(() => {
        return getTransactionCounterpartiesRowCustomizers(query.data)
    }, [query])

    return (
        <PageLayout className="Transaction">
            <RestrictedSection feature={ENTITY_FEATURE.TRANSACTION} permission={PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.transaction}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<Transaction, TransactionCounterpartiesRows>
                        data={query.data}
                        rows={TRANSACTION_COUNTERPARTIES_ROWS}
                        rowCustomizers={rowCustomizer}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
