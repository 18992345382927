import {
    DirectDebitMandateDirectionSchema,
    DirectDebitMandatesServiceFindAllQueryOptions,
    DirectDebitMandateStatusSchema,
    DirectDebitMandateTypeSchema,
    PaymentOrderType,
    PaymentOrderTypeSchema,
    Uuid
} from '@webapps/numeral-ui-core'

export function getPaymentOrderDirectDebitMandateSectionAPIQueryParams(
    connectedAccountId?: Uuid,
    receivingAccountId?: Uuid,
    paymentType?: PaymentOrderType
): Partial<DirectDebitMandatesServiceFindAllQueryOptions> {
    const computedDirectDebitMandateType = (() => {
        switch (paymentType) {
            case PaymentOrderTypeSchema.enum.sepa: {
                return [DirectDebitMandateTypeSchema.enum.sepa_core, DirectDebitMandateTypeSchema.enum.sepa_b2b]
            }

            case PaymentOrderTypeSchema.enum.bacs: {
                return DirectDebitMandateTypeSchema.enum.bacs_debit
            }
        }
    })()

    return {
        connected_account_id: connectedAccountId,
        receiving_account_id: receivingAccountId,
        type: computedDirectDebitMandateType,
        status: DirectDebitMandateStatusSchema.enum.active,
        direction: DirectDebitMandateDirectionSchema.enum.outgoing
    }
}
