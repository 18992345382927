import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { UserStatusSchema } from '@/services'
import { UserInfo } from '@webapps/numeral-ui-core'
import { chain, trim } from 'lodash'
import { User, UserRole } from './Users.service.types'

export function isUserStatusDisabled(user?: User): boolean {
    return user?.status === UserStatusSchema.enum.disabled
}

export function isUserStatusVerified(user?: User): boolean {
    return user?.status === UserStatusSchema.enum.verified
}

export function isUserStatusPending(user?: User): boolean {
    return user?.status === UserStatusSchema.enum.pending
}

export function getUserFullName(user?: User | UserInfo, emptyValuePlaceholder = EMPTY_VALUE_PLACEHOLDER): string {
    return (
        chain<User | UserInfo>(user)
            .pick('first_name', 'last_name')
            .toArray()
            .filter(globalThis.Boolean)
            .map(trim)
            .join(SPACE_SYMBOL)
            .value() || emptyValuePlaceholder
    )
}

export function getUserFirstRoleName(roles?: UserRole[]): string {
    return chain(roles).head().get('name', '').value()
}

/**
 * @todo
 * Remove `is_social` check after backend is live without it.
 */
export function isUserFieldDisabled(data?: Partial<UserInfo> | User, disabled?: boolean | undefined): boolean {
    switch (true) {
        //@ts-expect-error Remove `is_social` check after backend is live without it.
        case Boolean(data?.is_social):
        case Boolean(data?.is_enterprise):
        case disabled: {
            return true
        }

        default: {
            return false
        }
    }
}
