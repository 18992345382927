import { useNavigationRoutes } from '@/hooks'
import { useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import { chain } from 'lodash'

export const AppRoutes: React.FC = () => {
    const location = useLocation()
    const { routes, paths } = useNavigationRoutes()
    const routeMapper = useMemo(
        () => (route: any, index: number) => {
            const childrenWrapper = chain(route.children)
            const hasChildRoutes = childrenWrapper.size().gt(0).value()

            if (hasChildRoutes) {
                return (
                    <Route {...route} key={index}>
                        {childrenWrapper.map(routeMapper).value()}
                    </Route>
                )
            }

            if (route.configuration?.isDisabled) {
                const element = <Navigate to={paths.ROOT} state={{ from: location }} />
                return <Route element={element} key={index} />
            }

            return <Route {...route} key={index} />
        },
        [location, paths.ROOT]
    )

    return <Routes>{routes?.map(routeMapper)}</Routes>
}
