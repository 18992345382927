import React from 'react'
import { Nullable } from '@/types'
import { CreatedBy, CreatedByTypeSchema } from '@webapps/numeral-ui-core'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { FormattedMessage } from 'react-intl'
import { APIKeyName, UserName } from '@/components/@misc'

export const FormattedCreatedBy: React.FC<{ createdBy?: Nullable<CreatedBy> }> = ({ createdBy }) => {
    switch (createdBy?.type) {
        case CreatedByTypeSchema.enum.user: {
            return <UserName id={createdBy?.id} />
        }

        case CreatedByTypeSchema.enum.api_key: {
            return <APIKeyName id={createdBy?.id} />
        }

        case CreatedByTypeSchema.enum.system: {
            return <FormattedMessage id="app.common.system" />
        }

        default: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }
    }
}
